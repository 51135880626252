import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Roles } from '@admin/components/helpers/roles';
import { Alert, Button } from '@shared/components/bootstrap';

import {
  Logistics__ParcelCarrierAccount,
  Logistics__ParcelCarrierAccountInput,
  Logistics__ParcelCarrierEnum,
  Status,
  useModifyParcelCarrierAccountMutation,
} from '@admin/schema';
import { UserRole } from '@shared/types/user_role';

type ParcelAccount = Pick<
  Logistics__ParcelCarrierAccount,
  'id' | 'carrier' | 'accountNumber' | 'country' | 'postalCode'
>;

export const ParcelCarrierAccountRow: React.FC<{
  account: ParcelAccount;
  index: string;
}> = ({ account, index }) => {
  const [carrier, setCarrier] = useState<string>(account.carrier);
  const [accountNumber, setAccountNumber] = useState<string>(account.accountNumber);
  const [country, setCountry] = useState<string>(account.country);
  const [postalCode, setPostalCode] = useState<string>(account.postalCode);
  const [editing, setEditing] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [executeModifySubmit, { loading: modifyLoading }] = useModifyParcelCarrierAccountMutation({ client });

  const resetValues = () => {
    setCarrier(account.carrier);
    setAccountNumber(account.accountNumber);
    setCountry(account.country);
    setPostalCode(account.postalCode);
  };

  const cancelEditing = () => {
    resetValues();
    setEditing(false);
  };

  const formatParcelAccount = () => {
    const parcelAccount: Logistics__ParcelCarrierAccountInput = {
      id: account.id,
      carrier: carrier,
      accountNumber: accountNumber,
      country: country,
      postalCode: postalCode,
    };
    return parcelAccount;
  };

  async function save() {
    try {
      const input = formatParcelAccount();
      const payload = await executeModifySubmit({ variables: { input: input } });
      const res = payload.data?.modifyParcelCarrierAccount;
      if (res?.status === Status.Ok) {
        setError(undefined);
      } else {
        resetValues();
        setError(res?.error || undefined);
      }
    } catch (e) {
      resetValues();
      setError('Sorry, something went wrong. Please try again or contact Tech Support.');
    } finally {
      setEditing(false);
    }
  }

  return (
    <>
      {error && (
        <tr>
          <td className="text-left" colSpan={5}>
            <Alert style="danger">{error}</Alert>
          </td>
        </tr>
      )}
      <tr key={index}>
        <td className="text-left col-md-2">
          {!editing && account.carrier}
          {editing && (
            <select className="form-control" value={carrier} onChange={(event) => setCarrier(event.target.value)}>
              {Object.values(Logistics__ParcelCarrierEnum).map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          )}
        </td>
        <td className="text-left col-md-3">
          {!editing && account.accountNumber}
          {editing && (
            <input
              type="string"
              value={accountNumber}
              onChange={(event) => setAccountNumber(event.currentTarget.value)}
            />
          )}
        </td>
        <td className="text-left col-md-1">
          {!editing && account.country}
          {editing && (
            <input type="string" value={country} onChange={(event) => setCountry(event.currentTarget.value)} />
          )}
        </td>
        <td className="text-left col-md-2">
          {!editing && account.postalCode}
          {editing && (
            <input type="string" value={postalCode} onChange={(event) => setPostalCode(event.currentTarget.value)} />
          )}
        </td>
        <td className="text-right col-md-4">
          <Roles show={[UserRole.Admin, UserRole.EnterpriseManager]}>
            {!editing && (
              <Button kind="primary" onClick={() => setEditing(true)}>
                Edit
              </Button>
            )}
            {editing && (
              <>
                <Button kind="danger" onClick={() => cancelEditing()} loading={modifyLoading}>
                  Cancel
                </Button>{' '}
                <Button kind="primary" onClick={() => save()} loading={modifyLoading}>
                  Save
                </Button>
              </>
            )}
          </Roles>
        </td>
      </tr>
    </>
  );
};
