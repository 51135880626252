import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import {
  Currency,
  useHiringJobListingsPaginatedQuery,
  useHiringJobListingDeactivateMutation,
  useHiringJobListingActivateMutation,
} from '@admin/schema';
import { Table, Button, AnchorButton } from '@shared/components/bootstrap';
import { Timestamp } from '@shared/components/helpers';
import React, { useState } from 'react';
import { facilityNameWithType } from '@admin/utils/facilities';
import { ConfirmModal } from '@admin/components/helpers/confirm_modal';

export const HiringJobListings: React.FC = () => {
  const [page, setPage] = useState<number | undefined>();
  const [confirmModal, setConfirmModal] = useState<{ message: string; onConfirm: () => void } | undefined>(undefined);
  const { data, loading, refetch } = useHiringJobListingsPaginatedQuery({ client, variables: { page: page } });
  const paginated = data?.paginated;

  const [activateJobListing] = useHiringJobListingActivateMutation({ client });
  const [deactivateJobListing] = useHiringJobListingDeactivateMutation({ client });

  const handleActivate = async (id: string) => {
    setConfirmModal({
      message: 'Are you sure you want to activate this job listing? This will make it visible to job seekers.',
      onConfirm: async () => {
        await activateJobListing({ variables: { id } });
        refetch();
        setConfirmModal(undefined);
      },
    });
  };

  const handleDeactivate = async (id: string) => {
    setConfirmModal({
      message:
        'Are you sure you want to deactivate this job listing? This will make it no longer visible to job seekers.',
      onConfirm: async () => {
        await deactivateJobListing({ variables: { id } });
        refetch();
        setConfirmModal(undefined);
      },
    });
  };

  return (
    <Panel>
      <Table responsive striped>
        <thead>
          <tr>
            <th>Job Title</th>
            <th>Region</th>
            <th>Facility</th>
            <th>Hourly Rate</th>
            <th>Time Commitment</th>
            <th>Shift</th>
            <th>Activated At</th>
            <th>Deactivated At</th>
            <th className="text-right">
              <a className="btn btn-mint btn-labeled fa fa-plus" href="/hiring/job_listings/new">
                New
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {paginated?.results.map((jobListing) => (
            <tr key={jobListing.id}>
              <td>{jobListing.job.title}</td>
              <td>{jobListing.region.name}</td>
              <td>{facilityNameWithType(jobListing.facility)}</td>
              <td>
                {jobListing.region.currency === Currency.Usd
                  ? jobListing.hourlyRate.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                  : jobListing.hourlyRate.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' })}
              </td>
              <td>{jobListing.fullTime ? 'Full-Time' : 'Part-Time'}</td>
              <td>{jobListing.nightShift ? 'Night Shift' : 'Daytime Shift'}</td>
              <td>
                {jobListing.activatedAt ? (
                  <Timestamp value={jobListing.activatedAt} format={Timestamp.Format.DateTime} />
                ) : undefined}
              </td>
              <td>
                {jobListing.deactivatedAt ? (
                  <Timestamp value={jobListing.deactivatedAt} format={Timestamp.Format.DateTime} />
                ) : undefined}
              </td>
              <td className="text-right">
                <Button.Group>
                  {!jobListing.activatedAt && !jobListing.deactivatedAt && (
                    <Button kind="success" onClick={() => handleActivate(jobListing.id)}>
                      Activate
                    </Button>
                  )}
                  {jobListing.activatedAt && !jobListing.deactivatedAt && (
                    <Button kind="danger" onClick={() => handleDeactivate(jobListing.id)}>
                      Deactivate
                    </Button>
                  )}
                  <AnchorButton href={`/hiring/job_listings/${jobListing.id}/edit`} kind="primary">
                    Edit
                  </AnchorButton>
                </Button.Group>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {loading && <Spinner />}
      {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
      {confirmModal && <ConfirmModal {...confirmModal} onCancel={() => setConfirmModal(undefined)} />}
    </Panel>
  );
};
