import { useField } from 'formik';
import React, { forwardRef, useState } from 'react';

import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';

import { InputFormControlType, InputFormControlProps } from '@shared/components/fields/input_form_control';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';

type InputFormGroupType = InputFormControlType;
type InputFormGroupProps = InputFormControlProps &
  FieldFormGroupProps & { name: string; allowDecimal?: boolean; allowNegative?: boolean };

export const FormikNumberFormGroup = forwardRef<InputFormGroupType, InputFormGroupProps>(
  ({ help, label, children, allowDecimal = false, allowNegative = false, ...input }, ref) => {
    const [field, _, { setValue }] = useField(input.name);
    const [localValue, setLocalValue] = useState(field.value);

    return (
      <FormikFieldFormGroup name={input.name} help={help} label={label} id={input.id}>
        <input
          className="form-control"
          ref={ref}
          {...field}
          {...input}
          value={localValue ?? ''}
          onChange={(e) => {
            const sanitizer = `[^0-9${allowDecimal ? '.' : ''}${allowNegative ? '-' : ''}]`;
            let value = e.target.value.replace(new RegExp(sanitizer, 'g'), '');

            // Prepend 0 for leading decimal
            value = value.replace(/^(-)?\./, '$10.');
            // Strip invalid leading 0s
            value = value.replace(/^(-?)0+([^.])/, '$1$2');
            // Block repeated . characters
            if (value.indexOf('.') !== value.lastIndexOf('.')) return;
            // Block invalid - characters
            if (value.lastIndexOf('-') > 0) return;

            const number = Number(value);

            setLocalValue(value);
            if (value === '') {
              setValue(undefined);
            }
            if (!isNaN(number)) {
              setValue(number);
            }
          }}
        />
      </FormikFieldFormGroup>
    );
  },
);
