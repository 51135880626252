import React from 'react';

import { client } from '@admin/libraries/apollo';
import { History__Subject, useAccountAddressShowQuery } from '@admin/schema';

import { Breadcrumb, Table, AnchorButton as Link } from '@shared/components/bootstrap';

import { Panel } from '@admin/components/helpers/panel';
import { Preview as AddressDetailPreview } from '@admin/components/address/detail/preview';
import { HistoriesPanel } from '@admin/components/histories/panel';
import { UserRole } from '@shared/types/user_role';
import { Roles } from '@admin/components/helpers/roles';

import { State as OrderState } from '@admin/components/orders/state';
import { Summary as OrderSummary } from '@admin/components/orders/summary';
import { Scheduled as OrderScheduled } from '@admin/components/orders/scheduled';

export const Show: React.FC<{
  accountID: string;
  addressID: string;
}> = ({ accountID, addressID }) => {
  const { data } = useAccountAddressShowQuery({
    client,
    fetchPolicy: 'cache-and-network',
    variables: { accountID, addressID },
  });
  const account = data?.account;
  const address = account?.address;
  const orders = address?.orders;

  return (
    <>
      <h1 className="page-header text-overflow">
        <Breadcrumb>
          <Breadcrumb.Item>
            <a href="/accounts">Accounts</a>
          </Breadcrumb.Item>{' '}
          <Breadcrumb.Item>
            <a href={`/accounts/${accountID}`}>
              #{account?.number ?? '-'} ({account?.customer.name ?? '-'})
            </a>
          </Breadcrumb.Item>{' '}
          <Breadcrumb.Item active>Address</Breadcrumb.Item>
        </Breadcrumb>
      </h1>

      <Panel>
        <Panel.Header>
          <Panel.Title>Details</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <p>
            <strong>Street:</strong> {address?.street ?? '-'}
          </p>
          <p>
            <strong>Apt/Suite:</strong> {address?.aptsuite ?? '-'}
          </p>
          <p>
            <strong>Business Name:</strong> {address?.business_name ?? '-'}
          </p>
          <p>
            <strong>City:</strong> {address?.city ?? '-'}
          </p>
          <p>
            <strong>Neighborhood:</strong> {address?.neighborhood ?? '-'}
          </p>
          <p>
            <strong>State:</strong> {address?.state ?? '-'}
          </p>
          <p>
            <strong>Country:</strong> {address?.country ?? '-'}
          </p>
          <p>
            <strong>ZIP:</strong> {address?.zip ?? '-'}
          </p>
          <p>
            <strong>Latitude:</strong> {address?.latitude ?? '-'}
          </p>
          <p>
            <strong>Longitude:</strong> {address?.longitude ?? '-'}
          </p>
        </Panel.Body>
      </Panel>

      <Panel>
        <Panel.Header>
          <Panel.Title>Settings</Panel.Title>
        </Panel.Header>
        <Panel.Body>{address?.details && <AddressDetailPreview address={address.details} />}</Panel.Body>
        <Panel.Footer align="right">
          <Link href={`/accounts/${accountID}`} kind="default">
            Back
          </Link>
          <Roles hide={[UserRole.L1Agent]}>
            <Link href={`/accounts/${accountID}/addresses/${addressID}/edit`} kind="primary">
              Edit
            </Link>
          </Roles>
        </Panel.Footer>
      </Panel>

      <Panel>
        <Panel.Header>
          <Panel.Title>Orders</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Table bordered striped>
            <thead>
              <tr>
                <th>Number</th>
                <th>Kind</th>
                <th>Scheduled</th>
                <th>State</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {orders?.map((order) => (
                <tr key={order.id}>
                  <td>#{order.number}</td>
                  <td>
                    <OrderSummary order={order} />
                  </td>
                  <td>
                    <OrderScheduled order={order} />
                  </td>
                  <td>
                    <OrderState state={order.state} />
                  </td>
                  <td>
                    <Link kind="primary" href={`/accounts/${accountID}/orders/${order.id}`} target="_blank">
                      Details
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Panel.Body>
      </Panel>

      <HistoriesPanel type={History__Subject.Address} id={addressID} />
    </>
  );
};
