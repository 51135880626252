import { AddressPanel } from '@admin/components/address/panel';
import { AuthorizationHoldsPanel } from '@admin/components/billing/authorization_holds/panel';
import { DamagedItemsPanel } from '@admin/components/damaged_items/damaged_items_panel';
import { DropOffManager } from '@admin/components/drop_off/manager';
import { EstimationFlowInsightPanel } from '@admin/components/estimation/insight/estimation_flow_insight_panel';
import { Roles } from '@admin/components/helpers/roles';
import { HistoriesPanel } from '@admin/components/histories/panel';
import { MovingAppointmentDetails } from '@admin/components/moving/appointment_details/appointment_details';
import { MovingClocksPanel } from '@admin/components/moving/moving_clocks/panel';
import { NotesPanel } from '@admin/components/notes/panel';
import { OrderAssessment } from '@admin/components/orders/assessment';
import { OrderAttachments } from '@admin/components/orders/attachments';
import { InstructionsPanel } from '@admin/components/orders/instructions_panel';
import { OrderLaborClocksPanel } from '@admin/components/orders/labor_clocks/panel';
import { OrderMap } from '@admin/components/orders/map';
import { TransportWeightUpsertForm } from '@admin/components/orders/moving/long_distance/transport_weight_upsert_form';
import { WalkthroughForm } from '@admin/components/orders/moving/walkthrough_form';
import { OrderActions } from '@admin/components/orders/order_actions';
import { OrderPickupCompleteButton } from '@admin/components/orders/order_pickup_complete_button';
import { OrderReturnRouteButton } from '@admin/components/orders/order_return_route_button';
import { ResendTodayUrlButton } from '@admin/components/orders/resend_today_url_button';
import { OrderShipmentsPanel } from '@admin/components/orders/shipments/panel';
import { OrderSummaryFields } from '@admin/components/orders/show/summary_fields';
import { OrderSignaturesPanel } from '@admin/components/orders/signatures/panel';
import { Walkthroughs } from '@admin/components/orders/walkthrough';
import { DocumentImagesPanel } from '@admin/components/order_events/document_images_panel';
import { TicketsPanel } from '@admin/components/tickets/panel';
import { CreateTicket } from '@admin/components/tickets/tech_support/create_ticket';
import { accountsURL, accountURL, dispatchURL } from '@admin/config/routes';
import { useQA } from '@admin/hooks/use_qa';
import { client } from '@admin/libraries/apollo';
import {
  History__Subject,
  NoteNotableEnum,
  OrderSubtypeEnum,
  Ticket__TicketableEnum,
  OrderStateEnum,
  useOrderShowQuery,
} from '@admin/schema';
import { TicketTicketableType } from '@admin/types/ticketable';
import { UserRole } from '@shared/types/user_role';
import { AnchorButton, Col, Panel, Row } from '@shared/components/bootstrap';
import React from 'react';

export const OrderShow = ({ orderID }: { orderID: string }) => {
  const { data } = useOrderShowQuery({ client, variables: { id: orderID }, skip: !orderID });
  const qaMode = useQA();

  if (!data?.order) return null;
  const order = data.order;
  const account = order.account;

  return (
    <>
      <div className="text-overflow pad-top pad-btm">
        <ol className="breadcrumb page-header pull-left">
          <li>
            <a href={accountsURL()}>Accounts</a>
          </li>
          <li>
            <a href={accountURL(order.account)}>
              #{account.number} ({account.customer.name})
            </a>
          </li>
          <li className="active">#{order.number}</li>
        </ol>
        <Roles show={[UserRole.Admin, UserRole.L2Agent, UserRole.Manager]}>
          <div className="pull-right">
            <CreateTicket
              ticketable={{
                id: order.id,
                type: TicketTicketableType.Order,
                displayName: account.customer.name + ' (Order #' + order.number + ')',
              }}
            />
          </div>
        </Roles>
      </div>
      <Panel>
        <Panel.Header>
          <Panel.Title>Details</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Row>
            <Col md={6}>
              <OrderSummaryFields orderID={order.id} />
            </Col>
            <Col md={6}>
              <OrderMap orderID={order.id} />
            </Col>
          </Row>
        </Panel.Body>
        <Panel.Footer align="right">
          {order.todayApp && (
            <>
              <div className="pull-left">
                <ResendTodayUrlButton orderId={order.id} />
              </div>
              <Roles show={[UserRole.Admin]}>
                <div className="pull-left">
                  <AnchorButton kind="info" href={order.todayApp?.url} target="_blank">
                    Today App URL
                  </AnchorButton>
                </div>
              </Roles>
            </>
          )}
          <div className="form-inline">
            <AnchorButton kind="default" href={accountURL(order.account)}>
              Account
            </AnchorButton>
            {qaMode && (
              <span>
                <OrderPickupCompleteButton order={order} />
                <OrderReturnRouteButton order={order} />
              </span>
            )}
            {order.dispatch && (
              <span>
                <AnchorButton kind="info" href={dispatchURL(order.dispatch)}>
                  View Dispatch
                </AnchorButton>
              </span>
            )}
            <OrderActions
              canceled={order.state === OrderStateEnum.Canceled}
              accountID={account.id}
              orderID={order.id}
            />
          </div>
        </Panel.Footer>
      </Panel>
      {!order.movingOperation && (
        <Row>
          <Col xs={12} md={6}>
            <DropOffManager orderID={order.id} />
            <AddressPanel title="Location Details" address={order.address} account={account} />
          </Col>
          <Col xs={12} md={6}>
            <InstructionsPanel order={order} />
          </Col>
        </Row>
      )}
      {order.movingOperation && (
        <div>
          <Row>
            {order.subtype !== OrderSubtypeEnum.Unpacking && (
              <Col xs={12} md={6}>
                <AddressPanel title="Pickup Details" address={order.movingOperation.originAddress} account={account} />
              </Col>
            )}
            {order.subtype !== OrderSubtypeEnum.Packing && (
              <Col xs={12} md={6}>
                <AddressPanel
                  title="Delivery Details"
                  address={order.movingOperation.destinationAddress}
                  account={account}
                />
              </Col>
            )}
          </Row>
          <Panel>
            <Panel.Header className="panel-heading">
              <Panel.Title className="panel-title">Appointment Details</Panel.Title>
            </Panel.Header>
            <Panel.Body>
              <MovingAppointmentDetails orderID={order.id} />
            </Panel.Body>
          </Panel>
        </div>
      )}
      {(order.moving || order.subtype === OrderSubtypeEnum.Onboarding) && (
        <EstimationFlowInsightPanel orderID={order.id} moving={order.moving} />
      )}
      {order.moving && <WalkthroughForm orderID={order.id} />}
      {order.moving && <TransportWeightUpsertForm orderID={order.id} />}
      {order.shipped && <OrderShipmentsPanel orderID={order.id} />}
      <OrderSignaturesPanel orderID={order.id} />
      {order.assessment && <OrderAssessment assessment={order.assessment} />}
      <OrderLaborClocksPanel orderID={order.id} />
      {order.moving && order.movingOperation && <MovingClocksPanel orderID={order.id} />}
      <OrderAttachments orderID={order.id} /> <AuthorizationHoldsPanel orderID={order.id} />
      <HistoriesPanel type={History__Subject.Order} id={order.id} timezone={order && order.region && order.region.tz} />
      <NotesPanel type={NoteNotableEnum.Order} id={order.id} />
      <TicketsPanel
        ticketable={{ id: order.id, type: Ticket__TicketableEnum.Order, associatedLeadUserID: order.leadUser?.id }}
      />
      {order.walkthroughs.length > 0 && <Walkthroughs walkthroughs={order.walkthroughs} timeZone={order.region.tz} />}
      {order && <DocumentImagesPanel orderID={order.id} />}
      {order && <DamagedItemsPanel orderID={order.id} />}
    </>
  );
};
