import React from 'react';
import { Spinner } from '@admin/components/spinner';
import { Moving__PackingEnum, PackageKindEnum, useMovingAppointmentDetailsQuery } from '@admin/schema';
import { Spacer } from '@shared/components/helpers';
import { client } from '@admin/libraries/apollo';
import styled from '@emotion/styled';
import {
  MoveSizes,
  SelectionDetailsSummary,
  StorageUnitSizes,
  STORAGE_UNIT_FIELDS,
  SMALL_MOVE_UNIT_TYPES,
  SmallMoveUnitTypes,
} from '@admin/components/orders/moving/selection_details_summary';
import {
  PackingDetailsSummary,
  PackingValue,
  PACKING_VALUES,
} from '@admin/components/orders/moving/packing_details_summary';
import { QuoteSummary } from '@admin/components/orders/moving/quote_summary';

const MOVING_SQUARE_FOOTAGE_BUILDING_TYPES = ['Commercial', 'Other'];
const MOVING_CUSTOM_UNIT_BUILDING_TYPES = ['Studio', 'Storage Facility / Warehouse'];
const MOVE_SIZE_BUILDING_TYPES = [...MOVING_SQUARE_FOOTAGE_BUILDING_TYPES, ...MOVING_CUSTOM_UNIT_BUILDING_TYPES];
const STORAGE_UNIT_FIELDS_SET = new Set(STORAGE_UNIT_FIELDS);

const PackingEnumToPackingValue: { [key in Moving__PackingEnum]: PackingValue } = {
  [Moving__PackingEnum.AllItems]: PACKING_VALUES[0],
  [Moving__PackingEnum.SomeItems]: PACKING_VALUES[1],
  [Moving__PackingEnum.EverythingIsPacked]: PACKING_VALUES[2],
};

const SpinnerWrapper = styled.div`
  display: flex;
`;

export const MovingAppointmentDetails: React.FC<{
  orderID: string;
}> = ({ orderID }) => {
  const { data, loading } = useMovingAppointmentDetailsQuery({
    fetchPolicy: 'cache-and-network',
    client,
    variables: { orderID },
  });

  if (loading) {
    return (
      <>
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
        <Spacer height="8px" />
      </>
    );
  }

  const movingOperation = data?.order?.movingOperation;
  const movingSelections = data?.order?.movingSelections || [];
  const accountPackages = data?.order?.accountPackages || [];
  const materialAccountPackage = accountPackages.find(
    (accountPackage) => accountPackage.kind === PackageKindEnum.PackingSupplies,
  );
  const buildingType = data?.order?.movingOperation?.originAddress?.details?.buildingType;
  const movingRooms = movingSelections.reduce((mappedMovingRooms, { movingCategory, quantity }) => {
    mappedMovingRooms[movingCategory.name] = quantity;
    return mappedMovingRooms;
  }, {} as { [key: string]: number });
  const movingSquareFootage =
    movingSelections.find(({ movingCategory }) => movingCategory.name === 'square_footage')?.quantity || 0;
  const storageUnitSize = movingSelections.find(
    ({ quantity, movingCategory }) => STORAGE_UNIT_FIELDS_SET.has(movingCategory.name as any) && quantity > 0,
  )?.movingCategory?.name;

  // Small moves only have a single selection
  const selection = movingSelections[0]?.movingCategory?.name;
  const isSmallMove = SMALL_MOVE_UNIT_TYPES.includes(selection);
  const smallMoveSelection = isSmallMove ? (selection as SmallMoveUnitTypes) : undefined;

  return (
    <>
      <SelectionDetailsSummary
        movingRooms={movingRooms}
        moveHasSquareFootage={!!buildingType && MOVING_SQUARE_FOOTAGE_BUILDING_TYPES.includes(buildingType)}
        moveHasMoveSize={!!buildingType && MOVE_SIZE_BUILDING_TYPES.includes(buildingType)}
        moveHasStorageUnit={buildingType === 'Storage Facility / Warehouse'}
        movingSquareFootage={movingSquareFootage}
        moveSize={movingOperation?.moveSize as MoveSizes}
        smallMoveSelection={smallMoveSelection}
        storageUnitSize={storageUnitSize as StorageUnitSizes}
      />
      <hr />
      <h3 className="text-thin">Packing Details</h3>
      {movingOperation?.packing && (
        <PackingDetailsSummary
          packingValue={PackingEnumToPackingValue[movingOperation?.packing]}
          packingSuppliesRequired={!!materialAccountPackage}
        />
      )}
      <hr />
      <h3 className="text-thin">Estimation Details</h3>
      {movingOperation?.quote && <QuoteSummary quote={movingOperation.quote} />}
    </>
  );
};
