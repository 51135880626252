import React from 'react';

import { CustomerTicket__Priority } from '@admin/schema';
import { CUSTOMER_TICKET_PRIORITIES } from '@admin/types/customer_ticket';

export const PriorityPicker: React.FC<{
  className?: string;
  priority?: CustomerTicket__Priority;
  onChange(priority: CustomerTicket__Priority): void;
}> = ({ className, priority, onChange }) => (
  <div className={className}>
    <select
      id="ticket-priority-select"
      className="form-control"
      value={priority}
      onChange={(event) => onChange(event.currentTarget.value as CustomerTicket__Priority)}
      required
    >
      <option value="">-- Priority --</option>
      {CUSTOMER_TICKET_PRIORITIES.map((ticketPriority) => (
        <option key={ticketPriority.value} value={ticketPriority.value}>
          {ticketPriority.name} - {ticketPriority.details}
        </option>
      ))}
    </select>
  </div>
);
