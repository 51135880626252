import axios from 'axios';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { IItem } from '@admin/types/item';
import { IItemGroup } from '@admin/types/item_group';
import { InventorySectionMode } from '@admin/types/inventory_section';
import { IPallet } from '@admin/types/pallet';
import { IWarehouse } from '@admin/types/warehouse';

import { Button } from '@shared/components/bootstrap';
import { Sections } from './sections';
import { Summary } from './summary';

const link = (items: IItem[], groups: IItemGroup[], pallets: IPallet[], warehouses: IWarehouse[]) => {
  for (const item of items) {
    Object.assign(item, {
      group: groups.find((group) => group.id === item.group_id),
      pallet: pallets.find((pallet) => pallet.id === item.pallet_id),
      warehouse: warehouses.find((warehouse) => warehouse.id === item.warehouse_id),
    });
  }
  for (const group of groups) {
    Object.assign(group, {
      items: items.filter((item) => item.group_id === group.id),
    });
  }
  for (const pallet of pallets) {
    Object.assign(pallet, {
      items: items.filter((item) => item.pallet_id === pallet.id),
    });
  }
  for (const warehouse of warehouses) {
    Object.assign(warehouse, {
      items: items.filter((item) => item.warehouse_id === warehouse.id),
    });
  }
};

const FetcherForSelectedIDs: React.FC<{
  accountID: number;
  orderID?: number;
  selectedIDs: Set<number>;
  onSelect(selections: IItem[], options: IItem[]): void;
}> = ({ accountID, orderID, selectedIDs, onSelect }) => {
  const [mode, setMode] = useState<InventorySectionMode>(InventorySectionMode.Warehouses);
  const [options, setOptions] = useState<{
    items: IItem[];
    groups: IItemGroup[];
    pallets: IPallet[];
    warehouses: IWarehouse[];
  }>({
    items: [],
    groups: [],
    pallets: [],
    warehouses: [],
  });

  const selections = options.items.filter(({ id }) => selectedIDs.has(id));

  useEffect(() => {
    const requests = axios.all([
      axios.get('/warehouses.json'),
      axios.get(`/accounts/${accountID}/pallets.json`),
      axios.get(`/accounts/${accountID}/items/requestables.json`, { params: { order_id: orderID } }),
      axios.get(`/accounts/${accountID}/item_groups.json`, { params: { order_id: orderID } }),
    ]);
    requests.then(
      axios.spread((warehouses, pallets, items, groups) => {
        link(items.data, groups.data, pallets.data, warehouses.data);
        setOptions({
          items: items.data,
          groups: groups.data,
          pallets: pallets.data,
          warehouses: warehouses.data,
        });
        onSelect(
          (items.data as IItem[]).filter(({ id }) => selectedIDs.has(id)),
          items.data,
        );
      }),
    );
  }, []);

  return (
    <>
      <div className="clearfix">
        <div className="btn-group pull-right">
          <Button
            kind="default"
            active={mode === InventorySectionMode.Warehouses}
            onClick={() => setMode(InventorySectionMode.Warehouses)}
          >
            By Warehouse
          </Button>
          <Button
            kind="default"
            active={mode === InventorySectionMode.Pallets}
            onClick={() => setMode(InventorySectionMode.Pallets)}
          >
            By Pallet
          </Button>
        </div>
      </div>

      <br />

      <Sections
        {...options}
        mode={mode}
        selections={selections}
        onSelect={(values) => {
          onSelect(values, options.items);
        }}
      />

      <Summary
        selections={selections}
        items={options.items}
        groups={options.groups}
        onSelect={(values) => {
          onSelect(values, options.items);
        }}
      />
    </>
  );
};

export const Fetcher: React.FC<{
  accountID: number;
  orderID?: number;
  selections?: Array<{ id: number }>;
  onSelect(selections: IItem[], options: IItem[]): void;
}> = ({ selections = [], ...props }) => {
  const selectedIDs = new Set(selections.map(({ id }) => id));
  return <FetcherForSelectedIDs {...props} selectedIDs={selectedIDs} />;
};
