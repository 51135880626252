import axios from 'axios';
import { debounce } from 'lodash';
import moment from 'moment';
import * as qs from 'qs';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { IGratuityShare } from '@admin/types/gratuity_share';
import { IPaginated } from '@admin/types/paginated';
import { IPagination } from '@admin/types/pagination';

import { Filters } from './filters';
import { Results } from './results';

const FETCH_DELAY = 200; // ms
const DATE_FORMAT = 'YYYY-MM-DD';
const WEEK_FORMAT = 'YYYY-[W]ww';

interface IQueryParams {
  query?: string;
  from?: string;
  till?: string;
  week?: string;
  page?: number;
}

const parameterize = ({ from, till, ...params }: IQueryParams) => ({
  from: from ? moment(from).format(DATE_FORMAT) : undefined,
  till: till ? moment(till).format(DATE_FORMAT) : undefined,
  ...params,
});

type Callback = (result: { data: IPaginated<IGratuityShare> }) => void;

const fetch = debounce((params: IQueryParams, callback: Callback) => {
  const results = axios.get<IPaginated<IGratuityShare>>('/gratuities/shares.json', { params: parameterize(params) });
  results.then(callback);
}, FETCH_DELAY);

const saveURL = (params: IQueryParams, format = 'csv') =>
  `/gratuities/shares.${format}?${qs.stringify(parameterize(params))}`;

type Mode = 'week' | 'dates';

export const Dashboard = () => {
  const [pagination, setPagination] = useState<IPagination | undefined>(undefined);
  const [results, setResults] = useState<IGratuityShare[] | undefined>(undefined);
  const [mode, onMode] = useState<Mode>('week');
  const [query, onQuery] = useState<string | undefined>(undefined);
  const [from, onFrom] = useState<string | undefined>(undefined);
  const [till, onTill] = useState<string | undefined>(undefined);
  const [week, onWeek] = useState<string | undefined>(moment().format(WEEK_FORMAT));

  const filters: IQueryParams = { query, from, till, week };

  const filter = (page?: number) => {
    fetch({ ...filters, page }, ({ data }) => {
      setResults(data.results);
      setPagination(data.pagination);
    });
  };

  useEffect(() => {
    if (from && till && moment(from) > moment(till)) {
      onTill(from);
    }
  }, [from]);

  useEffect(() => {
    if (till && from && moment(till) < moment(from)) {
      onFrom(till);
    }
  }, [till]);

  useEffect(() => {
    switch (mode) {
      case 'week':
        onFrom(undefined);
        onTill(undefined);
        break;
      case 'dates':
        onWeek(undefined);
        break;
    }
  }, [mode]);

  useEffect(filter, [query, from, till, week]);

  return (
    <>
      <Filters
        mode={mode}
        query={query}
        from={from}
        till={till}
        week={week}
        onMode={onMode}
        onQuery={onQuery}
        onFrom={onFrom}
        onTill={onTill}
        onWeek={onWeek}
        onCSV={() => window.open(saveURL(filters), '_blank')}
      />
      <Results results={results} pagination={pagination} onPage={filter} />
    </>
  );
};
