import React, { useEffect, useState } from 'react';

import { Panel as Container } from '@admin/components/helpers/panel';

import { IAccountPackage } from '@admin/types/account_package';
import { ILaborRate } from '@shared/types/labor_rate';

import {
  AppointmentQuote,
  PricingGroupLaborEntryFragment,
  PricingGroupPricingEntryFragment,
  PricingSetFragment,
} from '@admin/schema';

import { Subscribe } from './subscribe';

export const SubscribePanel: React.FC<{
  zip?: string;
  cuft?: number;
  accountID: number;
  reonboard: boolean;
  onChange(_: {
    activePricingSet?: PricingSetFragment;
    labor?: PricingGroupLaborEntryFragment;
    laborRate?: ILaborRate;
    storage?: PricingGroupPricingEntryFragment;
    protection?: PricingGroupPricingEntryFragment;
    length?: number;
    width?: number;
    quantity?: number;
    accountPackages?: IAccountPackage[];
    appointmentQuote?: AppointmentQuote;
  }): void;
}> = ({ zip, cuft, accountID, reonboard, onChange }) => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  useEffect(() => {
    onChange({});
  }, [collapsed]);

  return (
    <Container>
      <Container.Header>
        {!reonboard && <Container.Collapse collapsed={collapsed} onChange={setCollapsed} />}
        <Container.Title>Subscription</Container.Title>
      </Container.Header>
      {(!collapsed || reonboard) && (
        <Container.Body>
          <Subscribe zip={zip} cuft={cuft} accountID={accountID} reonboard={reonboard} onChange={onChange} />
        </Container.Body>
      )}
    </Container>
  );
};
