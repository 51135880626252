import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import {
  useStorageTreasuresAuctionListQuery,
  StorageTreasures__AuctionStatus,
  StorageTreasuresAuctionListQuery,
  useStorageTreasuresAuctionCancelMutation,
  Status,
  StorageTreasuresAuctionListDocument,
} from '@admin/schema';
import { Box } from '@clutter/clean';
import { AnchorButton as Link, Badge, Table, Modal, Alert, Button } from '@shared/components/bootstrap';
import { Currency, Timestamp } from '@shared/components/helpers';
import React, { useState } from 'react';
import { AuctionFilters, AuctionFilterValues } from './filters';

const AUCTION_STATUS_TO_STYLE: Record<StorageTreasures__AuctionStatus, 'danger' | 'warning' | 'success' | 'info'> = {
  [StorageTreasures__AuctionStatus.Active]: 'info',
  [StorageTreasures__AuctionStatus.Cancelled]: 'warning',
  [StorageTreasures__AuctionStatus.Sold]: 'success',
  [StorageTreasures__AuctionStatus.Unsold]: 'danger',
};

const StatusBadge: React.FC<{ status: StorageTreasures__AuctionStatus }> = ({ status }) => (
  <Badge style={AUCTION_STATUS_TO_STYLE[status]}>{status.toUpperCase()}</Badge>
);

type StorageTreasuresAuction = StorageTreasuresAuctionListQuery['paginated']['results'][0];

const Row: React.FC<{ auction: StorageTreasuresAuction; setAuctionToCancel: () => void }> = ({
  auction,
  setAuctionToCancel,
}) => (
  <tr>
    <td>{auction.id}</td>
    <td>
      <Link kind="primary" href={`/accounts/${auction.auctionedAccount.id}`} target="_blank">
        {auction.auctionedAccount.name}
      </Link>
    </td>
    <td>{<Timestamp value={auction.startTime} format={Timestamp.Format.DateTime} />}</td>
    <td>{<Timestamp value={auction.endTime} format={Timestamp.Format.DateTime} />}</td>
    <td>
      {auction.winner && (
        <Link kind="primary" href={`/accounts/${auction.winner.id}`} target="_blank">
          {auction.winner?.name}
        </Link>
      )}
    </td>
    <td>{auction.winnerInvoice && <Currency value={auction.winnerInvoice.clutterAmount} />}</td>
    <td>
      {auction.winnerOrder && (
        <Link kind="primary" href={`/orders/${auction.winnerOrder.id}`} target="_blank">
          {auction.winnerOrder.number}
        </Link>
      )}
    </td>
    <td>
      <StatusBadge status={auction.status} />
    </td>
    <td className="col-zero text-center">
      <Box.Flex gap={'8px'}>
        <Link kind="primary" href={auction.storageTreasuresLink} target="_blank">
          View
        </Link>
        {auction.status === StorageTreasures__AuctionStatus.Active && (
          <Link kind="warning" onClick={setAuctionToCancel}>
            Cancel
          </Link>
        )}
      </Box.Flex>
    </td>
  </tr>
);

const ViewPermissionDenied: React.FC = () => (
  <tr>
    <td colSpan={12}>You do not have permission to view Storage Treasures auctions.</td>
  </tr>
);

export const StorageTreasuresAuctionList: React.FC = () => {
  const [page, setPage] = useState<number | undefined>();
  const [auctionToCancel, setAuctionToCancel] = useState<StorageTreasuresAuction | undefined>();
  const [filters, setFilters] = useState<AuctionFilterValues>({});

  const { data, loading } = useStorageTreasuresAuctionListQuery({
    client,
    variables: {
      page: page,
      status: filters.status,
      warehouseID: filters.warehouseID,
      minInvoiceAmount: filters.minInvoiceAmount ? parseFloat(filters.minInvoiceAmount) : undefined,
      date: filters.date,
      search: filters.search,
    },
  });
  const paginated = data?.paginated;
  const viewable = data?.permission?.view;

  const handleFilterUpdate = (newFilters: AuctionFilterValues) => {
    setFilters(newFilters);
    setPage(undefined); // Reset to first page when filtering
  };

  return (
    <>
      <AuctionFilters filters={filters} onChangeFilters={handleFilterUpdate} />

      <Panel>
        <Panel.Header>
          <Panel.Title>Storage Treasures</Panel.Title>
        </Panel.Header>
        <Table responsive striped>
          <thead>
            <tr>
              <th>Teal ID</th>
              <th>Auctioned Account</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th>Winner</th>
              <th>Winner Invoice Amount</th>
              <th>Winner Facility Pickup</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading ? (
              viewable ? (
                paginated?.results.map((auction) => (
                  <Row key={auction.id} auction={auction} setAuctionToCancel={() => setAuctionToCancel(auction)}></Row>
                ))
              ) : (
                <ViewPermissionDenied />
              )
            ) : undefined}
          </tbody>
        </Table>
        {loading && <Spinner />}
        {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
        {auctionToCancel && <CancelModal auction={auctionToCancel} onClose={() => setAuctionToCancel(undefined)} />}
      </Panel>
    </>
  );
};

const CancelModal: React.FC<{
  auction: StorageTreasuresAuction;
  onClose(): void;
}> = ({ auction, onClose }) => {
  const [error, setError] = useState<string | undefined>();
  const [reason, setReason] = useState<string>('');

  const [cancel, { loading }] = useStorageTreasuresAuctionCancelMutation({
    client,
    refetchQueries: [
      {
        query: StorageTreasuresAuctionListDocument,
      },
    ],
  });

  const onConfirm = async () => {
    const response = await cancel({ variables: { tealAuctionID: auction.id, reason: reason } });
    if (response && response.data?.storageTreasuresAuctionCancel) {
      if (response.data.storageTreasuresAuctionCancel.error) {
        setError(response.data.storageTreasuresAuctionCancel.error);
      } else if (response.data.storageTreasuresAuctionCancel.status === Status.Ok) {
        onClose();
      }
    }
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            Cancel Auction ID: {auction.id} ({auction.auctionedAccount.name})
          </Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert style="danger">
              <strong>Error: </strong>
              {error}
            </Alert>
          )}
          <p>Why do you want to cancel this auction?</p>
          <input
            type="text"
            value={reason}
            placeholder="Enter reason here"
            onChange={(event) => {
              setReason(event.target.value);
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} disabled={loading} size="lg" kind="info">
            Nevermind
          </Button>
          <Button onClick={onConfirm} disabled={loading || reason.length === 0} size="lg" kind="danger">
            Cancel Auction
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};
