/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS } from '@root/colors';
import React, { useState } from 'react';

import { IDispatchOrder } from '@admin/types/dispatch_order';
import { DateTime } from 'luxon';

import { orderURL } from '@admin/config/routes';
import { OrderStatus } from '@shared/types/order';

import { TooltipPlacement } from '@shared/components/bootstrap/tooltip';
import { format } from './utils';
import { OrderDetail } from '../../order_events/order_detail';
import { DURATION_EVENTS } from '../../order_events/order_detail/duration_utils';
import { Toggle } from '../../order_events/toggle';
import { useTimeZone } from '../context';
import { EventStream } from './event_stream';
import { ExpectedStartTime } from './expected_start_time';
import { SLAWindow } from './sla_window';

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 5px;
  position: relative;
  height: 80px;
  box-sizing: border-box;
  border-bottom: 1px solid gray;

  &:first-child {
    border-top: 1px solid gray;
  }

  &:last-child {
    border-bottom: none;
  }
`;

const Description = styled.a`
  position: relative;
  left: 5px;
  top: 10px;
  padding: 7px;
  background: ${COLORS.grayLightest};
  border-radius: 10px;
  box-sizing: border-box;
`;

const redStripedStyle = css`
  background: repeating-linear-gradient(
    45deg,
    rgba(239, 83, 80, 0.1) 0px,
    rgba(239, 83, 80, 0.1) 4px,
    rgba(239, 83, 80, 0) 4px,
    rgba(239, 83, 80, 0) 8px
  );
`;

const toggleStyle = css`
  position: absolute;
  right: 10px;
  bottom: 20px;
`;

export const OrderGanttRow: React.FC<{
  next?: DateTime;
  order: IDispatchOrder;
  isTerminalOrderEvent: (isSimulated: boolean) => (eventName: string) => boolean;
}> = ({
  order: { id, name, internalScheduled, simulatedOrderEvents, orderEventsExcludingNonLeadAssignments, services },
  next,
  order,
  isTerminalOrderEvent,
}) => {
  const timeZone = useTimeZone();
  const scheduled = format(DateTime.fromISO(internalScheduled).setZone(timeZone));
  const [detailShown, setDetailShown] = useState<boolean>(false);
  const description = `${name} - ${scheduled}`;
  const canceled = order.status === OrderStatus.Canceled;

  const handleToggleClick = () => {
    setDetailShown(!detailShown);
  };

  return (
    <React.Fragment>
      <Row css={canceled && redStripedStyle}>
        <SLAWindow order={order} />
        <div>
          <Description href={orderURL({ id })} target="_blank">
            {description} {services.length > 1 && '*'}
          </Description>
        </div>
        <div>
          <ExpectedStartTime order={order} />
        </div>
        <EventStream
          next={next}
          events={simulatedOrderEvents}
          shade={0.5}
          isTerminalOrderEvent={isTerminalOrderEvent(true)}
        />
        <EventStream
          next={next}
          placement={detailShown ? TooltipPlacement.Top : TooltipPlacement.Bottom}
          events={orderEventsExcludingNonLeadAssignments}
          shade={1}
          isTerminalOrderEvent={isTerminalOrderEvent(false)}
        />
        <Toggle css={toggleStyle} toggled={detailShown} onClick={handleToggleClick} />
      </Row>
      {detailShown && (
        <OrderDetail
          next={next}
          orderEventsExcludingNonLeadAssignments={orderEventsExcludingNonLeadAssignments.filter(({ eventName }) =>
            DURATION_EVENTS.includes(eventName),
          )}
          simulatedOrderEvents={simulatedOrderEvents.filter(({ eventName }) => DURATION_EVENTS.includes(eventName))}
          breakEvents={orderEventsExcludingNonLeadAssignments.filter(({ isBreakEvent }) => isBreakEvent)}
          simulatedBreakEvents={simulatedOrderEvents.filter(({ isBreakEvent }) => isBreakEvent)}
          services={services}
        />
      )}
    </React.Fragment>
  );
};
