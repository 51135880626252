import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import React, { useState } from 'react';
import {
  ActiveStorageAttachment,
  Currency,
  NoteNotableEnum,
  useHiringJobApplicationDetailsQuery,
  useHiringOnsiteInterviewEvaluateMutation,
} from '@admin/schema';
import styled from '@emotion/styled';
import { Timestamp } from '@admin/components/timestamp';
import { Box } from '@clutter/clean';
import { Spacer } from '@shared/components/helpers';
import { DateTime } from 'luxon';
import { Button, Modal } from '@shared/components/bootstrap';
import { ConfirmModal } from '@admin/components/helpers/confirm_modal';
import { NotesPanel } from '@admin/components/notes/panel';
import { getStatusBadgeClass, getStatusDisplayText } from './status';

type JobApplicationData = NonNullable<ReturnType<typeof useHiringJobApplicationDetailsQuery>['data']>['jobApplication'];
type CandidateData = NonNullable<JobApplicationData>['candidate'];
type JobListingData = NonNullable<JobApplicationData>['jobListing'];
type ResponseData = NonNullable<JobApplicationData>['responses'][number];
type OnsiteInterviewData = NonNullable<JobApplicationData>['onsiteInterviews'][number];

const Label = styled.label`
  font-weight: bold;
  font-size: x-small;
`;

const SectionTitle = styled.h4`
  margin-top: 1.25rem;
  margin-bottom: 0.75rem;
  font-size: 1.1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #eee;
`;

const PanelHeading = styled.h3`
  padding: 1rem 1rem 0 1rem;
`;

const PanelContent = styled.div`
  padding: 1rem;
`;

const FormGroup = styled.div`
  margin-bottom: 1rem;
`;

const CandidatePanel: React.FC<{
  candidate: CandidateData;
}> = ({ candidate }) => (
  <Panel>
    <PanelHeading>Candidate</PanelHeading>
    <PanelContent>
      <FormGroup>
        <Label>Name</Label>
        <div>{candidate.name}</div>
      </FormGroup>
      <FormGroup>
        <Label>Email</Label>
        <div>
          <a href={`mailto:${candidate.email}`} className="text-decoration-none">
            {candidate.email}
          </a>
        </div>
      </FormGroup>
      <FormGroup>
        <Label>Phone</Label>
        <div>
          <a href={`tel:${candidate.phone}`} className="text-decoration-none">
            {candidate.phone}
          </a>
        </div>
      </FormGroup>
    </PanelContent>
  </Panel>
);

const JobListingPanel: React.FC<{
  jobListing: JobListingData;
}> = ({ jobListing }) => (
  <Panel>
    <PanelHeading>Job Listing</PanelHeading>
    <PanelContent>
      <Box.Flex flexWrap={'wrap'}>
        <div style={{ flex: '1 1 50%' }}>
          <FormGroup>
            <Label>Title</Label>
            <div>{jobListing.job.title}</div>
          </FormGroup>
          <FormGroup>
            <Label>Facility</Label>
            <div>{jobListing.facility.name}</div>
          </FormGroup>
          <FormGroup>
            <Label>Region</Label>
            <div>{jobListing.region.name}</div>
          </FormGroup>
        </div>
        <div style={{ flex: '1 1 50%' }}>
          <FormGroup>
            <Label>Time Commitment</Label>
            <div>{jobListing.fullTime ? 'Full-Time' : 'Part-Time'}</div>
          </FormGroup>
          <FormGroup>
            <Label>Shift</Label>
            <div>{jobListing.nightShift ? 'Night Shift' : 'Day Shift'}</div>
          </FormGroup>
          <FormGroup>
            <Label>Hourly Rate</Label>
            <div>
              {jobListing.region.currency === Currency.Usd
                ? jobListing.hourlyRate.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
                : jobListing.hourlyRate.toLocaleString('en-CA', { style: 'currency', currency: 'CAD' })}
            </div>
          </FormGroup>
        </div>
      </Box.Flex>
    </PanelContent>
  </Panel>
);

const ApplicationProgressSection: React.FC<{
  application: JobApplicationData;
}> = ({ application }) => (
  <>
    <SectionTitle>Progress</SectionTitle>
    <Box.Flex flexWrap={'wrap'}>
      <div style={{ flex: '1 1 33%', marginBottom: '1rem' }}>
        <Label>Current Status</Label>
        <div style={{ marginTop: '0.25rem' }}>
          <span className={`badge ${getStatusBadgeClass(application.status)}`} style={{ padding: '0.5rem 1rem' }}>
            {getStatusDisplayText(application.status)}
          </span>
        </div>
      </div>
      <div style={{ flex: '1 1 33%', marginBottom: '1rem' }}>
        <Label>Timestamps</Label>
        <div>
          Application Started: <Timestamp value={application.createdAt} />
        </div>
        {application.submittedAt && (
          <div>
            Pre-Screen Passed: <Timestamp value={application.submittedAt} />
          </div>
        )}
        {application.preScreenPassedAt && (
          <div>
            Pre-Screen Passed: <Timestamp value={application.preScreenPassedAt} />
          </div>
        )}
        {application.preScreenFailedAt && (
          <div>
            Pre-Screen Failed: <Timestamp value={application.preScreenFailedAt} />
          </div>
        )}
        {application.onsiteInterviews.map((interview) => (
          <div key={interview.id}>
            {interview.passedAt && (
              <div>
                Interview Passed: <Timestamp value={interview.passedAt} />
              </div>
            )}
            {interview.failedAt && (
              <div>
                Interview Failed: <Timestamp value={interview.failedAt} />
              </div>
            )}
          </div>
        ))}
      </div>
    </Box.Flex>
  </>
);

const ResumeSection: React.FC<{
  resume?: ActiveStorageAttachment | null;
}> = ({ resume }) => (
  <>
    <SectionTitle>Resume</SectionTitle>
    <FormGroup>
      {resume ? (
        <a href={resume.imgixURL} target="_blank">
          View Resume
        </a>
      ) : (
        <div>Not Provided</div>
      )}
    </FormGroup>
  </>
);

const WorkExperienceSection: React.FC<{
  workExperience?: string | null;
}> = ({ workExperience }) => (
  <>
    <SectionTitle>Work Experience</SectionTitle>
    {workExperience ? <div>{workExperience}</div> : <div>Not Provided</div>}
  </>
);

const PreScreenQuestionsSection: React.FC<{
  responses?: ResponseData[];
}> = ({ responses }) => {
  if (!responses || responses.length === 0) return null;

  return (
    <>
      <SectionTitle>Pre-Screen Questions</SectionTitle>
      <div>
        {responses.map((response, index) => (
          <div key={index}>
            <Label>{response.question.text}</Label>
            <div style={{ color: response.failed ? 'red' : 'inherit' }}>{response.response}</div>
          </div>
        ))}
      </div>
    </>
  );
};

const ApplicationPanel: React.FC<{
  application: JobApplicationData;
}> = ({ application }) => (
  <Panel style={{ marginBottom: '1rem' }}>
    <PanelHeading>Application</PanelHeading>
    <PanelContent>
      <ApplicationProgressSection application={application} />
      <Spacer height="1rem" />
      <ResumeSection resume={application.resume} />
      <Spacer height="1rem" />
      <WorkExperienceSection workExperience={application.workExperience} />
      <Spacer height="1rem" />
      <PreScreenQuestionsSection responses={application.responses} />
    </PanelContent>
  </Panel>
);

const InterviewFeedbackModal: React.FC<{
  interview: OnsiteInterviewData;
  onClose: () => void;
  onSubmit: (values: { feedback: string; passed: boolean; noShow: boolean }) => void;
}> = ({ interview, onClose, onSubmit }) => {
  const [feedback, setFeedback] = useState(interview.interviewerFeedback || '');
  const [passed, setPassed] = useState<boolean | null>(null);
  const [noShow, setNoShow] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (passed === null) return;
    onSubmit({ feedback, passed, noShow });
  };

  const decisionMade = passed !== null;

  return (
    <Modal onClose={onClose}>
      <Panel>
        <Panel.Header style={{ padding: '20px' }}>
          <h4>Provide Interview Feedback</h4>
        </Panel.Header>
        <Panel.Body>
          <form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Decision</Label>
              <Box.Flex gap="2rem">
                <div>
                  <input
                    type="radio"
                    name="decision"
                    id="passRadio"
                    checked={passed === true}
                    onChange={() => {
                      setPassed(true);
                      setNoShow(false);
                    }}
                  />
                  <label style={{ paddingLeft: '0.5rem' }}>Pass</label>
                </div>
                <div>
                  <input
                    type="radio"
                    name="decision"
                    id="failRadio"
                    checked={passed === false}
                    onChange={() => setPassed(false)}
                  />
                  <label style={{ paddingLeft: '0.5rem' }}>Fail</label>
                </div>
              </Box.Flex>
            </FormGroup>

            {passed === false && (
              <FormGroup>
                <div>
                  <input type="checkbox" checked={noShow} onChange={() => setNoShow(!noShow)} id="noShowCheckbox" />
                  <label style={{ paddingLeft: '0.5rem' }}>No-Show</label>
                </div>
              </FormGroup>
            )}

            {decisionMade && (
              <FormGroup>
                <Label>Feedback</Label>
                <textarea
                  className="form-control"
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  rows={5}
                  placeholder="Enter any feedback you have. This feedback is for internal use only and will not be sent to the candidate."
                />
              </FormGroup>
            )}
          </form>
        </Panel.Body>
        <Panel.Footer>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button kind="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button kind="primary" onClick={handleSubmit} disabled={passed === null}>
              Submit Feedback
            </Button>
          </div>
        </Panel.Footer>
      </Panel>
    </Modal>
  );
};

const InterviewCard: React.FC<{
  interview: OnsiteInterviewData;
  rescheduleLink: string;
  refetch: () => void;
}> = ({ interview, rescheduleLink, refetch }) => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [feedbackData, setFeedbackData] = useState<{ feedback: string; passed: boolean; noShow: boolean } | undefined>(
    undefined,
  );

  const [evaluateInterview, { loading }] = useHiringOnsiteInterviewEvaluateMutation({
    client,
    onCompleted: () => {
      setShowFeedbackModal(false);
      setShowConfirmModal(false);
      refetch();
    },
  });

  const handleFeedbackSubmit = (values: { feedback: string; passed: boolean; noShow: boolean }) => {
    setFeedbackData(values);
    setShowFeedbackModal(false);
    setShowConfirmModal(true);
  };

  const handleConfirm = () => {
    if (feedbackData) {
      evaluateInterview({
        variables: {
          input: {
            id: interview.id,
            feedback: feedbackData.feedback,
            passed: feedbackData.noShow ? false : feedbackData.passed,
            noShow: feedbackData.noShow,
          },
        },
      });
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: '10px', right: '10px', display: 'flex', gap: '0.5rem' }}>
        {interview.passedAt && <span className="badge bg-success">Passed</span>}
        {interview.failedAt && <span className="badge bg-danger">Failed</span>}
        {interview.noShow && <span className="badge bg-warning text-dark">No Show</span>}
      </div>
      <div style={{ padding: '1.5rem' }}>
        <Box.Flex flexWrap={'wrap'}>
          <div style={{ flex: '1 1 50%' }}>
            <FormGroup>
              <Label>Date & Time</Label>
              <div>
                {DateTime.fromISO(interview.scheduledStart, { zone: interview.facility.tz }).toLocaleString(
                  DateTime.DATE_FULL,
                )}
              </div>
              <div>
                {DateTime.fromISO(interview.scheduledStart, { zone: interview.facility.tz }).toLocaleString(
                  DateTime.TIME_SIMPLE,
                )}{' '}
                -{' '}
                {DateTime.fromISO(interview.scheduledEnd, { zone: interview.facility.tz }).toLocaleString(
                  DateTime.TIME_SIMPLE,
                )}
              </div>
            </FormGroup>
            <FormGroup>
              <Label>Facility</Label>
              <div>{interview.facility.name}</div>
            </FormGroup>
            {interview.interviewer && (
              <FormGroup>
                <Label>Interviewer</Label>
                <div>{interview.interviewer.name}</div>
              </FormGroup>
            )}
            {interview.interviewerFeedback && (
              <div style={{ marginTop: '1rem' }}>
                <Label>Interviewer Feedback</Label>
                <div>{interview.interviewerFeedback}</div>
              </div>
            )}
          </div>
        </Box.Flex>
        {!interview.passedAt && !interview.failedAt && (
          <Box.Flex gap="8px" justifyContent="flex-end" style={{ marginTop: '1rem' }}>
            <Button kind="primary" onClick={() => window.open(rescheduleLink, '_blank')}>
              Reschedule
            </Button>
            <Button kind="primary" onClick={() => setShowFeedbackModal(true)} disabled={loading}>
              {loading ? 'Submitting...' : 'Provide Feedback'}
            </Button>
          </Box.Flex>
        )}
      </div>

      {showFeedbackModal && (
        <InterviewFeedbackModal
          interview={interview}
          onClose={() => setShowFeedbackModal(false)}
          onSubmit={handleFeedbackSubmit}
        />
      )}

      {showConfirmModal && feedbackData && (
        <ConfirmModal
          message={`Are you sure you want to ${feedbackData.passed ? 'PASS' : 'FAIL'} this candidate${
            feedbackData.noShow ? ' and mark them as a no-show' : ''
          }?`}
          onConfirm={handleConfirm}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};

const InterviewsPanel: React.FC<{
  interviews: OnsiteInterviewData[] | null | undefined;
  rescheduleLink: string;
  refetch: () => void;
}> = ({ interviews, rescheduleLink, refetch }) => (
  <Panel style={{ marginBottom: '1.5rem' }}>
    <PanelHeading>Interviews</PanelHeading>
    <PanelContent>
      {interviews && interviews.length > 0 ? (
        interviews.map((interview, index) => (
          <React.Fragment key={interview.id}>
            <div style={{ marginBottom: index < interviews.length - 1 ? '0' : '1.5rem' }}>
              <InterviewCard interview={interview} rescheduleLink={rescheduleLink} refetch={refetch} />
            </div>
            {index < interviews.length - 1 && <hr style={{ margin: '1.5rem 0' }} />}
          </React.Fragment>
        ))
      ) : (
        <div style={{ margin: '1rem' }}>None Scheduled</div>
      )}
    </PanelContent>
  </Panel>
);

export const HiringJobApplicationReview: React.FC<{
  id: string;
}> = ({ id }) => {
  const { data, loading, refetch } = useHiringJobApplicationDetailsQuery({
    client,
    variables: { id },
    fetchPolicy: 'network-only',
  });

  if (loading) return <Spinner />;
  if (!data?.jobApplication) return <div>Application Not Found</div>;

  const application = data.jobApplication;
  const candidate = application.candidate;
  const jobListing = application.jobListing;

  return (
    <>
      <Box.Flex gap="1.5rem" flexWrap={'wrap'}>
        <div style={{ flex: '1 1 calc(50% - 0.75rem)' }}>
          <CandidatePanel candidate={candidate} />
        </div>

        <div style={{ flex: '1 1 calc(50% - 0.75rem)' }}>
          <JobListingPanel jobListing={jobListing} />
        </div>
      </Box.Flex>

      <Box.Flex gap="1.5rem" flexWrap={'wrap'}>
        <div style={{ flex: '1 1 calc(50% - 0.75rem)' }}>
          <ApplicationPanel application={application} />
        </div>

        <div style={{ flex: '1 1 calc(50% - 0.75rem)' }}>
          <InterviewsPanel
            interviews={application.onsiteInterviews}
            rescheduleLink={application.unshortenedCareersURL}
            refetch={refetch}
          />
          <NotesPanel type={NoteNotableEnum.JobApplication} id={application.id} />
        </div>
      </Box.Flex>
    </>
  );
};
