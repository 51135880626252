import { AddressForm } from '@admin/components/address/address_form';
import { Selector as RegionsSelector } from '@admin/components/regions/selector';
import { DepotInput } from '@admin/schema';
import * as React from 'react';
import { TZS } from '@admin/constants/time_zone';
import { SelectFormControl } from '../fields';

export const DepotForm: React.FC<{
  depot: DepotInput;
  onChange(depot: DepotInput): void;
  editAccess?: boolean;
}> = ({ onChange, depot, editAccess = true }) => {
  const { name, abbreviation, active, tz } = depot;
  return (
    <>
      <div className="form-group depot">
        <label className="control-label" htmlFor="depot__name">
          <strong>Name</strong>
        </label>
        <input
          id="depot__name"
          type="text"
          className="form-control"
          value={name}
          onChange={(event) => {
            onChange({ ...depot, name: event.target.value });
          }}
          disabled={!editAccess}
        />
      </div>
      <div className="form-group depot">
        <label className="control-label" htmlFor="depot__abbr">
          <strong>Abbreviation</strong>
        </label>
        <input
          id="depot__abbr"
          type="text"
          className="form-control"
          value={abbreviation}
          onChange={(event) => {
            onChange({ ...depot, abbreviation: event.target.value });
          }}
          disabled={!editAccess}
        />
      </div>
      <div className="form-group depot">
        <label className="control-label" htmlFor="depot__active">
          <strong>Active</strong>
        </label>
        <input
          id="depot__active"
          type="checkbox"
          className="form-control"
          checked={active}
          onChange={(event) => {
            onChange({ ...depot, active: event.target.checked });
          }}
          disabled={!editAccess}
        />
      </div>
      <AddressForm
        address={depot.address}
        onChange={(address) => {
          onChange({ ...depot, address });
        }}
        disabled={!editAccess}
      />
      <div className="form-group depot">
        <label className="control-label" htmlFor="depot__tz">
          <strong>Time Zone</strong>
        </label>
        <SelectFormControl
          id="depot__tz"
          options={TZS}
          value={tz ?? undefined}
          onChange={(option) => {
            onChange({ ...depot, tz: option || '' });
          }}
          prompt=" - Select Time Zone -"
          disabled={!editAccess}
        />
      </div>
      <div className="form-group depot">
        <label className="control-label">
          <strong>Regions:</strong>
        </label>
        <RegionsSelector
          selectedIDs={depot.regionIDs}
          onChange={(regionIDs) => {
            onChange({ ...depot, regionIDs });
          }}
          disabled={!editAccess}
        />
      </div>
    </>
  );
};
