import styled from '@emotion/styled';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

import { CopyText } from '@admin/components/helpers/copy_text';
import { Roles } from '@admin/components/helpers/roles';
import { accountPortalURL, enterpriseAccountPortalURL } from '@admin/config/routes';
import { qaEnvironment } from '@admin/config/qa_environment';
import { client } from '@admin/libraries/apollo';
import { useGenerateTestPasswordMutation, Status } from '@admin/schema';
import { UserRole } from '@shared/types/user_role';
import { COLORS } from '@root/colors';
import { Button, Text } from '@shared/components/bootstrap';

const ExternalLink = styled.a`
  border: none;
  background: none;
  color: ${COLORS.gray};

  &:hover {
    color: ${COLORS.blue};
  }
`;

export const GenerateTestPassword: React.FC<{
  resourceID: string;
  resourceType: string;
  email?: string;
}> = ({ resourceID, resourceType, email }) => {
  const [password, setPassword] = useState<string | undefined | null>();
  const [error, setError] = useState<string | undefined | null>();
  const [generate, { loading }] = useGenerateTestPasswordMutation({ client });

  const roles = () => {
    switch (resourceType) {
      case 'User':
        return [UserRole.Admin, UserRole.Manager];
      default:
        return [UserRole.Admin, UserRole.L2Agent, UserRole.L1Agent];
    }
  };

  const generatePassword = async () => {
    const response = await generate({ variables: { resourceID: resourceID, resourceType: resourceType } });

    if (response?.data?.generate.status === Status.Unprocessable) {
      setError(response?.data?.generate.error);
      setPassword(undefined);
      return;
    }
    setError(undefined);
    setPassword(response?.data?.generate?.password);
  };

  if (!qaEnvironment) {
    return null;
  }

  const search = email ? new URLSearchParams({ email }).toString() : '';
  const portalURLWithQuery = `${accountPortalURL}/login?${search}`;
  const enterprisePortalURLWithQuery = `${enterpriseAccountPortalURL}/sign_in?${search}`;

  return (
    <Roles show={roles()}>
      <Button kind="default" onClick={generatePassword} loading={loading}>
        Generate Test Password
      </Button>
      {error && (
        <div className="mar-top">
          <Text style="danger">{error}</Text>
        </div>
      )}
      {password && (
        <>
          <div className="mar-top">
            <Text>{password}</Text>
            <CopyText text={password} />
          </div>
          {resourceType === 'Customer' && (
            <>
              <div className="mar-top">
                <Text>Open Account Portal</Text>{' '}
                <ExternalLink href={portalURLWithQuery} target="_blank">
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </ExternalLink>
              </div>

              <div className="mar-top">
                <Text>Open Enterprise Account Portal</Text>{' '}
                <ExternalLink href={enterprisePortalURLWithQuery} target="_blank">
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                </ExternalLink>
              </div>
            </>
          )}
        </>
      )}
    </Roles>
  );
};
