import { Box } from '@clutter/clean';
import React, { useState } from 'react';

import { Button, Text } from '@shared/components/bootstrap';

import { Roles } from '@admin/components/helpers/roles';
import { accountURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import { SettingsFragment, Status, useChangeAccountSettingsMutation } from '@admin/schema';
import { UserRole } from '@shared/types/user_role';
import { FEATURE_FLAG } from '@shared/config/feature_flag';

export const AccountSettingsForm: React.FC<{
  accountID: string;
  savedSettings: SettingsFragment;
  onSave(): void;
}> = ({ accountID, savedSettings, onSave }) => {
  const [settings, setSettings] = useState<SettingsFragment>(savedSettings);
  const [error, setError] = useState<string>();

  const [execute, { loading }] = useChangeAccountSettingsMutation({
    client,
  });

  const onSubmit = async (event: React.FormEvent) => {
    setError(undefined);
    event.preventDefault();
    event.stopPropagation();
    const response = await execute({
      variables: {
        input: {
          accountID: String(accountID),
          internal: settings.internal,
          isBusiness: settings.isBusiness,
          skipIncomingIvr: settings.skipIncomingIvr,
          isThirdPartyLogistics: settings.isThirdPartyLogistics,
          emailReceipts: settings.emailReceipts,
          skuTeachInOptOut: settings.skuTeachInOptOut,
          dicentralAccountID: settings.dicentralAccountID,
        },
      },
    });

    if (response.data?.changeAccountSettings.status === Status.Unprocessable) {
      setError(response.data.changeAccountSettings.error ?? 'There was an error');
    } else {
      onSave();
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="panel">
        <div className="panel-heading">
          <h3 className="panel-title">Preferences</h3>
        </div>
        <div className="panel-body">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={settings.emailReceipts}
                onChange={(event) => {
                  setSettings({
                    ...settings,
                    emailReceipts: event.target.checked,
                  });
                }}
              />
              Send me email receipts for charges and refunds.
            </label>
          </div>
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={settings.isBusiness}
                onChange={(event) => {
                  setSettings({
                    ...settings,
                    isBusiness: event.target.checked,
                  });
                }}
              />
              Business Account
            </label>
          </div>
          <Roles show={[UserRole.Admin]}>
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={settings.internal}
                  onChange={(event) => {
                    setSettings({
                      ...settings,
                      internal: event.target.checked,
                    });
                  }}
                />
                Internal Account
              </label>
            </div>
          </Roles>
          {FEATURE_FLAG.new_ivr_flow_2022_06 && (
            <div className="checkbox">
              <label>
                <input
                  type="checkbox"
                  checked={settings.skipIncomingIvr}
                  onChange={(event) => {
                    setSettings({
                      ...settings,
                      skipIncomingIvr: event.target.checked,
                    });
                  }}
                />
                Allow Incoming Calls
              </label>
            </div>
          )}
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={settings.isThirdPartyLogistics}
                onChange={(event) => {
                  const isThirdPartyLogistics = event.target.checked;
                  const skuTeachInOptOut = isThirdPartyLogistics ? settings.skuTeachInOptOut : false;
                  const dicentralAccountID = isThirdPartyLogistics ? settings.dicentralAccountID : undefined;
                  setSettings({
                    ...settings,
                    isThirdPartyLogistics,
                    skuTeachInOptOut,
                    dicentralAccountID,
                  });
                }}
              />
              Third Party Logistics Account
            </label>
          </div>
          <Roles show={[UserRole.Admin]}>
            <Box margin="0 0 0 20px">
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    disabled={!settings.isThirdPartyLogistics}
                    checked={settings.skuTeachInOptOut}
                    onChange={(event) => {
                      setSettings({
                        ...settings,
                        skuTeachInOptOut: event.target.checked,
                      });
                    }}
                  />
                  Opt out of teach in for all SKUs
                </label>
              </div>
            </Box>
            <Box margin="0 0 0 20px">
              <label>
                DiCentral Account ID:{' '}
                <input
                  type="text"
                  disabled={!settings.isThirdPartyLogistics}
                  value={settings.dicentralAccountID || undefined}
                  onChange={(event) => {
                    setSettings({
                      ...settings,
                      dicentralAccountID: event.target.value,
                    });
                  }}
                />
              </label>
            </Box>
          </Roles>
        </div>

        <div className="panel-footer text-right">
          {error && (
            <div>
              <Text style="danger">{error}</Text>
            </div>
          )}
          <a className="btn btn-default" href={accountURL({ id: accountID })}>
            Cancel
          </a>{' '}
          <Button type="submit" kind="primary" disabled={loading}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};
