import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { Roles } from '@admin/components/helpers/roles';
import { Spinner } from '@admin/components/spinner';
import { UserRole } from '@shared/types/user_role';
import { opsAuditConfigurationsURL } from '@admin/config/routes';

import { useOpsAuditConfigurationQuery } from '@admin/schema';

import { OpsAuditConfigurationForm, EditFormData } from './form/form';

export const OpsAuditEditConfiguration: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading } = useOpsAuditConfigurationQuery({ client, variables: { id: id } });

  if (loading || !data?.opsAuditConfiguration) {
    return <Spinner />;
  }

  const auditConfig = data.opsAuditConfiguration;

  const formData: EditFormData = {
    id: auditConfig.id,
    refreshIntervalMinutes: auditConfig.refreshIntervalMinutes ?? undefined,
    hoursUntilExpired: auditConfig.hoursUntilExpired ?? undefined,
    name: auditConfig.name,
    dataQuery: auditConfig.dataQuery,
    exceptionReasonCodes: auditConfig.exceptionReasonCodes,
    questions: auditConfig.questions,
  };

  const onSubmit = async () => {
    window.location.href = opsAuditConfigurationsURL();
  };

  return (
    <Roles show={[UserRole.Admin]}>
      <OpsAuditConfigurationForm formData={formData} onSave={onSubmit} />
    </Roles>
  );
};
