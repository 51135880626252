import axios from 'axios';
import * as React from 'react';

import { IPagination } from '@admin/types/pagination';
import { ITimelineActivity } from '@admin/types/timeline_activity';

import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';

import { Table } from './table';

interface IPayload {
  results: ITimelineActivity[];
  pagination: IPagination;
}

interface IResource {
  id: number;
  type: 'items' | 'pallets';
}

interface IPanelProps {
  resource: IResource;
  timezone: string;
}

interface IPanelState {
  payload?: IPayload;
}

class TimelineActivityPanel extends React.Component<IPanelProps, IPanelState> {
  public state: IPanelState = { payload: undefined };

  public componentDidMount() {
    this.fetch();
  }

  public render() {
    const { payload } = this.state;
    return (
      <div className="panel">
        <div className="panel-heading">
          <h3 className="panel-title">Scan History</h3>
        </div>
        <div className="panel-body">
          {payload && !!payload.results.length && (
            <Table activities={payload.results} timezone={this.props.timezone} resource={this.props.resource} />
          )}
          {payload && !payload.results.length && <p className="text-center">no activities have been generated yet</p>}
          {!payload && <Spinner />}
          {payload && <Pagination onPage={this.fetch} pagination={payload.pagination} />}
        </div>
      </div>
    );
  }

  private fetch = async (page?: number) => {
    const { resource } = this.props;
    const { type, id } = resource;
    const { data } = await axios.get<IPayload>(`/${type}/${id}/timeline_activities.json`, { params: { page } });
    this.setState({ payload: data });
  };
}

export { TimelineActivityPanel as Panel, IResource };
