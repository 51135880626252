import { client } from '@admin/libraries/apollo';
import { Hiring__Job__Input, Status, useHiringJobUpdateMutation, useHiringJobDetailsQuery } from '@admin/schema';
import React from 'react';
import type { FC } from 'react';
import { HiringJobForm } from './form';

export const HiringJobEdit: FC<{
  id: string;
}> = ({ id }) => {
  const { data: defaults } = useHiringJobDetailsQuery({ client, variables: { id } });
  const [execute, { loading: saving, data }] = useHiringJobUpdateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) window.location.href = '/hiring/job_listings';
    },
  });

  const save = (input: Hiring__Job__Input) => {
    execute({ variables: { id, input } });
  };

  const error = data?.result?.error;
  if (!defaults) return null;

  return <HiringJobForm save={save} saving={saving} error={error} defaults={defaults.hiringJob} />;
};
