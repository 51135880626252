import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { useAccountImpersonateCustomerLinkCreateMutation } from '@admin/schema';
import { UserRole } from '@shared/types/user_role';
import { Alert, Button, Modal, Text } from '@shared/components/bootstrap';
import { FEATURE_FLAG } from '@shared/config/feature_flag';
import { Roles } from '../helpers/roles';

const ConfirmationModal: React.FC<{ customerID: string; onClose: () => void }> = ({ customerID, onClose }) => {
  const [execute, { loading }] = useAccountImpersonateCustomerLinkCreateMutation({
    client,
    variables: { customerID },
  });
  const [error, setError] = useState<string | undefined>(undefined);

  const onConfirm = async () => {
    setError(undefined);
    const { data, errors } = await execute();

    setError(errors && errors[0].message);

    const url = data?.result.url;
    if (url) {
      open(url);
      onClose();
    }
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Access Customer Account</Modal.Title>
          <Modal.Close close={onClose} />
        </Modal.Header>
        <Modal.Body>
          {error && (
            <Alert style="danger">
              <strong>Error: </strong>
              {error}
            </Alert>
          )}
          <Text>
            ATTENTION: You are logging into the customer’s actual account portal. Any changes you make will be seen by
            the customer.
          </Text>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClose} disabled={loading} size="lg">
            Cancel
          </Button>
          <Button onClick={onConfirm} loading={loading} size="lg" kind="danger">
            Access Customer Account
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export const AccessCustomerAccountButton: React.FC<{
  customerID: string;
}> = ({ customerID }) => {
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <Roles
      show={
        FEATURE_FLAG.l1_impersonator_mode_access_2024_02
          ? [UserRole.Admin, UserRole.L1Agent, UserRole.L2Agent]
          : [UserRole.Admin, UserRole.L2Agent]
      }
    >
      <Button kind="default" onClick={() => setShowModal(true)}>
        Access Customer Account
      </Button>
      {showModal && <ConfirmationModal customerID={customerID} onClose={() => setShowModal(false)} />}
    </Roles>
  );
};
