import styled from '@emotion/styled';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

import { currentUser } from '@admin/config/current_user';
import { Button, Modal, Text } from '@shared/components/bootstrap';
import { NoteFragment, Status, useModifyNoteMutation, useNoteDestroyMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';

import { ROLES } from '@admin/config/roles';
import { UserRole } from '@shared/types/user_role';
import { Files } from './files';

const IconContainer = styled.span`
  font-size: 14px;
  cursor: pointer;
  margin: 0 2px;
`;

const Delete: React.FC<{ note: NoteFragment }> = ({ note }) => {
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [destroy, { loading: destroying }] = useNoteDestroyMutation({ client });

  const onDestroy = async () => {
    setError(false);
    const response = await destroy({ variables: { id: note.id } });
    if (response?.data?.noteDestroy?.status === Status.Unprocessable) {
      setError(true);
    } else {
      client.cache.evict({ id: client.cache.identify(note) });
    }
  };

  const onClose = () => {
    setShowConfirm(false);
    setError(false);
  };

  return (
    <>
      <IconContainer onClick={() => setShowConfirm(true)}>
        <FontAwesomeIcon icon={faTrash} />
      </IconContainer>
      {showConfirm && (
        <Modal centered onClose={onClose}>
          <Modal.Content>
            <Modal.Header>
              <Modal.Close close={onClose} disabled={destroying} />
            </Modal.Header>
            <Modal.Body>
              <p>Are you sure you want to delete this note?</p>
              {error && (
                <Text tag="p" style="danger">
                  You may only delete a note that you created.
                </Text>
              )}
              <Button kind="primary" onClick={onClose} disabled={destroying}>
                No
              </Button>
              <Button kind="danger" onClick={onDestroy} disabled={destroying} style={{ marginLeft: '5px' }}>
                Yes
              </Button>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};

export const Entry: React.FC<{
  note: NoteFragment;
  supplementary: boolean; // Indicates if the note needs to have the "notable" listed.
}> = ({ note, supplementary }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [save, { data, loading }] = useModifyNoteMutation({ client });
  const [body, setBody] = useState<string>(note.body);

  const saveable = body && !!body.trim().length && !loading;
  const isAuthor = currentUser.id === Number(note.user.id);
  const isAdmin = ROLES.includes(UserRole.Admin);

  const onSubmit = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!saveable) {
      return;
    }
    await save({
      variables: {
        id: note.id,
        body,
      },
    });
  };

  if (data && data.modifyNote.status === 'OK' && editing && saving) {
    setEditing(false);
    setSaving(false);
  }

  return (
    <>
      {!editing && <p className="pre-wrap">{note.body}</p>}
      {editing && (
        <>
          <textarea
            style={{ width: '100%', resize: 'vertical' }}
            className="pre-wrap"
            value={body}
            onChange={(event) => setBody(event.target.value)}
          />
          <br />
        </>
      )}
      <small>
        <span>by</span> <strong>{note.user.name}</strong> <span>on</span>{' '}
        <span>{DateTime.fromISO(note.updatedAt || note.createdAt).toLocaleString(DateTime.DATETIME_MED)}</span>{' '}
        {supplementary && (
          <>
            for{' '}
            <span className="label label-default">
              {note.notable.type}:{note.notable.id}
            </span>
          </>
        )}{' '}
        {!editing && (isAuthor || isAdmin) && (
          <IconContainer onClick={() => setEditing(true)}>
            <FontAwesomeIcon icon={['far', 'edit']} />
          </IconContainer>
        )}
        {editing && (
          <IconContainer
            onClick={(event) => {
              setSaving(true);
              onSubmit(event);
            }}
          >
            <FontAwesomeIcon icon={['far', 'save']} />
          </IconContainer>
        )}
        {isAuthor && !editing && <Delete note={note} />}
      </small>
      {!!note.files.length && (
        <Files>
          {note.files.map((file) => (
            <Files.Download key={file.id} target="_blank" href={file.url}>
              <FontAwesomeIcon icon="download" color="#444444" /> {file.filename}
            </Files.Download>
          ))}
        </Files>
      )}
    </>
  );
};
