import { head } from 'lodash';
import * as React from 'react';

import { IItem } from '@admin/types/item';

import { Fit, Picture } from '@shared/components/helpers/picture';

export const Hero: React.FC<{
  selection: IItem;
  width: number;
  height: number;
  className?: string;
}> = ({ selection, width, height, className }) => {
  const images = selection.images;
  const image = images[selection.hero] || head(images);
  const source = image && image.state === 'uploaded' && image.source;

  return source ? (
    <Picture className={className} width={width} height={height} source={source} fit={Fit.Fill} background="FFF" />
  ) : (
    <img className={className} src="/missing.svg" />
  );
};
