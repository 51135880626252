import { client } from '@admin/libraries/apollo';
import {
  Hiring__Question__Input,
  Status,
  useHiringQuestionUpdateMutation,
  useHiringQuestionDetailsQuery,
} from '@admin/schema';
import React from 'react';
import type { FC } from 'react';
import { HiringQuestionForm } from './form';

export const HiringQuestionEdit: FC<{
  id: string;
}> = ({ id }) => {
  const { data: defaults } = useHiringQuestionDetailsQuery({ client, variables: { id } });
  const [execute, { loading: saving, data }] = useHiringQuestionUpdateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) window.location.href = '/hiring/pre_screen_questions';
    },
  });

  const save = (input: Hiring__Question__Input) => {
    execute({ variables: { id, input } });
  };

  const error = data?.result?.error;
  if (!defaults) return null;

  return (
    <HiringQuestionForm
      save={save}
      saving={saving}
      error={error}
      defaults={{
        ...defaults.hiringQuestion,
        jobIDs: defaults.hiringQuestion.jobs.map((job) => job.id),
        options: defaults.hiringQuestion.options.map((option) => ({
          text: option,
          failing: defaults.hiringQuestion.failingOptions.includes(option),
        })),
      }}
    />
  );
};
