import { OrderStateEnum } from '@admin/schema';
import { Scheduled } from '@admin/components/orders/scheduled';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { ManageFollowUpsQuery, OrderSubtypeEnum, useManageFollowUpsQuery, useOrderCancelMutation } from '@admin/schema';
import { Button, Modal, Panel, Table, Text } from '@shared/components/bootstrap';
import React, { useState } from 'react';
import { Checkbox } from '@clutter/clean';
import { OPTIONS } from '@admin/components/fields/formik/formik_moving_subtype_form_group';
import { CreateOrEditModal } from './create_or_edit_modal';

export type ManageFollowUpsOrder = ManageFollowUpsQuery['order']['sourceAndSuccessors'][number];

export const ManageFollowUps = ({ orderID }: { orderID: string }) => {
  const { data, loading: ordersLoading, refetch } = useManageFollowUpsQuery({ client, variables: { orderID } });
  const [cancelProcessing, setCancelProcessing] = useState<boolean>(false);
  const [editingOrder, setEditingOrder] = useState<ManageFollowUpsOrder | undefined>(undefined);
  const [creatingOrder, setCreatingOrder] = useState<boolean>(false);
  const [cancelingOrder, setCancelingOrder] = useState<ManageFollowUpsOrder | undefined>(undefined);
  const [chargeCancellationFee, setChargeCancellationFee] = useState<boolean>(false);

  const [orderCancel, { error: orderCancelError }] = useOrderCancelMutation({ client });

  const cancel = async (cancelOrderID: string, allOtherOrdersCanceled: boolean) => {
    setCancelProcessing(true);

    await orderCancel({
      variables: {
        input: {
          orderID: cancelOrderID,
          chargeCancellationFee,
          cancelReasonID: null,
          silenceCancelNotification: !allOtherOrdersCanceled,
          suppressSuccessorCancellations: true,
        },
      },
    });

    refetch();
    setCancelingOrder(undefined);
    setCancelProcessing(false);
  };

  const sourceAndSuccessors = data?.order.sourceAndSuccessors;

  if (!sourceAndSuccessors) {
    return null;
  }

  return (
    <>
      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th>Scheduled Time</th>
                <th>Type</th>
                <th>Origin</th>
                <th>Destination</th>
                <th>Mover Count</th>
                <th>Region</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {sourceAndSuccessors.map((order) => (
                <tr key={order.id}>
                  <td>
                    <Scheduled order={order} />
                  </td>
                  <td>{OPTIONS.find((option) => option.subtype === order.subtype)?.displayName}</td>
                  <td>
                    {order.subtype !== OrderSubtypeEnum.Unpacking && order.movingOperation?.originAddress.formatted}
                  </td>
                  <td>
                    {order.subtype !== OrderSubtypeEnum.Packing && order.movingOperation?.destinationAddress.formatted}
                  </td>
                  <td>{order.movers}</td>
                  <td>{order.region.name}</td>
                  {order.state === OrderStateEnum.Approved ? (
                    <td>
                      <Button.Group>
                        <Button onClick={() => setEditingOrder(order)} kind="default" size="sm">
                          Edit
                        </Button>
                        <Button onClick={() => setCancelingOrder(order)} kind="danger" size="sm">
                          Cancel
                        </Button>
                      </Button.Group>
                    </td>
                  ) : (
                    order.state
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
          {ordersLoading && <Spinner />}
        </Panel.Body>
        {editingOrder && (
          <CreateOrEditModal
            sourceOrder={sourceAndSuccessors[0]}
            editingOrder={editingOrder}
            onCancel={() => setEditingOrder(undefined)}
            onSave={() => {
              refetch();
              setEditingOrder(undefined);
            }}
          />
        )}
        {creatingOrder && (
          <CreateOrEditModal
            sourceOrder={sourceAndSuccessors[0]}
            onCancel={() => setCreatingOrder(false)}
            onSave={() => {
              refetch();
              setCreatingOrder(false);
            }}
          />
        )}
        {cancelingOrder && (
          <Modal onClose={() => setCancelingOrder(undefined)}>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Are you sure you want to cancel this order?</Modal.Title>
              </Modal.Header>
              {cancelingOrder.cancellationFeeDescription && (
                <Modal.Body>
                  <Checkbox.Selector
                    name="cancellation-fee"
                    values={{ 'charge-cancellation-fee': chargeCancellationFee }}
                    options={[
                      {
                        key: 'charge-cancellation-fee',
                        label: `Charge Reschedule Fee (${cancelingOrder.cancellationFeeDescription})`,
                      },
                    ]}
                    onChange={() => setChargeCancellationFee(!chargeCancellationFee)}
                  />
                </Modal.Body>
              )}
              <Modal.Footer>
                <Button kind="default" onClick={() => setCancelingOrder(undefined)}>
                  Keep Order
                </Button>
                <Button
                  kind="danger"
                  loading={cancelProcessing}
                  onClick={() => cancel(cancelingOrder.id, sourceAndSuccessors.length === 1)}
                >
                  Cancel Order
                </Button>
              </Modal.Footer>
            </Modal.Content>
          </Modal>
        )}
        <Panel.Footer align="right">
          {orderCancelError && <Text style="danger">{orderCancelError.message}</Text>}
          <Button onClick={() => setCreatingOrder(true)} kind="primary" size="sm">
            New
          </Button>
        </Panel.Footer>
      </Panel>
    </>
  );
};
