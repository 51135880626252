import { sumBy } from 'lodash';

import {
  Admin__Billing__InvoiceFragment as Invoice,
  Billing__OfflinePayment__Kind as OfflinePaymentKind,
  Maybe,
} from '@admin/schema';
import { BillingMethod } from '@admin/types/billing/source';
import { BillingMethodSourceType } from '@admin/types/billing/source';
import { OfferChanges } from '@admin/types/payment_plan/offer_changes';

const PLAN_TYPE_INVALID = 'Please select a plan type.';
const INVOICES_INVALID = 'Please select invoices.';
const OFFER_WITHOUT_FEE = 'Please configure at least one fee.';
const ONE_TIME_FEE_TOO_SMALL = 'One time fee must be greater than $0.';
const STORAGE_TERM_TOO_SMALL = 'Storage commitment must be at least one month.';
const STORAGE_TERM_FRACTION = 'Storage commitment cannot be a fraction of month.';
const MONTHLY_FEE_TOO_SMALL = 'Monthly fee must be greater than $0.';
const MONTHLY_FEE_TERM_TOO_SMALL = 'Monthly fee term must be at least two months.';
const MONTHLY_FEE_TERM_FRACTION = 'Monthly fee term cannot be a fraction of month.';
const EXPIRY_INVALID = 'Please select a valid expiry date.';
const FEES_TOO_LARGE = 'Total fees on payment plan exceed the total amount due.';
const SOURCE_INVALID = 'Please select a valid source.';
const LUMP_SUM_SINGLE_INVOICE =
  'Paying a single invoice with a Lump Sum Payment Plan is not supported: use the account’s billing page instead.';

const validateOneTimeFee = (oneTimeFee?: Maybe<number>) => {
  if (!oneTimeFee || oneTimeFee! <= 0) {
    return ONE_TIME_FEE_TOO_SMALL;
  }
};

const validateStorageTerm = (storageTerm?: Maybe<number>) => {
  if (!storageTerm || storageTerm <= 0) {
    return STORAGE_TERM_TOO_SMALL;
  } else if (storageTerm && storageTerm % 1) {
    return STORAGE_TERM_FRACTION;
  }
};

const validateMonthyFee = (monthlyFee?: Maybe<number>, monthlyFeeTerm?: Maybe<number>) => {
  if (!monthlyFee || monthlyFee <= 0) {
    return MONTHLY_FEE_TOO_SMALL;
  } else if (!monthlyFeeTerm || monthlyFeeTerm <= 1) {
    return MONTHLY_FEE_TERM_TOO_SMALL;
  } else if (monthlyFeeTerm && monthlyFeeTerm % 1) {
    return MONTHLY_FEE_TERM_FRACTION;
  }
};

const validateSource = (source?: BillingMethod) => {
  const sourceMissing = !source?.kind;
  const transactionNumberMissing =
    source?.type === BillingMethodSourceType.Other &&
    source.kind !== OfflinePaymentKind.Cash &&
    !source.transactionNumber;
  if (sourceMissing || transactionNumberMissing) {
    return SOURCE_INVALID;
  }
};

export const validateOfferChanges = (
  offerChanges: OfferChanges,
  selectedInvoices: Invoice[],
  oneTimeFeeIncluded: boolean,
  storageTermIncluded: boolean,
  monthlyFeeIncluded: boolean,
  payImmediately: boolean,
  source?: BillingMethod,
) => {
  const totalAmountDue = sumBy(selectedInvoices, (invoice) => invoice.amountDue);
  if (!offerChanges.type) {
    return PLAN_TYPE_INVALID;
  } else if (!selectedInvoices.length) {
    return INVOICES_INVALID;
  } else if (!oneTimeFeeIncluded && !monthlyFeeIncluded) {
    return OFFER_WITHOUT_FEE;
  } else if (!payImmediately && !offerChanges.expiry) {
    return EXPIRY_INVALID;
  } else if (payImmediately && selectedInvoices.length === 1) {
    return LUMP_SUM_SINGLE_INVOICE;
  }

  let errorMessage = oneTimeFeeIncluded && validateOneTimeFee(offerChanges.oneTimeFee);
  if (errorMessage) {
    return errorMessage;
  }

  errorMessage = storageTermIncluded && validateStorageTerm(offerChanges.storageTerm);
  if (errorMessage) {
    return errorMessage;
  }

  errorMessage = monthlyFeeIncluded && validateMonthyFee(offerChanges.monthlyFee, offerChanges.monthlyFeeTerm);
  if (errorMessage) {
    return errorMessage;
  }

  errorMessage = payImmediately && validateSource(source);
  if (errorMessage) {
    return errorMessage;
  }

  const totalFees =
    (offerChanges.oneTimeFee || 0) + (offerChanges.monthlyFee || 0) * (offerChanges.monthlyFeeTerm || 0);

  if (totalFees > totalAmountDue) {
    return FEES_TOO_LARGE;
  }
};
