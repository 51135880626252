import axios from 'axios';
import * as React from 'react';

import { Modal } from '@shared/components/bootstrap';

import { Timestamp } from '@admin/components/timestamp';

import { IMetadata } from '@admin/types/metadata';
import { VideoPlayer } from './video_player';

interface IVideoProps {
  showModal: boolean;
  metadata: IMetadata;
  locationId: number;
  onClose(): void;
}

interface IVideoState {
  source?: string;
}

class VideoModal extends React.Component<IVideoProps, IVideoState> {
  constructor(props: IVideoProps) {
    super(props);
    this.state = {
      source: undefined,
    };
  }

  public render() {
    const { showModal, onClose, metadata } = this.props;
    const { source } = this.state;

    if (!showModal) {
      return null;
    }

    if (!source) {
      this.fetchVideoUrl();
      return null;
    }

    return (
      <Modal centered onClose={onClose} size={Modal.Size.Large}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Video</Modal.Title>
            <Modal.Close close={onClose} />
          </Modal.Header>
          <Modal.Body>
            <div className="video--player">
              <VideoPlayer url={source!} />
            </div>
            <div className="video--metadata">
              <p>
                <strong>Warehouse: </strong>
                {metadata.warehouse}
              </p>
              <p>
                <strong>Scanned At: </strong>
                <Timestamp value={metadata.scannedAt} format="medium" timezone={metadata.timezone} />
              </p>
              <p>
                <strong>Location: </strong>
                {metadata.location}
              </p>
              <p>
                <strong>User: </strong>
                {metadata.user}
              </p>
            </div>
          </Modal.Body>
        </Modal.Content>
      </Modal>
    );
  }

  private async fetchVideoUrl(): Promise<string | undefined> {
    const params = { startTime: this.props.metadata.scannedAt };
    try {
      const response = await axios.get(`/locations/${this.props.locationId}/video_source.json`, { params });
      this.setState({ source: response.data.source });
    } catch {
      return undefined;
    }
  }
}

export { VideoModal };
