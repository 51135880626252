import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';

import React from 'react';

import { customerTicketURL } from '@admin/config/routes';
import {
  CustomerTicketFragment,
  CustomerTicketSortInput,
  CustomerTicket__Priority,
  OrderingDirectionEnum,
} from '@admin/schema';
import { CustomerTicketState } from '@admin/types/customer_ticket';
import { CUSTOMER_TICKET_PRIORITIES } from '@admin/types/customer_ticket';

import { AnchorButton, Table as BootstrapTable } from '@shared/components/bootstrap';
import { Timestamp, TruncatedWithTooltip } from '@shared/components/helpers';

const formatPriorityName = (priority: CustomerTicket__Priority) => {
  const ticketPriority = CUSTOMER_TICKET_PRIORITIES.find((p) => p.value === priority);
  return ticketPriority!.name;
};

const Row: React.FC<{
  ticket: CustomerTicketFragment;
}> = ({ ticket }) => {
  const {
    state,
    customerTicketable,
    description,
    group,
    assignee,
    createdAt,
    actionRequiredAt,
    customerUpdatedAt,
    priority,
  } = ticket;
  const isOpen = state === CustomerTicketState.Open;

  return (
    <tr>
      <td className="col-md-1">{customerTicketable.shortDisplay}</td>
      <td className="col-md-3">
        <TruncatedWithTooltip text={description || ''} />
      </td>
      <td className="col-md-1">{group?.name ?? '-'}</td>
      <td className="col-md-1">{assignee?.name ?? 'Unassigned'}</td>
      <td className="col-md-2">
        <Timestamp value={createdAt} format={Timestamp.Format.DateTime} />
      </td>
      <td className="col-md-2">
        {customerUpdatedAt ? <Timestamp value={customerUpdatedAt} format={Timestamp.Format.DateTime} /> : '-'}
      </td>
      <td className="col-md-2">
        {isOpen && actionRequiredAt ? <Timestamp value={actionRequiredAt} format={Timestamp.Format.DateTime} /> : '-'}
      </td>
      <td className="col-md-1">{priority ? formatPriorityName(priority) : '-'}</td>
      <td>
        <AnchorButton kind="primary" href={customerTicketURL(ticket)} target="_blank">
          View
        </AnchorButton>
      </td>
    </tr>
  );
};

const SortIcon: React.FC<{
  column: string;
  sort?: CustomerTicketSortInput;
}> = ({ column, sort }) =>
  !sort || sort?.column !== column ? (
    <FontAwesomeIcon icon={faSort} />
  ) : (
    <FontAwesomeIcon icon={sort.direction === OrderingDirectionEnum.Ascending ? faSortUp : faSortDown} />
  );

export const Table: React.FC<{
  customerTickets: CustomerTicketFragment[];
  sort?: CustomerTicketSortInput;
  setSort(sort?: CustomerTicketSortInput): void;
}> = ({ customerTickets, sort, setSort }) => {
  const updateSort = (nextSortColumn: string) => {
    if (sort?.column !== nextSortColumn || sort?.direction === OrderingDirectionEnum.Ascending) {
      setSort({ column: nextSortColumn, direction: OrderingDirectionEnum.Descending });
    } else {
      setSort({ column: nextSortColumn, direction: OrderingDirectionEnum.Ascending });
    }
  };
  return (
    <BootstrapTable hover responsive className="mar-top" style={{ tableLayout: 'fixed' }}>
      <thead>
        <tr>
          <th className="col-md-1">Customer Name</th>
          <th className="col-md-2">Description</th>
          <th className="col-md-1">Group</th>
          <th className="col-md-1">Assignee</th>
          <th className="col-md-2" onClick={() => updateSort('created_at')}>
            Created <SortIcon column="created_at" sort={sort} />
          </th>
          <th className="col-md-2" onClick={() => updateSort('customer_updated_at')}>
            Customer Updated <SortIcon column="customer_updated_at" sort={sort} />
          </th>
          <th className="col-md-2" onClick={() => updateSort('action_required_at')}>
            Action Required <SortIcon column="action_required_at" sort={sort} />
          </th>
          <th className="col-md-1">Priority</th>
          <th className="col-md-1" />
        </tr>
      </thead>
      <tbody>
        {customerTickets.map((ticket: CustomerTicketFragment) => (
          <Row key={ticket.id} ticket={ticket} />
        ))}
      </tbody>
    </BootstrapTable>
  );
};
