import * as React from 'react';
import { useState } from 'react';

import { Timestamp } from '@admin/components/timestamp';

import { Lightbox } from '@shared/components/helpers/lightbox';

import { IPalletTask } from '@admin/types/pallet_task';

export const PalletTaskImages: React.FC<{ palletTasks: IPalletTask[]; timezone: string }> = ({
  palletTasks,
  timezone,
}) => {
  const [selection, setSelection] = useState<string | undefined>(undefined);
  return (
    <div>
      {palletTasks
        .filter((task) => task.image)
        .map((task) => (
          <div key={task.image.id} className="col-xs-6 col-md-4 col-lg-2">
            {task.completedAt && (
              <span>
                {task.user.name} completed at <Timestamp value={task.completedAt} format="medium" timezone={timezone} />
              </span>
            )}
            {task.canceledAt && (
              <span>
                {task.user.name} marked this location as full at{' '}
                <Timestamp value={task.canceledAt} format="medium" timezone={timezone} />
              </span>
            )}

            <figure className="brick-square">
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setSelection(task.image.source);
                }}
              >
                <img className="location-image" src={task.image.size} />
              </a>
            </figure>
          </div>
        ))}
      {selection && <Lightbox imageURL={selection} onClose={() => setSelection(undefined)} />}
    </div>
  );
};
