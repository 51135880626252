import * as React from 'react';

import { IGratuityShare } from '@admin/types/gratuity_share';
import { IPagination } from '@admin/types/pagination';

import { Currency, Timestamp } from '@shared/components/helpers';

import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { Panel, Table } from '@shared/components/bootstrap';

import { orderURL, userURL } from '@admin/config/routes';

interface IResultsProps {
  results?: IGratuityShare[];
  pagination?: IPagination;
  onPage(page: number): void;
}

export const Results = ({ results, pagination, onPage }: IResultsProps) => (
  <Panel>
    {results && (
      <Table striped bordered>
        <thead>
          <tr>
            <th>Employee ID</th>
            <th>Employee Name</th>
            <th>Order ID</th>
            <th>Amount</th>
            <th>Date of Tip</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result) => (
            <tr key={result.id}>
              <td>{result.user.id}</td>
              <td>
                <a className="btn-link" href={userURL(result.user)} target="_blank">
                  {result.user.name}
                </a>
              </td>
              <td>
                <a className="btn-link" href={orderURL({ id: result.gratuity.order_id })} target="_blank">
                  {result.gratuity.order_id}
                </a>
              </td>
              <td>
                <Currency value={Number(result.amount)} />
              </td>
              <td>
                <Timestamp value={result.created_at} format={Timestamp.Format.Date} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )}
    {!results && (
      <Panel.Body>
        <Spinner />
      </Panel.Body>
    )}
    {pagination && <Pagination pagination={pagination} onPage={onPage} />}
  </Panel>
);
