import React, { useState } from 'react';

import { Spinner } from '@admin/components/spinner';
import { PaymentPlan__Offer__Type, usePaymentPlanAccountQuery } from '@admin/schema';
import { OfferChanges } from '@admin/types/payment_plan/offer_changes';
import { Panel } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { Breadcrumbs } from './breadcrumbs';
import { Edit } from './edit';

export const New: React.FC<{ accountID: string; type?: PaymentPlan__Offer__Type }> = ({ accountID, type }) => {
  // Note: storing props in initial state is an anti-pattern. However, New
  // is a full-page component and the props will never change without a hard refresh/redirect
  const [offerChanges, setOfferChanges] = useState<OfferChanges>({ type });

  const { data, loading } = usePaymentPlanAccountQuery({ client, variables: { accountID } });

  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    return <p>Account could not be found.</p>;
  }

  const { account } = data;

  return (
    <>
      <Breadcrumbs account={account} newPaymentPlan />
      <Panel>
        <Panel.Body>
          <Edit accountID={accountID} offerChanges={offerChanges} setOfferChanges={setOfferChanges} lockType={!!type} />
        </Panel.Body>
      </Panel>
    </>
  );
};
