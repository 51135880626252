import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { useHiringJobListQuery } from '@admin/schema';
import { Table, AnchorButton } from '@shared/components/bootstrap';
import React from 'react';

export const HiringJobList: React.FC = () => {
  const { data, loading } = useHiringJobListQuery({ client });

  return (
    <Panel>
      <Table responsive striped>
        <thead>
          <tr>
            <th>Title</th>
            <th>Field</th>
            <th>Warehouse</th>

            <th className="text-right">
              <a className="btn btn-mint btn-labeled fa fa-plus" href="/hiring/roles/new">
                New
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.hiringJobs.map((job) => (
            <tr key={job.id}>
              <td>{job.title}</td>
              <td>{job.field ? 'Yes' : 'No'}</td>
              <td>{job.warehouse ? 'Yes' : 'No'}</td>

              <td className="text-right">
                <AnchorButton href={`/hiring/roles/${job.id}/edit`} kind="primary">
                  Edit
                </AnchorButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {loading && <Spinner />}
    </Panel>
  );
};
