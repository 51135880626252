import * as React from 'react';
import { ITimelineActivity } from '@admin/types/timeline_activity';
import { IResource } from './panel';
import { Entry } from './entry';

interface ITableProps {
  activities: ITimelineActivity[];
  timezone: string;
  resource: IResource;
}

const Table = ({ activities, timezone, resource }: ITableProps) => (
  <div className="table-responsive">
    <table className="table table-striped">
      <tbody>
        {activities.map((activity, key) => (
          <Entry activity={activity} key={key} timezone={timezone} resource={resource} />
        ))}
      </tbody>
    </table>
  </div>
);

export { Table };
