import * as React from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Table } from '@shared/components/bootstrap';
import { useVehicleTypesQuery } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { createVehicleTypeURL, editVehicleTypeURL } from '@admin/config/routes';
import { UserRole } from '@shared/types/user_role';
import { Roles } from '../helpers/roles';

export const VehicleTypes: React.FC = () => {
  const { data } = useVehicleTypesQuery({ client });

  if (!data || !data.vehicleTypes) {
    return null;
  }

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Vehicle Types</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Table striped responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Cuft</th>
              <th>Number of Seats</th>
              <th>Weight in Pounds</th>
              <th>Field Dispatchable</th>
              <th className="col-md-3">Restricted Zip Codes</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {data.vehicleTypes.map((type) => (
              <tr key={type.id}>
                <td>{type.name}</td>
                <td>{type.cuft}</td>
                <td>{type.numSeats}</td>
                <td>{type.weightInPounds}</td>
                <td>{type.fieldDispatchable ? 'True' : 'False'}</td>
                <td className="col-md-3">{type.restrictedZipCodes.join(', ')}</td>
                <td>
                  <a className="btn btn-primary" href={editVehicleTypeURL({ id: type.id })}>
                    Edit
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Panel.Body>
      <Roles show={[UserRole.Admin]}>
        <Panel.Footer align="right">
          <a href={createVehicleTypeURL()} className="btn btn-mint btn-labeled fa fa-plus">
            Add new
          </a>
        </Panel.Footer>
      </Roles>
    </Panel>
  );
};
