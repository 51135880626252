import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { useHiringQuestionsQuery } from '@admin/schema';
import { Table, AnchorButton } from '@shared/components/bootstrap';
import React from 'react';

export const HiringQuestionList: React.FC = () => {
  const { data, loading } = useHiringQuestionsQuery({ client });

  return (
    <Panel>
      <Table responsive striped>
        <thead>
          <tr>
            <th>Question</th>
            <th>Answers</th>
            <th>Failing Answers</th>
            <th>Roles</th>
            <th className="text-right">
              <a className="btn btn-mint btn-labeled fa fa-plus" href="/hiring/pre_screen_questions/new">
                New
              </a>
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.hiringQuestions.map((question) => (
            <tr key={question.id}>
              <td>{question.text}</td>
              <td>{question.options.join(', ')}</td>
              <td>{question.failingOptions.join(', ')}</td>
              <td>{question.jobs.map((job) => job.title).join(', ')}</td>
              <td className="text-right">
                <AnchorButton href={`/hiring/pre_screen_questions/${question.id}/edit`} kind="primary">
                  Edit
                </AnchorButton>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {loading && <Spinner />}
    </Panel>
  );
};
