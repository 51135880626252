import { IEstimatedItem } from '@shared/types/estimated_item';

interface IEstimatedItemAttribute {
  id?: string;
  category_id?: string;
  custom_category_name?: string;
  quantity: number;
}

export const buildEstimatedItemsAttributes = (data?: IEstimatedItem[]): IEstimatedItemAttribute[] => {
  if (!data) {
    return [];
  }

  return data.map(({ id, quantity, categoryId, customCategoryName }) => ({
    id,
    quantity,
    category_id: categoryId,
    custom_category_name: customCategoryName,
  }));
};
