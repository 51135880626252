import * as React from 'react';

import { useState } from 'react';

import { ITimelineActivity } from '@admin/types/timeline_activity';
import { IUser } from '@admin/types/user';

import { Timestamp } from '@admin/components/timestamp';

import { itemURL, locationURL, palletURL, userURL } from '@admin/config/routes';

import { IPalletize } from '@admin/types/palletize';
import { IPalletTask } from '@admin/types/pallet_task';
import { IScan } from '@admin/types/scan';
import { ITracking } from '@admin/types/tracking';
import { IPalletRequest } from '@admin/types/pallet_request';

import { IPalletInventoryTracking } from '@admin/types/logistics/pallet_inventory_tracking';

import { VideoModal } from './video_modal';

import { IResource } from './panel';

const EntryForScan = ({ scan }: { scan: IScan }) => (
  <>
    <td className="col-sm-3 text-left">
      <a href={userURL({ id: scan.user.id })} className="text-primary">
        <strong>{scan.user.name}</strong>
      </a>
      <span> scanned</span>
    </td>
    <td className="col-sm-8 text-left">
      <span>
        {(() => {
          switch (scan.source) {
            case 'camera':
              return 'via camera';
            case 'manual':
              return 'manual scan';
          }
        })()}
      </span>
    </td>
  </>
);

const EntryForTracking = ({ tracking, timezone }: { tracking: ITracking; timezone: string }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const { location, user, kind } = tracking;
  return (
    <>
      <td className="col-sm-3 text-left">
        <a href={userURL({ id: user.id })} className="text-primary">
          <strong>{user.name}</strong>
        </a>
        <span> {kind}</span>
      </td>
      <td className="col-sm-7 text-left">
        <a href={locationURL(location.id)} className="text-primary">
          <span>
            {(() => {
              switch (location.type) {
                case 'pnd':
                case 'dock':
                case 'shelf':
                case 'overflow':
                  return `#${location.value}`;
                default:
                  return location.description;
              }
            })()}
          </span>{' '}
          <span>({location.located.object.name})</span>
        </a>
        {!!location.camera_id && !!tracking.enabled_video && (
          <span>
            <i
              className="fa fa-play-circle"
              onClick={() => setShowModal(true)}
              style={{ fontSize: '20px', color: '#3BB5AE', marginLeft: '5px', cursor: 'pointer', position: 'absolute' }}
            />
            <VideoModal
              showModal={showModal}
              locationId={location.id}
              onClose={() => setShowModal(false)}
              metadata={{
                warehouse: location.located.object.name,
                scannedAt: tracking.created_at,
                timezone,
                location: location.value,
                user: user.name,
              }}
            />
          </span>
        )}
        {!!location.camera_id && !tracking.enabled_video && (
          <span>
            <i
              className="fa fa-play-circle"
              style={{ fontSize: '20px', color: '#a7a9ac', marginLeft: '5px', position: 'absolute' }}
            />
          </span>
        )}
      </td>
    </>
  );
};

const EntryForPalletize = ({ palletize }: { palletize: IPalletize }) => (
  <>
    <td className="col-sm-3 text-left">
      <a href={userURL({ id: palletize.user.id })} className="text-primary">
        <strong>{palletize.user.name}</strong>{' '}
      </a>
      <span>{palletize.kind}</span> {palletize.kind !== 'rescanned' && <span>via {palletize.source}</span>}
    </td>
    <td className="col-sm-8 text-left">
      <a href={itemURL(palletize.item)} className="text-primary">
        #{palletize.item.barcode.value}
      </a>{' '}
      <span>
        {(() => {
          switch (palletize.kind) {
            case 'added':
            case 'rescanned':
              return 'to';
            case 'removed':
              return 'from';
          }
        })()}
      </span>{' '}
      <a href={palletURL(palletize.pallet.id)} className="text-primary">
        <span>#{palletize.pallet.barcode.value}</span>
      </a>
    </td>
  </>
);

const EntryForPalletTask = ({ task, timezone }: { task: IPalletTask; timezone: string }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  return (
    <>
      <td className="col-sm-3 text-left">
        <a href={userURL({ id: task.user.id })} className="text-primary">
          <strong>{task.user.name}</strong>
        </a>
        <span>
          {(() => {
            if (task.canceledAt) {
              return ` task was cancelled`;
            } else {
              return ` claimed task (${task.origin_type} \u2192 ${task.destination_type})`;
            }
          })()}
        </span>
      </td>
      <td className="col-sm-8 text-left">
        {task.location && !task.canceledAt && (
          <span>
            {' '}
            Directed to
            <a href={locationURL(task.location.id)} className="text-primary">
              <span>{` #${task.location.value} `}</span>
              <span>({task.location.located.object.name})</span>
            </a>
          </span>
        )}

        {!task.location && !task.canceledAt && <span>Undirected</span>}

        {task.origin_location && !task.canceledAt && (
          <span>
            {' '}
            from
            <a href={locationURL(task.origin_location.id)} className="text-primary">
              <span>{` #${task.origin_location.value} `}</span>
              <span>({task.origin_location.located.object.name})</span>
            </a>
          </span>
        )}
        {!!task.origin_location.camera_id && !!task.enabled_video && !task.canceledAt && (
          <span>
            <i
              className="fa fa-play-circle"
              onClick={() => setShowModal(true)}
              style={{ fontSize: '20px', color: '#3BB5AE', marginLeft: '5px', cursor: 'pointer', position: 'absolute' }}
            />
            <VideoModal
              showModal={showModal}
              locationId={task.origin_location.id}
              onClose={() => setShowModal(false)}
              metadata={{
                warehouse: task.origin_location.located.object.name,
                scannedAt: task.created_at,
                timezone,
                location: task.origin_location.value,
                user: task.user.name,
              }}
            />
          </span>
        )}
        {!!task.origin_location.camera_id && !task.enabled_video && !task.canceledAt && (
          <span>
            <i
              className="fa fa-play-circle"
              style={{ fontSize: '20px', color: '#a7a9ac', marginLeft: '5px', position: 'absolute' }}
            />
          </span>
        )}
      </td>
    </>
  );
};

const EntryForPalletInventoryTracking = ({
  tracking,
  resource,
}: {
  tracking: IPalletInventoryTracking;
  resource: IResource;
}) => {
  let kind: string;
  if (tracking.origin_pallet_inventory == null) {
    if (tracking.changed_quantity < 0) {
      kind = 'deleted';
    } else {
      kind = tracking.sku_selection_kind ? 'created via ' + tracking.sku_selection_kind : 'created';
    }
  } else if (parseInt(tracking.origin_pallet_inventory.pallet_id) === parseInt(resource.id.toString())) {
    kind = 'removed';
  } else {
    kind = 'added';
  }

  return (
    <>
      <td className="col-sm-3 text-left">
        <a href={userURL({ id: tracking.user.id })} className="text-primary">
          <strong>{tracking.user.name}</strong>{' '}
        </a>
        <span> {kind}</span>
      </td>
      <td className="col-sm-8 text-left">
        <span>
          {' '}
          {Math.abs(tracking.changed_quantity)} {Math.abs(tracking.changed_quantity) === 1 ? 'unit' : 'units'} of SKU{' '}
          {tracking.destination_pallet_inventory.sku_value}
        </span>
        {tracking.origin_pallet_inventory && (
          <>
            <span> from </span>
            <a href={palletURL(tracking.origin_pallet_inventory.pallet_id)} className="text-primary">
              #{tracking.origin_pallet_inventory.pallet_barcode}
            </a>{' '}
          </>
        )}
        <span> {tracking.changed_quantity < 0 ? 'from' : 'to'} </span>
        <a href={palletURL(tracking.destination_pallet_inventory.pallet_id)} className="text-primary">
          <span>#{tracking.destination_pallet_inventory.pallet_barcode}</span>
        </a>
      </td>
    </>
  );
};

const EntryForPalletRequest = ({ request }: { request: IPalletRequest }) => {
  let user: IUser;
  let message: string;
  if (request.canceler) {
    user = request.canceler;
    message = 'canceled dock request';
  } else {
    user = request.requestor;
    message = 'requested to dock';
  }

  return (
    <>
      <td className="col-sm-3 text-left">
        <a href={userURL({ id: user.id })} className="text-primary">
          <strong>{user.name}</strong>{' '}
        </a>
        <span>{message}</span>
      </td>
      <td className="col-sm-8"></td>
    </>
  );
};

interface IEntryProps {
  activity: ITimelineActivity;
  timezone: string;
  resource: IResource;
}

const Entry = ({ activity, timezone, resource }: IEntryProps) => (
  <tr>
    <td className="col-sm-2 text-left">
      <Timestamp value={activity.timestamp} format="medium" timezone={timezone} />
    </td>

    {(() => {
      switch (activity.kind) {
        case 'palletize':
          return <EntryForPalletize palletize={activity.resource} />;
        case 'scan':
          return <EntryForScan scan={activity.resource} />;
        case 'tracking':
          return <EntryForTracking tracking={activity.resource} timezone={timezone} />;
        case 'palletTask':
          return <EntryForPalletTask task={activity.resource} timezone={timezone} />;
        case 'logisticsPalletInventoryTracking':
          return <EntryForPalletInventoryTracking tracking={activity.resource} resource={resource} />;
        case 'palletRequest':
          return <EntryForPalletRequest request={activity.resource} />;
      }
    })()}
  </tr>
);

export { Entry };
