import { client } from '@admin/libraries/apollo';

import { PollForInvoiceEventDocument } from '@admin/schema';

interface IQueryData {
  billing: {
    invoiceEvent: {
      id: string;
      completed: boolean;
    };
  };
}

interface IQueryVariables {
  accountID: string;
  attemptID: string;
}

const sleep = async (interval: number) => new Promise((resolve) => setTimeout(resolve, interval));

export const pollForInvoiceEvent = async (accountID: string, attemptID: string, interval = 3000) => {
  // eslint-disable-next-line no-constant-condition
  while (true) {
    // eslint-disable-next-line no-await-in-loop
    const { data } = await client.query<IQueryData, IQueryVariables>({
      fetchPolicy: 'no-cache',
      query: PollForInvoiceEventDocument,
      variables: {
        accountID,
        attemptID,
      },
    });
    if (data.billing.invoiceEvent.completed) {
      return;
    }

    // eslint-disable-next-line no-await-in-loop
    await sleep(interval);
  }
};
