import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { logisticsDetailedSKUURL } from '@admin/config/routes';
import { Roles } from '@admin/components/helpers/roles';
import { Spinner } from '@admin/components/spinner';
import { UserRole } from '@shared/types/user_role';

import { PalletHeightUnitEnum, useLogisticsSkuForEditQuery } from '@admin/schema';

import { EditFormData, LogisticsSkuForm } from './form/form';

export const LogisticsEditSku: React.FC<{ id: string }> = ({ id }) => {
  const { data, loading } = useLogisticsSkuForEditQuery({ client, variables: { id: id } });

  if (loading || !data) {
    return <Spinner />;
  }

  const sku = data.logisticsSku;
  const defaultPalletHeight =
    sku.defaultPalletHeight?.unit === PalletHeightUnitEnum.Inches ? sku.defaultPalletHeight.value : 0;
  const formData: EditFormData = {
    id: sku.id,
    value: sku.value,
    description: sku.description || '',
    hasBarcode: sku.hasBarcode,
    defaultPalletTypeID: sku.defaultPalletTypeId || '0',
    defaultPalletHeightValue: defaultPalletHeight,
    itemsPerPallet: sku.itemsPerPallet || undefined,
    weightPerPallet: sku.weightPerPallet || undefined,
    gtinBarcode: sku.gtinBarcode || undefined,
    nmfcClass: sku.nmfcClass || undefined,
    nmfcNumber: sku.nmfcNumber || undefined,
    itemWeight: sku.itemWeight || undefined,
    itemLength: sku.itemLength || undefined,
    itemWidth: sku.itemWidth || undefined,
    itemHeight: sku.itemHeight || undefined,
    teachInStatus: sku.teachInStatus,
    disableVBW: !sku.vbwEligible,
    vbwOptOutReason: sku.vbwOptOutReason || undefined,
    countingUnit: sku.countingUnit,
  };

  const onSubmit = async () => {
    window.location.href = logisticsDetailedSKUURL({ id: sku.id });
  };

  return (
    <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
      <LogisticsSkuForm formData={formData} onSave={onSubmit} />
    </Roles>
  );
};
