import { AddressForm } from '@admin/components/address/address_form';
import { Panel } from '@admin/components/helpers/panel';
import { Spinner } from '@admin/components/spinner';
import {
  DuplicateLoadNumbersQuery,
  DuplicateLoadNumbersDocument,
  LoadTypeEnum,
  Logistics__AddressInput,
  Logistics__LoadInputType,
  Status,
  useBuildLogisticsLoadMutation,
  useModifyLogisticsLoadMutation,
  useOptionsQuery,
  InsufficientSkuInventoryQuery,
  InsufficientSkuTruckCuftDocument,
  InsufficientSkuInventoryDocument,
  Logistics__InsufficientSkuInventoryInput,
  Logistics__InsufficientSkuTruckCuftInput,
  Logistics__FulfillmentExpectationInputType,
  InsufficientSkuTruckCuftQuery,
} from '@admin/schema';
import { Box } from '@clutter/clean';
import styled from '@emotion/styled';
import { Alert, Button, Text } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';
import { ConfirmationModal } from '@shared/components/modals/confirmation_modal';
import { client } from '@admin/libraries/apollo';
import { IAddress } from '@shared/types/address';
import { UUID } from '@shared/utils/uuid';
import React, { useEffect, useState } from 'react';
import { FormContext, useForm, useFieldArray } from 'react-hook-form';
import { Field, StyledRow } from './field';
import { BasicInfoFields } from './basic_info_fields';
import { CarrierInfoFields } from './carrier_info_fields';
import { LoadSettingsFields } from './load_settings_fields';
import { ReferenceFields } from './reference_fields';
import { FulfillmentExpectationFields } from './fulfillment_expectation_fields';
import { SkuInventoryConfirmationModal } from './sku_inventory_confirmation_modal';
import { SkuTruckCuftConfirmationModal } from './sku_truck_cuft_confirmation_modal';

type LoadInput = Logistics__LoadInputType & {
  fulfillmentExpectations?: Array<{
    accountID: string;
    ingestedCount: number;
  }>;
};

type FulfillmentExpectationFormInput = Logistics__FulfillmentExpectationInputType & { accountID?: string };

const Container = styled.div`
  width: 90%;
  margin: auto;
`;

const HR = styled.hr`
  margin: 20px 0px;
`;

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
`;

const StateTag = styled(Box.Flex)`
  color: '#FFFFFF';
  height: 24px;
  font-size: 12px;
  font-weight: 400;
  padding: 7px 10px;
  margin: 0 5px;
`;

const MarginButton = styled(Button)`
  margin: 0 5px;
`;

const DEFAULT_ADDRESS = {
  street: '',
  aptsuite: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  businessName: '',
};

const DEFAULT_CARRIER_DETAILS = {
  transportationArrangedBy: undefined,
  businessName: '',
  contactName: '',
  contactEmail: '',
  contactPhone: '',
  scac: '',
};

const DEFAULT_LOAD_INPUT = {
  shipToAddress: DEFAULT_ADDRESS,
  billToAddress: DEFAULT_ADDRESS,
  carrierDetails: DEFAULT_CARRIER_DETAILS,
  type: LoadTypeEnum.Inbound,
  usePalletLabels: false,
  itemLabelsRequired: false,
  trailerSealRequired: false,
  drop: false,
  fulfillmentExpectations: [
    {
      skuNumber: '',
      quantity: undefined,
    },
  ],
};

const VALIDATE_TRUCK_CUFT_MODES = ['TL', 'FLEET'];

const DEFAULT_STATE_COLOR = '#DAA520';
const LOAD_STATE_COLORS: { [key: string]: string } = {
  pending: '#46B9D8',
  prepared: '#337AB7',
  completed: '#008000',
  canceled: '#F6593A',
};

function IAddressFromAddressInput(input: Logistics__AddressInput | null | undefined): IAddress | null | undefined {
  return (
    input && {
      street: input.street,
      aptsuite: input.aptsuite ?? undefined,
      city: input.city,
      state: input.state,
      country: input.country ?? undefined,
      zip: input.zip,
      latitude: input.latitude ?? undefined,
      longitude: input.longitude ?? undefined,
      businessName: input.businessName ?? undefined,
    }
  );
}

const getLoadStateTag = (state: string | undefined) => {
  const tagColor = (state && LOAD_STATE_COLORS[state]) || DEFAULT_STATE_COLOR;
  return (
    <StateTag alignItems="center" color="#FFFFFF" background={tagColor}>
      {state || 'draft'}
    </StateTag>
  );
};

export const LogisticsLoadForm: React.FC<{
  load: LoadInput | undefined;
  editMode: boolean;
  state?: string;
  onSave(id: string | null | undefined): void;
}> = ({ load, editMode, state, onSave }) => {
  const [duplicateModalVisible, setDuplicateModalVisible] = useState<boolean>(false);
  const [error, setError] = useState<string | null | undefined>(undefined);
  const [insufficientInventoryData, setInsufficientInventoryData] = useState<InsufficientSkuInventoryQuery | undefined>(
    undefined,
  );
  const [insufficientTruckCuftData, setInsufficientTruckCuftData] = useState<InsufficientSkuTruckCuftQuery | undefined>(
    undefined,
  );
  const [formData, setFormData] = useState<Logistics__LoadInputType | undefined>(undefined);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [draft, setDraft] = useState<boolean>((load && load.draft) || !load);

  const { data: optionsData, loading: optionsLoading } = useOptionsQuery({ client });
  const [executeNewSubmit, { loading: newLoadSaving }] = useBuildLogisticsLoadMutation({ client });
  const [executeEditSubmit, { loading: editLoadSaving }] = useModifyLogisticsLoadMutation({ client });
  const [validationUuid, _] = useState(UUID);

  const form = useForm<LoadInput>({
    defaultValues: load || DEFAULT_LOAD_INPUT,
  });

  const {
    fields: fulfillmentFields,
    append: appendFulfillment,
    remove: removeFulfillment,
  } = useFieldArray({
    control: form.control,
    name: 'fulfillmentExpectations',
  });

  const watchLoadType = form.watch('type');
  const watchWarehouseID = form.watch('warehouseID');
  const watchFreightChargeTerms = form.watch('freightChargeTerms');

  const executeInsufficientSkuInventoryQuery = async (input: Logistics__LoadInputType) =>
    client.query<InsufficientSkuInventoryQuery, { input: Logistics__InsufficientSkuInventoryInput }>({
      query: InsufficientSkuInventoryDocument,
      variables: {
        input: {
          uuid: validationUuid,
          loadId: input.id,
          warehouseId: input.warehouseID,
          fulfillmentExpectationInputs: input.fulfillmentExpectations || [],
        },
      },
    });

  const executeInsufficientSkuTruckCuftQuery = async (input: Logistics__LoadInputType) =>
    client.query<InsufficientSkuTruckCuftQuery, { input: Logistics__InsufficientSkuTruckCuftInput }>({
      query: InsufficientSkuTruckCuftDocument,
      variables: {
        input: {
          uuid: validationUuid,
          loadId: input.id,
          fulfillmentExpectationInputs: input.fulfillmentExpectations || [],
        },
      },
    });

  const executeDuplicateLoadNumbersQuery = async (loadNumber: string) =>
    client.query<DuplicateLoadNumbersQuery, { loadNumber: string }>({
      query: DuplicateLoadNumbersDocument,
      variables: { loadNumber },
    });

  useEffect(() => {
    form.register({ name: 'shipToAddress' });
    return () => {
      form.unregister('shipToAddress');
    };
  }, [form.register]);

  useEffect(() => {
    form.register({ name: 'billToAddress' });
    return () => {
      form.unregister('billToAddress');
    };
  }, [form.register]);

  const handleTappedConfirmOnInsufficientInventoryModal = () => {
    setInsufficientInventoryData(undefined);
    onSubmit(formData!, true);
  };

  const handleTappedConfirmOnInsufficientTruckCuftModal = () => {
    setInsufficientTruckCuftData(undefined);
    onSubmit(formData!, false);
  };

  const checkForErrors = (data: Logistics__LoadInputType) => {
    if (!draft && data.fulfillmentExpectations?.some((fe) => !(fe.quantity && fe.skuID) || fe.skuID === '0')) {
      form.setError('fulfillmentExpectations', 'required');
      return 'Please fill out all fulfillment expectation fields';
    } else if (!draft && (!data.fulfillmentExpectations || data.fulfillmentExpectations.length === 0)) {
      form.setError('fulfillmentExpectations', 'required');
      return 'A published load must have at least one fulfillment expectation';
    } else if (
      draft &&
      data.fulfillmentExpectations?.some(
        (fe: FulfillmentExpectationFormInput) => fe.accountID && !(fe.quantity && fe.skuID),
      )
    ) {
      return 'Please ensure any applicable fulfillment expectation are filled out correctly';
    } else {
      form.clearError('fulfillmentExpectations');
    }

    const invalidAppointmentDate = !data.appointmentDate || data.appointmentDate === 'Invalid DateTime';

    if (draft && !data.requestedAppointmentDate && invalidAppointmentDate) {
      form.setError('requestedAppointmentDate', 'required');
      return 'A draft load must have a requested appointment date';
    } else {
      form.clearError('requestedAppointmentDate');
    }

    if (draft && !data.requestedAppointmentWindow && invalidAppointmentDate) {
      form.setError('requestedAppointmentWindow', 'required');
      return 'A draft load must have a requested appointment window';
    } else {
      form.clearError('requestedAppointmentWindow');
    }

    if (!draft && invalidAppointmentDate) {
      form.setError('appointmentDate', 'required');
      return 'A published load must have an appointment date and time';
    } else {
      form.clearError('appointmentDate');
    }

    if (watchLoadType === LoadTypeEnum.Outbound) {
      if (
        data.usePalletLabels &&
        data.fulfillmentExpectations?.some((fe) => fe.purchaseOrderID === '' || fe.purchaseOrderID == null)
      ) {
        form.setError('fulfillmentExpectations', 'required');
        return 'Fulfillment expectations with shipping labels require a purchase order';
      } else {
        form.clearError('fulfillmentExpectations');
      }
      if (
        !(
          data.shipToAddress &&
          data.shipToAddress.street &&
          data.shipToAddress.country &&
          data.shipToAddress.city &&
          data.shipToAddress.state &&
          data.shipToAddress.zip
        )
      ) {
        form.setError('shipToAddress', 'required');
        return 'Please fill out ship to street, city, state, country, and zip fields completely';
      } else {
        form.clearError('shipToAddress');
      }

      if (
        data.freightChargeTerms === 'third_party' &&
        !(
          data.billToAddress &&
          data.billToAddress.street &&
          data.billToAddress.country &&
          data.billToAddress.city &&
          data.billToAddress.state &&
          data.billToAddress.zip
        )
      ) {
        form.setError('billToAddress', 'required');
        return 'Please fill out bill to street, city, state, country, and zip fields completely';
      } else {
        form.clearError('billToAddress');
      }
    }

    if (data.warehouseID === '0') {
      form.setError('warehouseID', 'required');
      return 'Please select a warehouse';
    }

    if (data.targetPalletHeight && !data.palletHeightPreference) {
      form.setError('palletHeightPreference', 'required');
      return 'Please select a pallet height preference';
    }
    return null;
  };

  // FieldArray was not setting number fields correctly as numbers
  const transform = (data: Logistics__LoadInputType) => {
    data.draft = draft;

    if (draft && (!data.fulfillmentExpectations || data.fulfillmentExpectations.every(({ skuID }) => !skuID))) {
      data.fulfillmentExpectations = [];
    }

    if (data.fulfillmentExpectations) {
      // Registered accountID on expectations to save to use as initial account for new expectations, however it is not an input field on the type
      data.fulfillmentExpectations.forEach((fe: FulfillmentExpectationFormInput) => {
        fe.quantity = parseInt(String(fe.quantity), 10);
        delete fe.accountID;
      });
    }

    if (data.usePalletLabels == null) {
      data.usePalletLabels = false;
    }

    if (data.itemLabelsRequired == null) {
      data.itemLabelsRequired = false;
    }

    if (data.trailerSealRequired == null) {
      data.trailerSealRequired = false;
    }

    if (!data.requestedAppointmentDate) {
      delete data.requestedAppointmentDate;
    }

    if (!data.requestedAppointmentWindow) {
      delete data.requestedAppointmentWindow;
    }

    if (!data.palletHeightPreference) {
      data.palletHeightPreference = null;
    }

    if (!data.appointmentDate || data.appointmentDate === 'Invalid DateTime') {
      delete data.appointmentDate;
    }

    if (!data.carrierDetails?.transportationArrangedBy) {
      delete data.carrierDetails;
    }

    if (!data.billToAddress?.street || data.freightChargeTerms !== 'third_party') {
      delete data.billToAddress;
    }

    data.validationUuid = validationUuid;
    data.targetPalletHeight = parseFloat(String(data.targetPalletHeight));

    return data;
  };

  const onSubmit = async (data: Logistics__LoadInputType, validateTruckCuft: boolean) => {
    setSubmitting(true);
    if (validateTruckCuft && VALIDATE_TRUCK_CUFT_MODES.includes(data.mode)) {
      await checkTruckCuft(data);
    } else if (!editMode) {
      try {
        const result = await executeNewSubmit({
          variables: {
            input: data,
          },
        });

        if (result.data?.buildLogisticsLoad.status === Status.Unprocessable) {
          setError(result.data.buildLogisticsLoad.error);
          window.scrollTo(0, 0);
          setSubmitting(false);
        } else {
          setError(undefined);
          onSave(result.data?.buildLogisticsLoad.id);
        }
      } catch (e) {
        setError('Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.');
        window.scrollTo(0, 0);
        setSubmitting(false);
      }
    } else {
      try {
        const result = await executeEditSubmit({
          variables: {
            input: data,
          },
        });

        if (result.data?.modifyLogisticsLoad.status === Status.Unprocessable) {
          setError(result.data.modifyLogisticsLoad.error);
          window.scrollTo(0, 0);
          setSubmitting(false);
        } else {
          setError(undefined);
          onSave(result.data?.modifyLogisticsLoad.id);
        }
      } catch (e) {
        setError('Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.');
        window.scrollTo(0, 0);
        setSubmitting(false);
      }
    }
  };

  const checkTruckCuft = async (data: Logistics__LoadInputType) => {
    const truckCuftResult = await executeInsufficientSkuTruckCuftQuery(data);
    const insufficientSkuTruckCuft = truckCuftResult.data.insufficientSkuTruckCuft;
    if (
      insufficientSkuTruckCuft.validForKnownDimensions &&
      insufficientSkuTruckCuft.skusWithoutDimensions.length === 0
    ) {
      // valid and all sku dimensions known
      form.clearError('fulfillmentExpectations');
      onSubmit(data, false);
    } else {
      setFormData(data);
      setInsufficientTruckCuftData(truckCuftResult.data);
    }
  };

  const checkForDuplicateLoadNumber = async (data: Logistics__LoadInputType) => {
    const results = await executeDuplicateLoadNumbersQuery(data.number!);
    const duplicateLoads = results.data.duplicateLoadNumbers;
    if (duplicateLoads.length > 0) {
      setDraft(!state || state === 'draft');
      setFormData(data);
      setDuplicateModalVisible(true);
      return true;
    }
    return false;
  };

  const onSubmitWithChecks = async (data: Logistics__LoadInputType) => {
    const errorsFromValidation = checkForErrors(data);
    data = transform(data);

    if (errorsFromValidation === null) {
      setError(undefined);

      if (data.number && !duplicateModalVisible && !editMode) {
        const hasDuplicate = await checkForDuplicateLoadNumber(data);
        if (hasDuplicate) return;
      }

      if (data.type === LoadTypeEnum.Outbound && data.fulfillmentExpectations?.length !== 0) {
        try {
          const result = await executeInsufficientSkuInventoryQuery(data);

          if (result.data.insufficientSkuInventory.length) {
            form.setError('fulfillmentExpectations', 'required');
            setFormData(data);
            setInsufficientInventoryData(result.data);
          } else if (VALIDATE_TRUCK_CUFT_MODES.includes(data.mode)) {
            await checkTruckCuft(data);
          } else {
            form.clearError('fulfillmentExpectations');
            onSubmit(data, false);
          }
        } catch (e) {
          setError('Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.');
          window.scrollTo(0, 0);
        }
      } else {
        onSubmit(data, false);
      }
    } else {
      if (!state || state === 'draft') setDraft(true);
      setError(errorsFromValidation || 'Please fill out all required fields');
      window.scrollTo(0, 0);
    }
  };

  if (optionsLoading || !optionsData) {
    return <Spinner />;
  }

  return (
    <>
      <Panel>
        <FormContext {...form}>
          {error && <Alert style="danger">{error}</Alert>}
          <form id="loadForm" onSubmit={form.handleSubmit(onSubmitWithChecks)}>
            <Panel.Title>
              {!editMode && <Text tag="h2">New Load</Text>}
              {editMode && load && <Text tag="h2">Edit Load {load.number}</Text>}
              <Box.Flex flexDirection="row" alignItems="center">
                Current State: {getLoadStateTag(state)}
              </Box.Flex>
            </Panel.Title>
            <Panel.Body>
              <HR />
              <Container>
                <SectionTitle>Basic Info</SectionTitle>
                <Spacer height="8px" />
                <BasicInfoFields editMode={editMode} warehouses={optionsData.warehouses} state={state} />
              </Container>
              <HR />
              <Container>
                <SectionTitle>Carrier Info</SectionTitle>
                <Spacer height="8px" />
                <CarrierInfoFields />
              </Container>
              {watchLoadType === LoadTypeEnum.Outbound && watchFreightChargeTerms === 'third_party' && (
                <>
                  <HR />
                  <Container>
                    <SectionTitle>Third Party Freight Charges Bill To</SectionTitle>
                    <Spacer height="8px" />
                    <Field id="billing_business_name" label="Business Name" required={false}>
                      <input
                        id="billing_business_name"
                        type="text"
                        className="form-control"
                        name="billToAddress.businessName"
                        ref={form.register}
                      />
                    </Field>
                    <AddressForm
                      address={IAddressFromAddressInput(form.watch('billToAddress')) || DEFAULT_ADDRESS}
                      onChange={(address) => {
                        form.setValue('billToAddress', address);
                      }}
                    />
                  </Container>
                </>
              )}
              <HR />
              <Container>
                <SectionTitle>Reference Info</SectionTitle>
                <Spacer height="8px" />
                <ReferenceFields />
              </Container>
              {watchLoadType === LoadTypeEnum.Outbound && (
                <>
                  <HR />
                  <Container>
                    <SectionTitle>Ship To Address</SectionTitle>
                    <Spacer height="8px" />
                    <Field id="shipping_business_name" label="Business Name" required={false}>
                      <input
                        id="shipping_business_name"
                        type="text"
                        className="form-control"
                        name="shipToAddress.businessName"
                        ref={form.register}
                      />
                    </Field>
                    <AddressForm
                      address={IAddressFromAddressInput(form.watch('shipToAddress')) || DEFAULT_ADDRESS}
                      onChange={(address) => {
                        form.setValue('shipToAddress', address);
                      }}
                    />
                  </Container>
                  <HR />
                  <Container>
                    <SectionTitle>Outbound Load Settings</SectionTitle>
                    <Spacer height="8px" />
                    <LoadSettingsFields editMode={editMode} initialUsePalletLabels={load?.usePalletLabels || false} />
                  </Container>
                </>
              )}
              <HR />
              <Container>
                <SectionTitle>Fulfillment Expectations</SectionTitle>
                {editMode && !draft && (
                  <Text style="danger">
                    Some fulfillment expectations may not be editable if items have already been assigned to them
                  </Text>
                )}
                <Spacer height="16px" />
                {fulfillmentFields.map((item, index) => (
                  <FulfillmentExpectationFields
                    key={item.id}
                    editMode={editMode}
                    accounts={optionsData.logisticsAccounts}
                    purchaseOrders={optionsData.logisticsPurchaseOrders}
                    item={item}
                    index={index}
                    removeFulfillmentExpectation={removeFulfillment}
                    initialAccountID={
                      (load?.fulfillmentExpectations &&
                        load.fulfillmentExpectations.length > index &&
                        load.fulfillmentExpectations[index].accountID) ||
                      undefined
                    }
                    initialPurchaseOrderID={
                      (load?.fulfillmentExpectations &&
                        load.fulfillmentExpectations.length > index &&
                        load.fulfillmentExpectations[index].purchaseOrderID) ||
                      undefined
                    }
                    initialSkuID={
                      (load?.fulfillmentExpectations &&
                        load.fulfillmentExpectations.length > index &&
                        load.fulfillmentExpectations[index].skuID) ||
                      undefined
                    }
                    usePalletLabels={load?.usePalletLabels || false}
                    hasItems={
                      (load?.fulfillmentExpectations &&
                        load.fulfillmentExpectations.length > index &&
                        load.fulfillmentExpectations[index].ingestedCount > 0) ||
                      false
                    }
                  />
                ))}
                <StyledRow className="row pull-right">
                  <Button
                    kind="primary"
                    type="button"
                    onClick={() => appendFulfillment({ skuID: '', quantity: undefined })}
                    disabled={editMode && load?.usePalletLabels}
                  >
                    Add
                  </Button>
                </StyledRow>
              </Container>
              <Spacer height="20px" />
            </Panel.Body>
            <Panel.Footer align="right">
              {draft && (
                <MarginButton
                  kind="warning"
                  type="submit"
                  loading={draft && (newLoadSaving || editLoadSaving || submitting)}
                >
                  Save as Draft
                </MarginButton>
              )}
              <MarginButton
                kind="primary"
                type="submit"
                loading={!draft && (newLoadSaving || editLoadSaving || submitting)}
                onClick={() => setDraft(false)}
              >
                {draft ? 'Publish' : 'Save'}
              </MarginButton>
            </Panel.Footer>
          </form>
        </FormContext>
      </Panel>
      {insufficientInventoryData && (
        <SkuInventoryConfirmationModal
          warehouseName={
            optionsData.warehouses.find((warehouse) => warehouse.id === watchWarehouseID)?.name || 'Unknown Warehouse'
          }
          insufficientInventoryObjects={insufficientInventoryData?.insufficientSkuInventory || []}
          onCancel={() => {
            setInsufficientInventoryData(undefined);
            setDraft(!state || state === 'draft');
          }}
          onConfirm={handleTappedConfirmOnInsufficientInventoryModal}
        />
      )}
      {insufficientTruckCuftData && (
        <SkuTruckCuftConfirmationModal
          insufficientSkuTruckCuftObject={insufficientTruckCuftData?.insufficientSkuTruckCuft}
          onCancel={() => {
            setInsufficientTruckCuftData(undefined);
            setDraft(!state || state === 'draft');
            setSubmitting(false);
          }}
          onConfirm={handleTappedConfirmOnInsufficientTruckCuftModal}
        />
      )}
      {duplicateModalVisible && (
        <ConfirmationModal
          title={`An active load with number ${
            formData!.number
          } already exists. Are you sure you want to create this load?`}
          acceptButton="Yes, create this load"
          declineButton="No, do not create this load"
          onConfirm={() => {
            onSubmitWithChecks(formData!);
            setDuplicateModalVisible(false);
          }}
          onClose={() => setDuplicateModalVisible(false)}
        />
      )}
    </>
  );
};
