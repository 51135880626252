import React from 'react';
import { Formik, FormikErrors, Form as FormikForm } from 'formik';

import { Currency, Hiring__JobListing__Input, useHiringJobListingFormDataQuery } from '@admin/schema';

import { client } from '@admin/libraries/apollo';
import { Alert, Button, Panel } from '@shared/components/bootstrap';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { FormikNumberFormGroup } from '@admin/components/fields/formik/formik_number_input_group';
import { convertEncodedFacilityToFacilityInput, encodeFacility, facilityNameWithType } from '@admin/utils/facilities';
import { Spinner } from '@admin/components/spinner';

type FormValues = {
  regionID?: string;
  encodedFacility?: string;
  hourlyRate?: number;
  jobID?: string;
  fullTime?: string;
  nightShift?: string;
};

export const HiringJobListingForm: React.FC<{
  save(_: Hiring__JobListing__Input): void;
  defaults?: FormValues;
  saving?: boolean;
  error?: string | null;
}> = ({ save, defaults, saving, error }) => {
  const { data: formData } = useHiringJobListingFormDataQuery({ client });

  const onSubmit = (values: FormValues) => {
    if (
      !values.jobID ||
      !values.encodedFacility ||
      !values.hourlyRate ||
      values.fullTime === undefined ||
      values.nightShift === undefined ||
      !values.regionID
    ) {
      return;
    }

    save({
      jobID: values.jobID,
      facility: convertEncodedFacilityToFacilityInput(values.encodedFacility),
      hourlyRate: values.hourlyRate,
      fullTime: values.fullTime === 'true',
      nightShift: values.nightShift === 'true',
      regionID: values.regionID,
    });
  };

  const validateInput = (values: FormValues): FormikErrors<FormValues> => {
    const formErrors: FormikErrors<FormValues> = {};

    if (!values.jobID) {
      formErrors.jobID = 'A job title is required';
    }

    if (!values.regionID) {
      formErrors.regionID = 'A region is required';
    }

    if (!values.encodedFacility) {
      formErrors.encodedFacility = 'A facility is required';
    }

    if (!values.hourlyRate) {
      formErrors.hourlyRate = 'An hourly rate is required';
    }

    if (values.hourlyRate && values.hourlyRate <= 0) {
      formErrors.hourlyRate = 'Hourly rate must be greater than 0';
    }

    if (values.fullTime === undefined) {
      formErrors.fullTime = 'A time commitment is required';
    }

    if (values.nightShift === undefined) {
      formErrors.nightShift = 'A shift is required';
    }

    return formErrors;
  };

  const filterFacilities = (values: FormValues) => {
    const selectedJob = formData?.hiringJobs.find((job) => job.id === values.jobID);
    return (
      formData?.facilities.filter((facility) => {
        const isRegionMatch = facility.regions.some((region: { id: string }) => region.id === values.regionID);
        const isFacilityTypeMatch =
          (selectedJob?.warehouse && facility.__typename === 'Warehouse') ||
          (selectedJob?.field && facility.__typename === 'Depot');
        return isRegionMatch && isFacilityTypeMatch;
      }) ?? []
    );
  };

  return (
    <Formik<FormValues>
      initialValues={defaults ?? {}}
      onSubmit={onSubmit}
      validate={validateInput}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ values }) => (
        <FormikForm>
          <Panel>
            <Panel.Header style={{ padding: '20px' }}>
              <h4>{defaults ? 'Edit' : 'Create'} Job Listing</h4>
            </Panel.Header>
            {!formData ? (
              <Spinner />
            ) : (
              <>
                <Panel.Body>
                  {error && <Alert style="danger">{error}</Alert>}
                  <FormikSelectFormGroup label="Job Title" id="jobID" name="jobID">
                    <option value=""> - Select - </option>
                    {formData?.hiringJobs.map((job) => (
                      <option key={job.id} value={job.id}>
                        {job.title}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                  <FormikSelectFormGroup label="Region" id="regionID" name="regionID">
                    <option value=""> - Select - </option>
                    {formData?.regions.map((region) => (
                      <option key={region.id} value={region.id}>
                        {region.name}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                  <FormikSelectFormGroup label="Facility" id="encodedFacility" name="encodedFacility">
                    <option value=""> - Select - </option>
                    {filterFacilities(values).map((facility) => (
                      <option key={encodeFacility(facility)} value={encodeFacility(facility)}>
                        {facilityNameWithType(facility)}
                      </option>
                    ))}
                  </FormikSelectFormGroup>

                  <FormikNumberFormGroup
                    label={`Hourly Rate (${
                      formData.regions.find((region) => region.id === values.regionID)?.currency ?? Currency.Usd
                    })`}
                    name="hourlyRate"
                    allowDecimal
                  />

                  <FormikSelectFormGroup label="Time Commitment" id="fullTime" name="fullTime">
                    <option value=""> - Select - </option>
                    <option key="true" value="true">
                      Full-Time
                    </option>
                    <option key="false" value="false">
                      Part-Time
                    </option>
                  </FormikSelectFormGroup>

                  <FormikSelectFormGroup label="Shift" id="nightShift" name="nightShift">
                    <option value=""> - Select - </option>
                    <option key="false" value="false">
                      Daytime
                    </option>
                    <option key="true" value="true">
                      Night
                    </option>
                  </FormikSelectFormGroup>

                  {values.hourlyRate && values.hourlyRate > 40 && (
                    <div style={{ backgroundColor: 'orange', padding: '10px', borderRadius: '5px', color: 'white' }}>
                      The entered hourly rate seems high. Doublecheck that this is correct before proceeding.
                    </div>
                  )}
                </Panel.Body>
                <Panel.Footer align="right">
                  <Button loading={saving} kind="primary" type="submit">
                    Save
                  </Button>
                </Panel.Footer>
              </>
            )}
          </Panel>
        </FormikForm>
      )}
    </Formik>
  );
};
