/** @jsx jsx */

import * as React from 'react';

import { css, jsx } from '@emotion/react';

import { IDispatchOrder } from '@admin/types/dispatch_order';
import { Tooltip } from '@shared/components/bootstrap/tooltip';
import { DateTime } from 'luxon';
import { format } from './utils';
import { useTimeZone } from '../context';
import { useScale } from './order_gantt';

const transparentRed = 'rgba(255,0,0, 0.2)';
const transparentBlue = 'rgb(3, 165, 252, 0.2)';

export const SLAWindow: React.FC<{ order: IDispatchOrder }> = ({
  order: { scheduled, slaWindowEnd, expectedStartTime },
}) => {
  const timeZone = useTimeZone();
  const leftTime = DateTime.fromISO(scheduled).setZone(timeZone);
  const rightTime = DateTime.fromISO(slaWindowEnd).setZone(timeZone);
  const scale = useScale();
  const size = scale(rightTime) - scale(leftTime);
  const runningLate = expectedStartTime && slaWindowEnd < expectedStartTime;

  const color = runningLate ? transparentRed : transparentBlue;

  const title = `SLA: ${format(leftTime)} - ${format(rightTime)}`;

  // set window positioning local variables
  const positioning = css`
    left: ${scale(leftTime)}%;
    width: ${size}%;
  `;

  const slaWindowStyle = css`
    height: 100%;
    background-color: ${color};
    position: absolute;
  `;

  return (
    <div css={[positioning, slaWindowStyle]}>
      <Tooltip key={title} title={title}>
        <div style={{ height: '100%', width: '100%' }} />
      </Tooltip>
    </div>
  );
};
