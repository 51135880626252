import React, { useEffect, useState } from 'react';

import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Refresh } from '@admin/components/refresh';
import { Spinner } from '@admin/components/spinner';
import {
  CustomerTicketFiltersInput,
  CustomerTicketSortInput,
  PaginatedCustomerTicketListQuery,
  usePaginatedCustomerTicketListQuery,
} from '@admin/schema';
import { CustomerTicketMode } from '@admin/types/customer_ticket_filters_input';
import { CustomerTicketState } from '@admin/types/customer_ticket';
import { Retry } from '@shared/components/helpers';
import { client } from '@admin/libraries/apollo';

import { Filters } from './filters';
import { Table } from './table';

const DEFAULT_FILTER_STATE = {
  mode: CustomerTicketMode.Unassigned,
  state: CustomerTicketState.Open,
};

const Result: React.FC<{
  filters: CustomerTicketFiltersInput;
  sort?: CustomerTicketSortInput;
  setSort(sort?: CustomerTicketSortInput): void;
}> = ({ filters, sort, setSort }) => {
  const [tickets, setTickets] = useState<PaginatedCustomerTicketListQuery>();

  const { data, error, refetch } = usePaginatedCustomerTicketListQuery({
    client,
    variables: {
      filters,
      sort,
    },
  });

  useEffect(() => setTickets(data), [data]);

  if (error) {
    return <Retry error={error} refetch={refetch} />;
  }

  if (!tickets) {
    return <Spinner />;
  }

  const { paginatedCustomerTicketList } = tickets;

  return (
    <>
      <Refresh refetch={refetch} intervalSeconds={15} />
      <Table customerTickets={paginatedCustomerTicketList.results} sort={sort} setSort={setSort} />
      <Pagination
        pagination={paginatedCustomerTicketList.pagination}
        onPage={(page) => refetch({ filters, sort, page })}
      />
    </>
  );
};

export const Queue: React.FC = () => {
  const [filters, setFilters] = useState<CustomerTicketFiltersInput>(DEFAULT_FILTER_STATE);
  const [sort, setSort] = useState<CustomerTicketSortInput>();

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Customer Tickets</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Filters filters={filters} setFilters={setFilters} />
        <Result filters={filters} sort={sort} setSort={setSort} />
      </Panel.Body>
    </Panel>
  );
};
