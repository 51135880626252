import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { useUsersListQuery, User, User__Role } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Table, Col, Row, Button, AnchorButton as Link } from '@shared/components/bootstrap';
import { Spinner } from '@admin/components/spinner';
import { FieldFormGroup, InputFormGroup, MultiselectFormControl, SelectFormGroup } from '@admin/components/fields';
import styled from '@emotion/styled';
import { UserRole } from '@shared/types/user_role';
import { Roles } from '@admin/components/helpers/roles';
import { useDebounce } from '@shared/hooks';
import { Formatter } from '@admin/components/phone/formatter';
import { USER_ROLES, USER_ROLE_TO_NAME } from '@admin/constants/user_role';
import { Pagination } from '@shared/components/pagination';
import { ROLES } from '@admin/config/roles';
import { Activate } from './activate';
import { Delete } from './delete';
import { Role } from './role';
import { State } from './state';

const DEFAULT_PAGE = 1;

const ROLE_OPTIONS = USER_ROLES.map((role) => ({
  id: role,
  name: USER_ROLE_TO_NAME[role],
}));

const Labels = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4px;
`;

const Buttons = styled.div`
  display: flex;
  gap: 4px;
`;

enum Status {
  All = 'all',
  Active = 'active',
  Deleted = 'deleted',
}

const STATUS_TO_ACTIVE: Record<Status, boolean | undefined> = {
  [Status.All]: undefined,
  [Status.Active]: true,
  [Status.Deleted]: false,
};

export const List: React.FC = () => {
  const [query, setQuery] = useState<string | undefined>();
  const [roles, setRoles] = useState<User__Role[] | undefined>();
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [status, setStatus] = useState<Status>(Status.Active);

  const [activating, setActivating] = useState<Pick<User, 'id' | 'name'> | undefined>();
  const [deleting, setDeleting] = useState<Pick<User, 'id' | 'name'> | undefined>();
  const { data, loading } = useUsersListQuery({
    client,
    variables: {
      page,
      filters: {
        active: STATUS_TO_ACTIVE[status],
        roles,
        query: useDebounce(query),
      },
    },
  });

  const userIsEditable = (allRolesEditableByManager: boolean) =>
    ROLES.includes(UserRole.Admin) ||
    (allRolesEditableByManager && (ROLES.includes(UserRole.Manager) || ROLES.includes(UserRole.EnterpriseManager)));

  return (
    <>
      {deleting && (
        <Delete
          user={deleting}
          onClose={() => {
            setDeleting(undefined);
          }}
        />
      )}

      {activating && (
        <Activate
          user={activating}
          onClose={() => {
            setActivating(undefined);
          }}
        />
      )}

      <Panel>
        <Panel.Body>
          <Row>
            <Col sm={6}>
              <InputFormGroup
                label="Search"
                type="text"
                value={query ?? ''}
                onChange={(event) => {
                  setQuery(event.target.value || '');
                  setPage(DEFAULT_PAGE);
                }}
                placeholder="Search by name, email, phone"
              />
            </Col>
            <Col sm={3}>
              <FieldFormGroup label="Role">
                <MultiselectFormControl
                  selectedIDs={roles ?? []}
                  options={ROLE_OPTIONS}
                  placeholder="- Role -"
                  onChange={(values) => {
                    setRoles(values.length ? values : undefined);
                    setPage(DEFAULT_PAGE);
                  }}
                />
              </FieldFormGroup>
            </Col>
            <Col sm={3}>
              <SelectFormGroup
                label="Status"
                value={status}
                onChange={(event) => {
                  setStatus(event.target.value as Status);
                  setPage(DEFAULT_PAGE);
                }}
              >
                <option value={Status.All}>All</option>
                <option value={Status.Active}>Active</option>
                <option value={Status.Deleted}>Deleted</option>
              </SelectFormGroup>
            </Col>
          </Row>
        </Panel.Body>
        <Panel.Footer align="right">
          <Buttons>
            <Roles show={[UserRole.Admin]}>
              <Link href="/users.csv" target="_blank" kind="warning">
                Export
              </Link>
            </Roles>
            <Roles show={[UserRole.Admin, UserRole.Manager, UserRole.EnterpriseManager]}>
              <Link href="/users/new" kind="mint" className="btn-labeled fa fa-plus">
                Add
              </Link>
            </Roles>
          </Buttons>
        </Panel.Footer>
      </Panel>
      <Panel>
        <Panel.Body>
          <Table responsive striped>
            <thead>
              <tr>
                <th className="col-sm-2">Name</th>
                <th className="col-sm-2">Email</th>
                <th className="col-sm-2">Phone</th>
                <th className="col-sm-2">Roles</th>
                <th className="col-sm-2">State</th>
                <th className="col-sm-2">Logins</th>
                <th className="col-zero col-nowrap" />
              </tr>
            </thead>

            <tbody>
              {data?.paginated.results.map((user) => (
                <tr key={user.id}>
                  <td className="col-sm-2">{user.name}</td>
                  <td className="col-sm-2">
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td className="col-sm-2">{user.phone ? <Formatter number={user.phone} /> : <span>-</span>}</td>
                  <td className="col-sm-2">
                    <Labels>
                      {user.roles.map((role, index) => (
                        <Role key={index} role={role} />
                      ))}
                    </Labels>
                    {!user.roles.length && <span>-</span>}
                  </td>
                  <td className="col-sm-2">
                    <State state={user.state} />
                  </td>
                  <td className="col-sm-2">{user.signInCount || '-'}</td>
                  <td className="col-zero col-nowrap">
                    <Buttons>
                      <Link disabled={!user.active} kind="primary" href={`/users/${user.id}`}>
                        View
                      </Link>
                      {userIsEditable(user.allRolesEditableByManager) && (
                        <Link disabled={!user.active} kind="primary" href={`/users/${user.id}/edit`}>
                          Edit
                        </Link>
                      )}
                      {userIsEditable(user.allRolesEditableByManager) && (
                        <Button kind="danger" onClick={() => (user.active ? setDeleting(user) : setActivating(user))}>
                          {user.active ? 'Delete' : 'Activate'}
                        </Button>
                      )}
                    </Buttons>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <Spinner />}
          {data?.paginated.pagination && <Pagination pagination={data.paginated.pagination} onPage={setPage} />}
        </Panel.Body>
      </Panel>
    </>
  );
};
