import { IRoomCategory } from '@shared/types/room_category';
import { IRoomSelection } from '@shared/types/room_selection';

interface IRoomSelectionAttribute {
  id?: string;
  room_category_id: string;
  quantity?: number;
  selected?: boolean;
}

export const buildRoomSelectionsAttributes = (data?: Map<IRoomCategory, Partial<IRoomSelection>>) => {
  const results: IRoomSelectionAttribute[] = [];
  if (!data) {
    return results;
  }

  for (const category of Array.from(data.keys())) {
    const selection = data.get(category);
    if (!selection) {
      continue;
    }
    results.push({
      id: selection.id,
      room_category_id: category.id,
      quantity: selection.quantity,
      selected: selection.selected,
    });
  }

  return results;
};
