import styled from '@emotion/styled';
import * as React from 'react';

import {
  KindFormGroup as OfflinePaymentKindFormGroup,
  TransactionNumberFormGroup as OfflinePaymentTransactionNumberFormGroup,
} from '@admin/components/billing/offline_payments';
import { Billing__OfflinePayment__Kind } from '@admin/schema';
import { FieldFormGroup } from '@admin/components/fields';
import { Radio } from '@shared/components/bootstrap';
import { BillingMethod } from '@admin/types/billing/source';
import { BillingMethodOther } from '@admin/types/billing/source';
import { BillingMethodSourceType } from '@admin/types/billing/source';

import { Radios as SourcesRadios } from './radios';

const Container = styled.fieldset`
  background: #fafafa;
  padding: 20px;
`;

const SourceFields: React.FC<{
  source: BillingMethodOther;
  onChange(source: BillingMethodOther): void;
}> = ({ source, onChange }) => (
  <Container>
    <OfflinePaymentKindFormGroup
      kind={source.kind}
      onSelect={(kind) => {
        onChange({
          ...source,
          kind,
          transactionNumber: kind === Billing__OfflinePayment__Kind.Cash ? undefined : source.transactionNumber,
        });
      }}
    />
    <OfflinePaymentTransactionNumberFormGroup
      disabled={source.kind === Billing__OfflinePayment__Kind.Cash}
      required={source.kind !== Billing__OfflinePayment__Kind.Cash}
      value={source.transactionNumber}
      onChange={(transactionNumber) => {
        onChange({
          ...source,
          transactionNumber,
        });
      }}
    />
  </Container>
);

export const Select: React.FC<{
  id?: string;
  accountID: string;
  source?: BillingMethod;
  setSource: (source: BillingMethod) => void;
  showOtherSource?: boolean;
  showCreateBalance?: boolean;
}> = ({ id, accountID, source, setSource, showOtherSource, showCreateBalance }) => (
  <>
    <FieldFormGroup label="Source:" id={id}>
      <SourcesRadios
        name={id || ''}
        accountID={accountID}
        selectedID={source?.type === BillingMethodSourceType.Source ? source.id : undefined}
        onSelect={(value) => {
          setSource({
            ...value,
            type: BillingMethodSourceType.Source,
          });
        }}
      />
      {showOtherSource && (
        <Radio>
          <input
            type="radio"
            checked={source?.type === BillingMethodSourceType.Other}
            onChange={() => setSource({ type: BillingMethodSourceType.Other })}
          />
          ACH / Cash / Check / etc
        </Radio>
      )}
      {showCreateBalance && (
        <Radio>
          <input
            type="radio"
            checked={source?.type === BillingMethodSourceType.BalanceOnly}
            onChange={() => setSource({ type: BillingMethodSourceType.BalanceOnly, kind: undefined })}
          />
          Create a balance only
        </Radio>
      )}
    </FieldFormGroup>
    {source?.type === BillingMethodSourceType.Other && <SourceFields source={source} onChange={setSource} />}
  </>
);
