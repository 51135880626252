import { difference, union } from 'lodash';
import * as React from 'react';

import { IItem } from '@admin/types/item';
import { IPallet } from '@admin/types/pallet';

import { Titleize } from '@admin/components/helpers/titleize';
import { Pluralize } from '@shared/components/helpers/pluralize';

export const Pallet: React.FC<{
  pallet: IPallet;
  selections: IItem[];
  onSelect(selections: IItem[]): void;
}> = ({ pallet, selections, onSelect }) => {
  const selectableItems = pallet.items.filter((item) => !item.delayed);
  const selectedIDs = new Set(selections.map(({ id }) => id));
  const checked = selectableItems.every((item) => selectedIDs.has(item.id));

  return (
    <div className="panel">
      <div className="panel-body">
        <div className="pull-left">
          <div className="checkbox">
            <label>
              <input
                type="checkbox"
                checked={checked}
                onChange={(event) => {
                  onSelect((event.target.checked ? union : difference)(selections, selectableItems));
                }}
              />
            </label>
          </div>
        </div>

        <div className="pull-left">
          <div>
            <span>#{pallet.barcode.value}</span> <strong>({pallet.state})</strong>
          </div>
          <div>
            <Pluralize count={selectableItems.length} singular="Item" plural="Items" />
          </div>
        </div>

        {pallet.location && (
          <div className="pull-right">
            <div>
              <strong>
                <Titleize text={pallet.location.located.type} />:
              </strong>{' '}
              <span>{pallet.location.located.object.name}</span>
            </div>
            <div>
              <strong>Location:</strong> <span>#{pallet.location.value}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
