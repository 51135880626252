import * as React from 'react';
import { useMemo } from 'react';

import { IInventoryResource } from '@admin/types/inventory_resource';
import { IInventorySection } from '@admin/types/inventory_section';
import { IItem } from '@admin/types/item';
import { IItemGroup } from '@admin/types/item_group';
import { InventorySectionMode } from '@admin/types/inventory_section';
import { IPallet } from '@admin/types/pallet';
import { IPalletInventorySection } from '@admin/types/inventory_section';
import { IWarehouse } from '@admin/types/warehouse';
import { IWarehouseInventorySection } from '@admin/types/inventory_section';

import { Section } from './section';

const buildWarehouseInventorySections = ({
  items,
  groups,
  warehouses,
}: {
  items: IItem[];
  groups: IItemGroup[];
  warehouses: IWarehouse[];
}): IWarehouseInventorySection[] => {
  const warehouseIDs = new Set(items.map(({ warehouse_id }) => warehouse_id));
  return Array.from(warehouseIDs).map((warehouseID) => ({
    mode: InventorySectionMode.Warehouses,
    warehouse: warehouses.find(({ id }) => id === warehouseID),
    resources: [
      ...groups
        .filter((group) => group.items.some((item) => item.warehouse_id === warehouseID))
        .map((object): IInventoryResource => ({ object, kind: 'item-group' })),
      ...items
        .filter((item) => item.warehouse_id === warehouseID && !item.group_id)
        .map((object): IInventoryResource => ({ object, kind: 'item' })),
    ],
  }));
};

const buildPalletInventorySections = ({
  items,
  groups,
  pallets,
}: {
  items: IItem[];
  groups: IItemGroup[];
  pallets: IPallet[];
}): IPalletInventorySection[] => {
  const palletIDs = new Set(items.map(({ pallet_id }) => pallet_id));

  return Array.from(palletIDs).map((palletID) => ({
    mode: InventorySectionMode.Pallets,
    pallet: pallets.find(({ id }) => id === palletID),
    resources: [
      ...groups
        .filter((group) => group.items.some((item) => item.pallet_id === palletID))
        .map((object): IInventoryResource => ({ object, kind: 'item-group' })),
      ...items
        .filter((item) => item.pallet_id === palletID && !item.group_id)
        .map((object): IInventoryResource => ({ object, kind: 'item' })),
    ],
  }));
};

export const Sections: React.FC<{
  items: IItem[];
  groups: IItemGroup[];
  pallets: IPallet[];
  warehouses: IWarehouse[];
  mode: InventorySectionMode;
  selections: IItem[];
  onSelect(selections: IItem[]): void;
}> = ({ items, groups, pallets, warehouses, mode, selections, onSelect }) => {
  const sections: IInventorySection[] = useMemo(() => {
    switch (mode) {
      case InventorySectionMode.Pallets:
        return buildPalletInventorySections({ items, groups, pallets });
      case InventorySectionMode.Warehouses:
        return buildWarehouseInventorySections({ items, groups, warehouses });
    }
  }, [items, groups, pallets, warehouses, mode]);

  return (
    <>
      {sections.map((section, key) => (
        <Section key={`${mode}-${key}`} selections={selections} section={section} onSelect={onSelect} />
      ))}
    </>
  );
};
