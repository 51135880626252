import React, { useEffect, useState } from 'react';
import { depotsURL } from '@admin/config/routes';
import { client } from '@admin/libraries/apollo';
import { isEqual } from 'lodash';
import { DepotInput, useDepotQuery, useModifyDepotMutation } from '@admin/schema';
import { Breadcrumb, Button, Panel } from '@shared/components/bootstrap';
import { Spacer } from '@shared/components/helpers';
import { encodeFacility } from '@admin/utils/facilities';
import { ROLES } from '@admin/config/roles';
import { UserRole } from '@shared/types/user_role';
import { DepotForm } from './depot_form';
import { HiringOnsiteInterviewTimeSlotsCalendar } from '../hiring/onsite_interview_time_slots/calendar';

export const DepotDetails: React.FC<{ id: string }> = ({ id }) => {
  const [savedDepot, setSavedDepot] = useState<DepotInput | undefined>(undefined);
  const [depot, setDepot] = useState<DepotInput | undefined>(undefined);
  const [error, setError] = useState<string | undefined>();
  const [editingOtherDetails, setEditingOtherDetails] = useState<boolean>(false);

  const { data, loading: loadingQuery } = useDepotQuery({ client, variables: { id } });
  const [execute, { loading: loadingMutation }] = useModifyDepotMutation({ client });

  const editAccess = ROLES.includes(UserRole.Admin);

  const onSave = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (!depot || loadingMutation) {
      return;
    }
    const input = depot;
    const variables = { input, id };
    const response = await execute({ variables });
    if (response && response.data) {
      setError(response.data.modifyDepot.error || undefined);
      if (response.data.modifyDepot.error === null) {
        window.location.href = depotsURL();
      }
    }
  };

  useEffect(() => {
    if (data && data.depot && depot === undefined) {
      const newDepot = {
        name: data.depot.name,
        abbreviation: data.depot.abbreviation,
        active: data.depot.active,
        address: {
          aptsuite: data.depot.address.aptsuite || '',
          street: data.depot.address.street,
          city: data.depot.address.city,
          state: data.depot.address.state,
          country: data.depot.address.country || 'United States',
          zip: data.depot.address.zip,
          latitude: data.depot.address.latitude ?? undefined,
          longitude: data.depot.address.longitude ?? undefined,
        },
        regionIDs: data.depot.regions.map((region) => region.id),
        tz: data.depot.tz,
      };
      setSavedDepot(newDepot);
      setDepot(newDepot);
    }
  }, [data, depot]);

  return (
    <>
      <div className="page-header text-overflow">
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href={depotsURL()}>Depots</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{data?.depot.name}</Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
      <h3>Onsite Interview Time Slots</h3>
      {data?.depot && (
        <HiringOnsiteInterviewTimeSlotsCalendar preSelectedEncodedFacility={encodeFacility(data.depot)} />
      )}
      <Spacer height="8px" />
      <h3>Other Details</h3>
      <Panel>
        {editAccess && (
          <Panel.Header>
            <div style={{ justifyContent: 'flex-end', display: 'flex', gap: '2px', padding: '16px' }}>
              <Button kind="primary" type="button" onClick={() => setEditingOtherDetails(!editingOtherDetails)}>
                {editingOtherDetails ? 'Cancel Editing' : 'Edit Details'}
              </Button>
            </div>
          </Panel.Header>
        )}
        {error && <div className="alert alert-danger">{error}</div>}
        {loadingQuery || !depot ? null : (
          <form
            onSubmit={async (event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <Panel.Body>
              <DepotForm depot={depot} onChange={setDepot} editAccess={editAccess && editingOtherDetails} />
            </Panel.Body>
            {editAccess && editingOtherDetails && (
              <Panel.Footer align="right">
                <Button kind="primary" disabled={loadingMutation || isEqual(savedDepot, depot)} onClick={onSave}>
                  Save
                </Button>
              </Panel.Footer>
            )}
          </form>
        )}
      </Panel>
    </>
  );
};
