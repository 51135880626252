import * as React from 'react';

import { Titleize } from '@admin/components/helpers/titleize';
import { Timestamp } from '@admin/components/timestamp';

import { IMarketingEvent } from '@admin/types/marketing_event';

export const MarketingEvent: React.FC<{
  resource: IMarketingEvent;
  timestamp: string;
  timezone?: string;
}> = ({ resource, timestamp, timezone }) => (
  <tr>
    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <Timestamp value={timestamp} timezone={timezone} />
    </td>
    <td className="col-nowrap col-md-2 text-left" colSpan={2}>
      <strong>System</strong> <span>assigned a marketing event</span>
    </td>
    <td className="col-md-8 text-left" colSpan={8}>
      <ul>
        {Object.keys(resource.metadata).map((key) => {
          const value = resource.metadata[key];

          return (
            <li key={key}>
              <strong>
                <Titleize text={key} />
              </strong>{' '}
              <span>as</span> <strong>{value !== null && <Titleize text={String(value)} />}</strong>
            </li>
          );
        })}
      </ul>
    </td>
  </tr>
);
