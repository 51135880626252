import * as React from 'react';

import { Omit } from '@admin/utils/omit';
import { mask, parse } from '@shared/utils/phone';

export const PhoneFormControl: React.FC<
  {
    value?: string;
    onChange(value?: string): void;
  } & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'>
> = ({ value, onChange, ...props }) => (
  <input
    {...props}
    type="text"
    placeholder="Phone"
    className="form-control"
    value={(value && mask(value)) || ''}
    onChange={(event) => {
      onChange(parse(event.target.value) || undefined);
    }}
  />
);
