import styled from '@emotion/styled';
import { Button, Modal, Panel } from '@shared/components/bootstrap';
import React from 'react';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const ConfirmModal: React.FC<{
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}> = ({ message, onConfirm, onCancel }) => (
  <Modal onClose={onCancel}>
    <Panel>
      <Panel.Header style={{ padding: '20px' }}>
        <h4>Confirm</h4>
      </Panel.Header>
      <Panel.Body>{message}</Panel.Body>
      <Panel.Footer>
        <ButtonContainer>
          <Button kind="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button kind="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </ButtonContainer>
      </Panel.Footer>
    </Panel>
  </Modal>
);
