import { IOrderInventory } from '@shared/types/order_inventory';

import { filterInventoryForSave } from '@shared/utils/filter_inventory_for_save';
import { buildEstimatedItemsAttributes } from './build_estimated_items_attributes';
import { buildEstimationSelectionsAttributes } from './build_estimation_selections_attributes';
import { buildRoomSelectionsAttributes } from './build_room_selections_attributes';

export const buildInventoryAttributes = (inventory: IOrderInventory) => {
  const { packableEstimations, unpackableEstimations, extraItems, rooms } = filterInventoryForSave(inventory);

  return {
    reset_estimated_items_attributes: buildEstimatedItemsAttributes(extraItems),
    reset_estimation_selections_attributes: [
      ...buildEstimationSelectionsAttributes(packableEstimations),
      ...buildEstimationSelectionsAttributes(unpackableEstimations),
    ],
    reset_room_selections_attributes: buildRoomSelectionsAttributes(rooms),
  };
};
