import * as React from 'react';
import { useState } from 'react';
import styled from '@emotion/styled';

import { client } from '@admin/libraries/apollo';
import { ILightboxCarouselInput, LightboxWithCarousel } from '@shared/components/helpers/lightbox';
import { Spinner } from '@admin/components/spinner';
import { ItemGroupPanelQuery, useItemGroupPanelQuery } from '@admin/schema';
import { Timestamp } from '@admin/components/timestamp';
import { Text } from '@shared/components/bootstrap';

import { Panel as Container } from '@admin/components/helpers/panel';

const DEFAULT_COLLAPSED = false;

const Image = styled.img`
  margin: 10px 0 10px 0;
  display: block;
  width: 100%;
  height: auto;
`;

const Scrollable = styled.div`
  max-height: 60vh;
  overflow: hidden;
  overflow-y: scroll;
`;

type ItemGroup = ItemGroupPanelQuery['itemGroup'];

export const ItemGroupsPanel: React.FC<{ id: string }> = ({ id }) => {
  const [collapsed, setCollapsed] = useState<boolean>(DEFAULT_COLLAPSED);

  const { data, loading } = useItemGroupPanelQuery({
    client,
    variables: {
      id: String(id),
    },
  });

  const itemGroup = data?.itemGroup;

  return (
    <Container>
      <Container.Header>
        <Container.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Container.Title>Item Group</Container.Title>
      </Container.Header>
      {!collapsed && (
        <Container.Body>
          {loading && <Spinner />}
          {itemGroup && <ItemGroupInfo itemGroup={itemGroup} />}
          {!loading && !itemGroup && (
            <p className="text-center">We do not have a record of any disassembly for this item.</p>
          )}
        </Container.Body>
      )}
    </Container>
  );
};

const ItemGroupInfo: React.FC<{ itemGroup: ItemGroup }> = ({ itemGroup }) => {
  const [imageSelection, setImageSelection] = useState<number | undefined>(undefined);

  const itemGroupImages = itemGroup ? [itemGroup.heroImage!].concat(itemGroup.additionalImages!) : [];
  const imageSizeArray: ILightboxCarouselInput[] = itemGroupImages.map((image) => ({
    src: image.source,
  }));

  return (
    <div>
      <h4>{itemGroup.category.name}</h4>
      {itemGroup.sizingSelection && <p>{itemGroup.sizingSelection}</p>}
      {itemGroup.width && itemGroup.length && itemGroup.height && (
        <p>
          {itemGroup.length}" × {itemGroup.width}" × {itemGroup.height}"
        </p>
      )}
      <Scrollable>
        {itemGroupImages.map((image, index) => (
          <div key={image.id} className="col-sm-3 col-md-3 col-lg-3">
            <figure>
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  setImageSelection(index);
                }}
              >
                <Image src={image.size} />
              </a>
            </figure>
            <Text tag="p" alignment="center">
              Taken at: <Timestamp value={image.createdAt} format="medium" />
            </Text>
          </div>
        ))}
        {imageSelection != null && imageSizeArray && (
          <LightboxWithCarousel
            imageInputs={imageSizeArray}
            defaultActiveIndex={imageSelection}
            onClose={() => setImageSelection(undefined)}
          />
        )}
      </Scrollable>
    </div>
  );
};
