import React, { useEffect } from 'react';

import {
  AppointmentQuote,
  useCreateAppointmentQuoteMutation,
  useFormattedLaborAgreementQuery,
  Pricing__QuotableEnum,
  Pricing__SourceEnum,
  LaborRate,
} from '@admin/schema';

import { IAccountPackage } from '@admin/types/account_package';

import { client } from '@admin/libraries/apollo';
import { PackageSetEntries } from '../package_set_entry/package_set_entries';
import { LaborAgreementDescriptor } from '../labor_agreement_descriptor/labor_agreement_descriptor';

export const OnboardingAppointmentPricing: React.FC<{
  pricingSetId?: string;
  rateGroupId?: string;
  cuft?: number;
  zip?: string;
  quotableType: Pricing__QuotableEnum;
  quotableId: number;
  source: Pricing__SourceEnum;
  isCurbside?: boolean;
  setAppointmentData(
    accountPackages: IAccountPackage[],
    laborRate?: LaborRate,
    appointmentQuote?: AppointmentQuote,
  ): void;
}> = ({ pricingSetId, rateGroupId, cuft, zip, quotableType, quotableId, source, isCurbside, setAppointmentData }) => {
  const [mutate, { data }] = useCreateAppointmentQuoteMutation({ client });

  useEffect(() => {
    if (cuft && rateGroupId && zip) {
      mutate({
        variables: {
          zip: zip,
          cuft: cuft,
          rateGroupId: rateGroupId,
          quotableId: quotableId.toString(),
          quotableType: quotableType,
          source: source,
        },
      }).then((result) => {
        if (!result.data?.createAppointmentQuote) {
          setAppointmentData([], undefined, undefined);
        } else {
          const { laborRate, onboardingPackageSetEntries, finalPackageSetEntries, quote } =
            result.data.createAppointmentQuote;
          const accountPackages = onboardingPackageSetEntries.concat(finalPackageSetEntries).map((entry) => ({
            package_set_entry_id: entry.id,
            amount: entry.amount,
          }));
          setAppointmentData(accountPackages, laborRate, quote ?? undefined);
        }
      });
    }
  }, [zip, cuft, rateGroupId, quotableId, quotableType, source]);

  const onboardingPackageSetEntries = data?.createAppointmentQuote?.onboardingPackageSetEntries ?? [];
  const finalPackageSetEntries = data?.createAppointmentQuote?.finalPackageSetEntries ?? [];
  const packageSetEntries = onboardingPackageSetEntries.concat(finalPackageSetEntries);
  const laborRate = data?.createAppointmentQuote?.laborRate;

  const laborAgreementQuery = useFormattedLaborAgreementQuery({
    variables: {
      pricingSetId: pricingSetId!,
      rateGroupId: rateGroupId!,
      cuft: cuft!,
      orderZip: zip!,
      laborRateId: laborRate?.id,
      packageSetEntryIds: packageSetEntries?.map((entry) => entry.id),
    },
    client,
    skip: !pricingSetId || !rateGroupId || !cuft || !zip,
  });

  return (
    <div className="form-horizontal">
      <PackageSetEntries entries={onboardingPackageSetEntries} />
      {cuft && laborAgreementQuery.data?.formattedLaborAgreement && (
        <div id="labor-agreement-descriptor" className="col-sm-7">
          <LaborAgreementDescriptor
            agreement={laborAgreementQuery.data?.formattedLaborAgreement}
            cuft={cuft}
            isCurbside={!!isCurbside} // isCurbside is always present when rateGroupId is present
          />
        </div>
      )}
    </div>
  );
};
