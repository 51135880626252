import { OrderTypeEnum as Order__Type } from '@admin/schema';

export const ORDER_TYPES: Order__Type[] = [
  Order__Type.Pickup,
  Order__Type.Return,
  Order__Type.Restock,
  Order__Type.SelfStorageShuttle,
  Order__Type.Move,
  Order__Type.RetailDelivery,
  Order__Type.TaskOrder,
  Order__Type.Disposal,
];

export const ORDER_TYPE_TO_STATUS: Record<Order__Type, 'active' | 'legacy'> = {
  [Order__Type.Pickup]: 'active',
  [Order__Type.Return]: 'active',
  [Order__Type.PickupReturn]: 'active',
  [Order__Type.Restock]: 'active',
  [Order__Type.Move]: 'active',
  [Order__Type.Disposal]: 'active',
  [Order__Type.TaskOrder]: 'active',
  [Order__Type.SelfStorageShuttle]: 'legacy',
  [Order__Type.RetailDelivery]: 'legacy',
};

export const ORDER_TYPE_TO_NAME: Record<Order__Type, string> = {
  [Order__Type.Pickup]: 'Pickup',
  [Order__Type.Return]: 'Return',
  [Order__Type.PickupReturn]: 'Pickup + Return',
  [Order__Type.Restock]: 'Restock',
  [Order__Type.SelfStorageShuttle]: 'Self-Storage Shuttle',
  [Order__Type.Move]: 'Move',
  [Order__Type.RetailDelivery]: 'Retail Delivery',
  [Order__Type.TaskOrder]: 'Task Order',
  [Order__Type.Disposal]: 'Disposal',
};

export type OrderTypeParam =
  | 'move'
  | 'pickup'
  | 'return'
  | 'restock'
  | 'pickup-return'
  | 'self-storage-shuttle'
  | 'retail-delivery'
  | 'task'
  | 'disposal';

export const ORDER_TYPE_TO_PARAM: Record<Order__Type, OrderTypeParam> = {
  [Order__Type.Move]: 'move',
  [Order__Type.Pickup]: 'pickup',
  [Order__Type.Return]: 'return',
  [Order__Type.Restock]: 'restock',
  [Order__Type.PickupReturn]: 'pickup-return',
  [Order__Type.SelfStorageShuttle]: 'self-storage-shuttle',
  [Order__Type.RetailDelivery]: 'retail-delivery',
  [Order__Type.TaskOrder]: 'task',
  [Order__Type.Disposal]: 'disposal',
};

export const ORDER_TYPE_FROM_PARAM: Record<OrderTypeParam, Order__Type> = {
  move: Order__Type.Move,
  pickup: Order__Type.Pickup,
  return: Order__Type.Return,
  restock: Order__Type.Restock,
  'pickup-return': Order__Type.PickupReturn,
  'self-storage-shuttle': Order__Type.SelfStorageShuttle,
  'retail-delivery': Order__Type.RetailDelivery,
  task: Order__Type.TaskOrder,
  disposal: Order__Type.Disposal,
};
