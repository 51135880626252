import _ from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { Query } from '@apollo/client/react/components';

import { client } from '@admin/libraries/apollo';

import { ConfigWithSetup, InventoryCaptureForm } from '@shared/components/inventory_capture';

import { buildOrderInventory } from '@shared/utils/build_order_inventory';

import { IInventoryConfig } from '@shared/types/inventory_config';
import { IOrder } from '@shared/types/order';
import { IOrderInventory } from '@shared/types/order_inventory';
import { IPickupInventoryInput } from '@shared/types/pickup_inventory_input';

import { InventoryConfigDocument, OrderDetailsDocument } from '@admin/schema';

interface IQueryData extends IInventoryConfig {
  order: IOrder;
}

interface IQueryVariables {
  orderID: string;
}

interface IInventoryCaptureCallbacks {
  onChange(inventory: IOrderInventory): void;
}

export interface IInventoryCaptureProps extends IInventoryCaptureCallbacks {
  hideMoving?: boolean;
  orderID?: string;
  savedInventory?: IPickupInventoryInput;
}

// Inventory capture for new orders
const InventoryCaptureUsingConfigWithQuery: React.FC<
  IInventoryCaptureCallbacks & {
    savedInventory?: IPickupInventoryInput;
    hideMoving?: boolean;
  }
> = ({ onChange, savedInventory, hideMoving }) => (
  <Query<IInventoryConfig> query={InventoryConfigDocument}>
    {({ data }) => {
      if (!data || _.isEmpty(data)) {
        return null;
      }
      return (
        <InventoryCaptureUsingConfigWithSetup
          config={data}
          savedInventory={savedInventory}
          onChange={onChange}
          hideMoving={hideMoving}
        />
      );
    }}
  </Query>
);

// Inventory capture for existing orders
const InventoryCaptureUsingConfigWithSetup: React.FC<
  IInventoryCaptureCallbacks & {
    savedInventory?: IPickupInventoryInput;
    config: IInventoryConfig;
    hideMoving?: boolean;
  }
> = ({ savedInventory, config, hideMoving, onChange }) => {
  const [inventory, setInventory] = useState<IOrderInventory>(() =>
    savedInventory ? buildOrderInventory(savedInventory, config) : {},
  );
  useEffect(() => {
    onChange(inventory);
  }, [inventory]);

  return (
    <ConfigWithSetup {...config} inventory={inventory} onSelect={setInventory}>
      <InventoryCaptureForm hideMoving={hideMoving && !savedInventory?.isFullMoveOut} />
    </ConfigWithSetup>
  );
};

const InventoryCapture: React.FC<IInventoryCaptureProps> = ({ orderID, savedInventory, hideMoving, onChange }) => {
  if (!orderID) {
    return (
      <InventoryCaptureUsingConfigWithQuery
        savedInventory={savedInventory}
        onChange={onChange}
        hideMoving={hideMoving}
      />
    );
  }

  return (
    <Query<IQueryData, IQueryVariables>
      query={OrderDetailsDocument}
      variables={{ orderID: String(orderID) }}
      fetchPolicy="no-cache"
    >
      {({ data }) => {
        if (!data || !data.order) {
          return null;
        }
        const { order, ...config } = data;
        // NOTE: pickup / return orders are managed separately in admin (so use the current service).
        const service = order.services.find(({ id }) => id === String(orderID))!;
        return (
          <InventoryCaptureUsingConfigWithSetup
            config={config}
            savedInventory={service}
            hideMoving={hideMoving}
            onChange={onChange}
          />
        );
      }}
    </Query>
  );
};

const InventoryCaptureWithApolloProvider: React.FC<IInventoryCaptureProps> = (props) => (
  <ApolloProvider client={client}>
    <InventoryCapture {...props} />
  </ApolloProvider>
);

export { InventoryCaptureWithApolloProvider as InventoryCapture };
