import { Hiring__JobApplicationStatusEnum } from '@admin/schema';

export const getStatusBadgeClass = (status: Hiring__JobApplicationStatusEnum) => {
  switch (status) {
    case Hiring__JobApplicationStatusEnum.Started:
    case Hiring__JobApplicationStatusEnum.Submitted:
    case Hiring__JobApplicationStatusEnum.PreScreenPassed:
    case Hiring__JobApplicationStatusEnum.OnsiteInterviewScheduled:
      return 'bg-info';
    case Hiring__JobApplicationStatusEnum.PreScreenFailed:
    case Hiring__JobApplicationStatusEnum.OnsiteInterviewFailed:
      return 'bg-danger';
    case Hiring__JobApplicationStatusEnum.OnsiteInterviewPassed:
      return 'bg-success';
    case Hiring__JobApplicationStatusEnum.Expired:
      return 'bg-secondary';
  }
};

export const getStatusDisplayText = (status?: Hiring__JobApplicationStatusEnum) => {
  switch (status) {
    case Hiring__JobApplicationStatusEnum.Started:
      return 'Application Started';
    case Hiring__JobApplicationStatusEnum.Submitted:
      return 'Application Submitted';
    case Hiring__JobApplicationStatusEnum.PreScreenFailed:
      return 'Pre-Screen Failed';
    case Hiring__JobApplicationStatusEnum.PreScreenPassed:
      return 'Pre-Screen Passed';
    case Hiring__JobApplicationStatusEnum.OnsiteInterviewScheduled:
      return 'Onsite Interview Scheduled';
    case Hiring__JobApplicationStatusEnum.OnsiteInterviewFailed:
      return 'Onsite Interview Failed';
    case Hiring__JobApplicationStatusEnum.OnsiteInterviewPassed:
      return 'Onsite Interview Passed';
    case Hiring__JobApplicationStatusEnum.Expired:
      return 'Listing Expired';
  }
};
