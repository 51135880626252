import React, { useRef, createElement, useEffect, useState } from 'react';
import { useActiveStorageDirectUpload } from '@shared/hooks';

type TrixEditorAttachmentAttributes = {
  sgid: string;
  url: string;
};

type TrixEditorAttachment = {
  id: number;
  file: File;
  sgid?: string;
  setAttributes(_: TrixEditorAttachmentAttributes): void;
  setUploadProgress(progress: number): void;
};

type TrixEditorChangeEvent = React.ChangeEvent<TrixHTMLElement>;
type TrixEditorAttachmentEvent = { attachment: TrixEditorAttachment };

interface TrixEditorHTMLElementEventMap extends HTMLElementEventMap {
  'trix-change': TrixEditorChangeEvent;
  'trix-attachment-add': TrixEditorAttachmentEvent;
  'trix-attachment-remove': TrixEditorAttachmentEvent;
}

type TrixHTMLElement = HTMLElement & {
  editor: {
    loadHTML(html: string): void;
  };

  addEventListener<K extends keyof TrixEditorHTMLElementEventMap>(
    type: K,
    listener: (event: TrixEditorHTMLElementEventMap[K]) => void,
  ): void;

  removeEventListener<K extends keyof TrixEditorHTMLElementEventMap>(
    type: K,
    listener: (event: TrixEditorHTMLElementEventMap[K]) => void,
  ): void;
};

export type HTMLFormControlProps = Omit<React.HTMLAttributes<HTMLElement>, 'value' | 'onChange'> & {
  value: string;
  onChange(value: string): void;
};

const HTMLFormControlAttachment: React.FC<{
  attachment: TrixEditorAttachment;
}> = ({ attachment }) => {
  const { loaded, total } = useActiveStorageDirectUpload({
    onUpload: (blob: any) => {
      attachment.setAttributes({
        sgid: blob.attachable_sgid,
        url: `/rails/active_storage/blobs/redirect/${blob.signed_id}/${blob.filename}`,
      });
    },
    onError: () => {},
    file: attachment.file,
  });

  useEffect(() => {
    if (!loaded || !total) return;
    attachment.setUploadProgress((loaded / total) * 100.0);
  }, [loaded, total, attachment]);

  return null;
};

export const HTMLFormControl: React.FC<HTMLFormControlProps> = ({ value, onChange, ...props }) => {
  const trixHTMLElementRef = useRef<TrixHTMLElement>(null);
  const onChangeRef = useRef(onChange);
  const TrixEditor = createElement('trix-editor', { ...props, ref: trixHTMLElementRef });

  const [attachments, setAttachments] = useState<TrixEditorAttachment[]>([]);

  useEffect(() => {
    onChangeRef.current = onChange;
  }, [onChange]);

  useEffect(() => {
    const element = trixHTMLElementRef.current;
    if (!element || element.innerHTML === value) return;

    const editor = element.editor;
    editor.loadHTML(value);
  }, [value]);

  useEffect(() => {
    const element = trixHTMLElementRef.current;
    if (!element) return;

    const onChangeListener = (event: TrixEditorChangeEvent) => {
      onChangeRef.current(event.target.innerHTML);
    };

    const onAttachmentAddListener = (event: TrixEditorAttachmentEvent) => {
      setAttachments((current) => [...current, event.attachment]);
    };

    const onAttachmentRemoveListener = (event: TrixEditorAttachmentEvent) => {
      setAttachments((current) => current.filter((attachment) => attachment.id !== event.attachment.id));
    };

    element.addEventListener('trix-change', onChangeListener);
    element.addEventListener('trix-attachment-add', onAttachmentAddListener);
    element.addEventListener('trix-attachment-remove', onAttachmentRemoveListener);

    return () => {
      element.removeEventListener('trix-change', onChangeListener);
      element.removeEventListener('trix-attachment-add', onAttachmentAddListener);
      element.removeEventListener('trix-attachment-remove', onAttachmentRemoveListener);
    };
  }, []);

  return (
    <>
      {TrixEditor}

      {attachments.map((attachment) => (
        <HTMLFormControlAttachment key={attachment.id} attachment={attachment} />
      ))}
    </>
  );
};
