import { Panel } from '@admin/components/helpers/panel';
import { TeachInWarning } from '@admin/components/logistics/skus/teach_in_warning';
import { ROLES } from '@admin/config/roles';
import { accountURL, logisticsDetailedSKUURL } from '@admin/config/routes';
import { LoadTypeEnum, LogisticsLoadQuery } from '@admin/schema';
import { UserRole } from '@shared/types/user_role';
import styled from '@emotion/styled';
import { COLORS } from '@root/colors';
import React, { useState } from 'react';
import { intersection, lowerCase, startCase } from 'lodash';

const Link = styled.a`
  color: ${COLORS.blueLight};
`;

type LogisticsLoad = LogisticsLoadQuery['load'];

export const LogisticsLoadCargoDetailsPanel: React.FC<{ load: LogisticsLoad }> = ({ load }) => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  const accountLink = intersection(ROLES, [UserRole.EnterpriseManager, UserRole.Admin, UserRole.Manager]).length > 0;

  return (
    <Panel>
      <Panel.Header>
        <Panel.Collapse collapsed={collapsed} onChange={setCollapsed} />
        <Panel.Title>Cargo Details</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {!collapsed && load.fulfillmentExpectations && (
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th className="col-md-2">Customer</th>
                  <th className="col-md-3">SKU</th>
                  <th className="col-md-2">Purchase Order</th>
                  <th className="col-md-2">Expected Quantity</th>
                  <th className="col-md-1">
                    {load.type === LoadTypeEnum.Inbound ? `Ingested ` : `Assigned `} Quantity
                  </th>
                  <th className="col-md-2">Damaged Quantity</th>
                </tr>
              </thead>
              <tbody>
                {load.fulfillmentExpectations.map((expectation) => (
                  <tr style={{ color: expectation.quantity === 0 ? COLORS.red : 'inherit' }} key={expectation.id}>
                    <td className="col-md-2">
                      {accountLink && (
                        <Link href={accountURL({ id: expectation.sku.account.id })}>
                          {expectation.sku.account.customer.name}
                        </Link>
                      )}
                      {!accountLink && <span>{expectation.sku.account.customer.name}</span>}
                    </td>
                    <td className="col-md-3">
                      <Link href={logisticsDetailedSKUURL({ id: expectation.sku.id })}>{expectation.sku.value}</Link>
                      <br />
                      {startCase(lowerCase(expectation.sku.countingUnit))}
                      {expectation.sku.requiresTeachIn && (
                        <>
                          <br />
                          <TeachInWarning />
                        </>
                      )}
                    </td>
                    <td className="col-md-2">{expectation.purchaseOrder?.number}</td>
                    <td className="col-md-2">{expectation.quantity}</td>
                    <td className="col-md-1">
                      {load.type === LoadTypeEnum.Inbound && expectation.ingestedCount}
                      {load.type === LoadTypeEnum.Outbound && (
                        <span
                          style={{
                            color:
                              expectation.quantity !== expectation.preparedCount ||
                              expectation.quantity !== expectation.loadedCount
                                ? COLORS.red
                                : 'inherit',
                          }}
                        >
                          {' '}
                          Prepared {expectation.preparedCount}, Loaded {expectation.loadedCount}{' '}
                        </span>
                      )}
                    </td>
                    <td className="col-md-2">{expectation.damagedCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </Panel.Body>
    </Panel>
  );
};
