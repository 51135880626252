export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[];
  };
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Abeet__CurrentMembership: ['Account', 'WT__VisitorType'],
    AccountMutationInterface: ['Mutation'],
    AccountQueryInterface: ['Query'],
    AuctionMutationInterface: ['Mutation'],
    AuctionQueryInterface: ['Query'],
    AutomationMutationInterface: ['Mutation'],
    AutomationQueryInterface: ['Query'],
    AvailabilityMutationInterface: ['Mutation'],
    AvailabilityQueryInterface: ['Query'],
    BillingBillableInterface: ['Account'],
    BillingMutationInterface: ['Mutation'],
    BillingQueryInterface: ['Query'],
    CalculatePackageSetEntryAmountInterface: ['Query'],
    Changed__Audited: ['Changed__Customer', 'Changed__Phone', 'Changed__Record', 'LaborClock'],
    ClaimQueryInterface: ['Query'],
    ClaimsMutationInterface: ['Mutation'],
    Communication: ['Message', 'PhoneCall'],
    ComplianceQueryInterface: ['Query'],
    ContainersMutationInterface: ['Mutation'],
    CrmMutationInterface: ['Mutation'],
    CustomerTicketable: ['Account', 'Lead'],
    CustomerTicketsMutationInterface: ['Mutation'],
    CustomerTicketsQueryInterface: ['Query'],
    DockApiMutationInterface: ['Mutation'],
    DockApiQueryInterface: ['Query'],
    DropOffQueryInterface: ['Query'],
    DropOff__Fulfiller: ['DropOff__ThirdPartySelfStorageFacility', 'SelfStorage__Facility'],
    DropOff__StorageArrangement: ['DropOff__ThirdPartySelfStorageUnit', 'SelfStorage__Hold'],
    EmailEvent: ['IterableEvent', 'SendgridEvent'],
    EstimationMutationInterface: ['Mutation'],
    EstimationQueryInterface: ['Query'],
    Facility: ['Depot', 'Warehouse'],
    FeatureFlagWhitelistObject: ['Account', 'Region', 'User', 'Warehouse'],
    FieldApiMutationInterface: ['Mutation'],
    FieldApiQueryInterface: ['Query'],
    ForkliftApiQueryInterface: ['Query'],
    GenericCategory: ['Admin__ItemCategory', 'MaterialCategory', 'PartCategory'],
    HRSMutationInterface: ['Mutation'],
    HRSQueryInterface: ['Query'],
    HiringMutationInterface: ['Mutation'],
    HiringQueryInterface: ['Query'],
    History__Resource: ['Changed__Audit', 'LaborClock', 'MarketingEvent', 'Note', 'PhoneCall', 'Subscribe', 'Usage'],
    IncludedPart__Category: ['ItemCategory', 'MaterialCategory', 'PartCategory'],
    IncludedPart__Resource: ['Item', 'PartialItem'],
    IngestionMutationInterface: ['Mutation'],
    IngestionQueryInterface: ['Query'],
    InteractableUnion: ['PhoneCall'],
    LandingLocationMutationInterface: ['Mutation'],
    LandingLocationQueryInterface: ['Query'],
    LogisticsMutationInterface: ['Mutation'],
    LogisticsQueryInterface: ['Query'],
    Logistics__Fulfillment: ['Logistics__Load'],
    Logistics__OptimalPalletComposition: [
      'Logistics__OptimalPalletComposition__Composition',
      'Logistics__OptimalPalletComposition__Error',
    ],
    Logistics__Sku__BulkUploadPreview: [
      'Logistics__Sku__BulkUploadPreview__Error',
      'Logistics__Sku__BulkUploadPreview__Preview',
    ],
    MarketingMutationInterface: ['Mutation'],
    MarketingQueryInterface: ['Query'],
    MovingMutationInterface: ['Mutation'],
    MovingQueryInterface: ['Query'],
    NightTransportMutationInterface: ['Mutation'],
    NightTransportQueryInterface: ['Query'],
    NightTransport__DriveTask__VehicleSuggestion: ['Dispatch__VehiclePreference', 'Vehicle'],
    NightTransport__Task__Action: ['NightTransport__Carpool', 'NightTransport__Drive', 'NightTransport__Resupply'],
    OpsAuditMutationInterface: ['Mutation'],
    OpsAuditQueryInterface: ['Query'],
    OrderMutationInterface: ['Mutation'],
    OrderOrError: ['Error', 'Order'],
    OrderQueryInterface: ['Query'],
    PaymentPlanMutationInterface: ['Mutation'],
    PaymentPlanQueryInterface: ['Query'],
    PricingMutationInterface: ['Mutation'],
    PricingQueryInterface: ['Query'],
    RateAdjustmentMutationInterface: ['Mutation'],
    ReasonQueryInterface: ['Query'],
    RouteLocation: ['Landing__Location__City', 'Landing__Location__Neighborhood', 'Landing__Location__State'],
    SelfStorageMutationInterface: ['Mutation'],
    SelfStorageQueryInterface: ['Query'],
    StorageTreasuresMutationInterface: ['Mutation'],
    StorageTreasuresQueryInterface: ['Query'],
    TicketMutationInterface: ['Mutation'],
    TicketsQueryInterface: ['Query'],
    WorkforceMutationInterface: ['Mutation'],
    WorkforceQueryInterface: ['Query'],
  },
};
export default result;
