import * as React from 'react';

import { Formatter as CategoryFormatter } from '@admin/components/categories/formatter';

import { IItem } from '@admin/types/item';
import { IItemGroup } from '@admin/types/item_group';
import { Hero } from './hero';

export const SelectableBrick: React.FC<{
  selection: IItemGroup;
  selections?: IItem[];
  onSelect(selections: IItem[]): void;
}> = ({ selection, selections = [], onSelect, children }) => {
  const items = selection.items;
  const checked = selections.some(({ id }) => items.some((item) => item.id === id));

  const add = () => {
    onSelect([...selections, ...items]);
  };

  const remove = () => {
    onSelect(selections.filter(({ id }) => !items.some((item) => item.id === id)));
  };

  return (
    <div className="panel brick">
      <label className="brick-label">
        <span className="brick-badge brick-badge-dark">Group #{selection.id}</span>
        <figure className="brick-square">
          <Hero className="brick-image" width={480} height={480} selection={selection} />
        </figure>
        <div className="brick-metadata">
          <input type="checkbox" checked={checked} onChange={(event) => (event.target.checked ? add : remove)()} />{' '}
          <CategoryFormatter resource={selection} /> {children}
        </div>
      </label>
    </div>
  );
};
