import cn from 'classnames';
import React from 'react';

import { Categories } from '@admin/components/customer_tickets/categories';
import { PriorityPicker } from '@admin/components/customer_tickets/priority_picker';
import { TicketGroupPicker } from '@admin/components/customer_tickets/ticket_group_picker';
import { CustomerTicketFiltersInput } from '@admin/schema';
import { CustomerTicketMode } from '@admin/types/customer_ticket_filters_input';
import { CustomerTicketState } from '@admin/types/customer_ticket';
import { Nav } from '@shared/components/bootstrap';
import { ContactPreferencePicker } from '../contact_preference_picker';

type ALLOWED_MODES = CustomerTicketMode.All | CustomerTicketMode.Mine | CustomerTicketMode.Unassigned;

const TAB_NAMES = {
  [CustomerTicketMode.Unassigned]: 'Unassigned',
  [CustomerTicketMode.Mine]: 'Assigned to me',
  [CustomerTicketMode.All]: 'All',
};

const TABS: ALLOWED_MODES[] = [CustomerTicketMode.Unassigned, CustomerTicketMode.Mine, CustomerTicketMode.All];

const Tabs: React.FC<{
  selectedTab?: ALLOWED_MODES;
  setTab(mode: ALLOWED_MODES): void;
}> = ({ selectedTab, setTab }) => (
  <Nav format={Nav.Format.Tabs}>
    {TABS.map((tab) => (
      <Nav.Item key={tab} className={cn({ active: tab === selectedTab })}>
        <Nav.Link onClick={() => setTab(tab)}>{TAB_NAMES[tab]}</Nav.Link>
      </Nav.Item>
    ))}
  </Nav>
);

export const Filters: React.FC<{
  filters: CustomerTicketFiltersInput;
  setFilters(filters: CustomerTicketFiltersInput): void;
}> = ({ filters, setFilters }) => {
  const { mode, category, subCategory, state, groupID, assigneeID, priority, contactPreference } = filters;

  return (
    <>
      <Tabs
        selectedTab={mode as ALLOWED_MODES}
        setTab={(selected) => setFilters({ ...filters, groupID: '', assigneeID: '', mode: selected })}
      />
      <div className="mar-top row">
        <div className="col-md-2">
          <select
            className="form-control text-capitalize"
            value={state || ''}
            onChange={(e) => setFilters({ ...filters, state: e.currentTarget.value as CustomerTicketState })}
          >
            <option value="">-- State --</option>
            {[CustomerTicketState.Open, CustomerTicketState.Resolved].map((ticketState, key) => (
              <option key={key} value={ticketState}>
                {ticketState}
              </option>
            ))}
          </select>
        </div>
        <Categories
          className="col-md-2"
          selectedCategory={category || ''}
          selectedSubCategory={subCategory ?? undefined}
          showSubCategoryIfEmpty
          onChange={(newCategory, newSubCategory) =>
            setFilters({
              ...filters,
              category: newCategory,
              subCategory: newSubCategory,
            })
          }
        />
        {(mode === CustomerTicketMode.All || mode === CustomerTicketMode.Unassigned) && (
          <TicketGroupPicker
            className="col-md-2"
            selectedGroupID={groupID ?? undefined}
            selectedAssigneeID={assigneeID ?? undefined}
            showAssigneePicker={mode === CustomerTicketMode.All}
            onChange={(newGroupID, newAssigneeID) =>
              setFilters({
                ...filters,
                groupID: newGroupID,
                assigneeID: newAssigneeID,
              })
            }
          />
        )}
        <PriorityPicker
          className="col-md-2"
          priority={priority ?? undefined}
          onChange={(newPriority) =>
            setFilters({
              ...filters,
              priority: newPriority.length ? newPriority : undefined,
            })
          }
        />
        <ContactPreferencePicker
          className="col-md-2"
          contactPreference={contactPreference ?? undefined}
          onChange={(newContactPreference) =>
            setFilters({
              ...filters,
              contactPreference: newContactPreference.length ? newContactPreference : undefined,
            })
          }
        />
      </div>
    </>
  );
};
