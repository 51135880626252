import { IPlanDimensions } from '@admin/types/plan';
import { PlanFragment, PlanKindEnum, PricingGroupPricingEntryFragment, PricingSetFragment } from '@admin/schema';

export const DEFAULT_LENGTH = 1; // feet;
export const DEFAULT_HEIGHT = 4; // feet;
const DIMENSION_ROUNDING_FACTOR = 2; // i.e. round to .5 or .0

const DEFAULT_PROTECTION_PLAN_NAME = '$1000 Max. Coverage';

export const filteredPricingGroupEntries = (entries?: PricingGroupPricingEntryFragment[], rateGroupId?: string) => {
  if (!entries || entries.length === 0) {
    return [];
  }

  if (rateGroupId) {
    return entries.filter((entry) => entry.rateGroup?.id === rateGroupId);
  } else {
    const firstEntry = entries[0];
    return entries.filter((entry) => entry.rateGroup?.id === firstEntry.rateGroup?.id);
  }
};

export const isCustomPricingGroupEntry = ({ pricing: { plan } }: PricingGroupPricingEntryFragment) =>
  plan.kind === PlanKindEnum.Custom;

export const isCustomPlan = (plan: PlanFragment) => plan.kind === PlanKindEnum.Custom;

const isFixedPlan = (plan: PlanFragment) => plan.kind === PlanKindEnum.Fixed;

export const customPricingGroupEntry = (entries: PricingGroupPricingEntryFragment[]) =>
  entries.find(isCustomPricingGroupEntry);

const fixedPricingGroupEntries = (entries: PricingGroupPricingEntryFragment[]) =>
  entries.filter(({ pricing: { plan } }) => isFixedPlan(plan));

const defaultPricingGroupEntry = (entries: PricingGroupPricingEntryFragment[]) => {
  const entriesWithCUFT = fixedPricingGroupEntries(entries);
  if (!entriesWithCUFT.length) {
    return;
  }
  return entriesWithCUFT.reduce((entry, memo) => (entry.pricing.plan.cuft > memo.pricing.plan.cuft ? entry : memo));
};

const dimensionalPricingGroupEntry = (entries: PricingGroupPricingEntryFragment[], cuft: number) => {
  const entriesWithCUFT = fixedPricingGroupEntries(entries).filter(({ pricing: { plan } }) => plan.cuft >= cuft);
  if (!entriesWithCUFT.length) {
    return;
  }
  return entriesWithCUFT.reduce((entry, memo) => (entry.pricing.amount < memo.pricing.amount ? entry : memo));
};

export const dimensionalPlan = (plans: PlanFragment[], cuft: number) => {
  const entriesWithCUFT = plans.filter((plan) => plan.cuft >= cuft);
  if (!entriesWithCUFT.length) {
    return;
  }
  const standardDimensionalPlan = entriesWithCUFT.reduce((plan, memo) => (plan.cuft < memo.cuft ? plan : memo));

  return standardDimensionalPlan;
};

export const customPlan = (plans: PlanFragment[], cuft: number) => {
  const plan = plans.find(isCustomPlan)!;

  const dimensions = dimensionsForCuft(cuft);
  if (cuft && dimensions) {
    plan.name = `Custom ${dimensions.length}X${dimensions.width}`;
    plan.cuft = Math.round(dimensions.length * dimensions.width * dimensions.height);
  }

  return plan;
};

export const suggestedStoragePricingGroupEntry = (entries: PricingGroupPricingEntryFragment[], cuft: number) =>
  dimensionalPricingGroupEntry(entries, cuft) || customPricingGroupEntry(entries);

export const suggestedProtectionPricingGroupEntry = (entries: PricingGroupPricingEntryFragment[]) =>
  entries.find(
    ({
      pricing: {
        plan: { name },
      },
    }) => name === DEFAULT_PROTECTION_PLAN_NAME,
  );

export const dimensionsForCustomPricingGroupEntry = (
  entry?: PricingGroupPricingEntryFragment,
  cuft?: number,
): IPlanDimensions | undefined => {
  if (!cuft || !entry || !isCustomPricingGroupEntry(entry)) {
    return;
  }
  return dimensionsForCuft(cuft);
};

export const dimensionsForCuft = (cuft: number) => ({
  width: Math.round((cuft / (DEFAULT_LENGTH * DEFAULT_HEIGHT)) * DIMENSION_ROUNDING_FACTOR) / DIMENSION_ROUNDING_FACTOR,
  length: DEFAULT_LENGTH,
  height: DEFAULT_HEIGHT,
});

export const priceForCustomPricingGroupEntry = (
  entry?: PricingGroupPricingEntryFragment,
  length?: number,
  width?: number,
  ps?: PricingSetFragment,
) => {
  if (!entry || !length || !width || !ps || !isCustomPricingGroupEntry(entry)) {
    return;
  }
  const entries = filteredPricingGroupEntries(ps.storagePricingGroupEntries);
  const cuft = length * width * DEFAULT_HEIGHT;
  const suggestion = dimensionalPricingGroupEntry(entries, cuft) || defaultPricingGroupEntry(entries);
  if (!suggestion || !suggestion.pricing.plan.cuft) {
    return;
  }
  return Math.round((suggestion.pricing.amount / suggestion.pricing.plan.cuft) * cuft);
};
