import React from 'react';
import type { FC } from 'react';

import { client } from '@admin/libraries/apollo';
import { Hiring__Job__Input, Status, useHiringJobCreateMutation } from '@admin/schema';

import { HiringJobForm } from './form';

export const HiringJobNew: FC = () => {
  const [execute, { loading: saving, data }] = useHiringJobCreateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) window.location.href = '/hiring/roles';
    },
  });

  const save = (input: Hiring__Job__Input) => {
    execute({ variables: { input } });
  };

  const error = data?.result?.error;

  return <HiringJobForm save={save} saving={saving} error={error} />;
};
