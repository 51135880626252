import cn from 'classnames';
import { capitalize } from 'lodash';
import * as React from 'react';

import { Formatter as CategoryFormatter } from '@admin/components/categories/formatter';
import { Formatter as DimensionsFormatter } from '@admin/components/dimensions/formatter';

import { IItem } from '@admin/types/item';
import { Hero } from './hero';
import { Metadata } from './metadata';

export const SelectableBrick: React.FC<{
  selection: IItem;
  selections: IItem[];
  onSelect(selections: IItem[]): void;
}> = ({ selection, selections, onSelect }) => {
  const checked = selections.some(({ id }) => id === selection.id);

  const add = () => {
    onSelect([...selections, selection]);
  };

  const remove = () => {
    onSelect(selections.filter(({ id }) => id !== selection.id));
  };

  return (
    <div className={cn('panel brick', { disabled: selection.delayed })}>
      <label className="brick-label">
        {selection.latest_open_incident_kind && (
          <span className="brick-badge brick-badge-danger">{capitalize(selection.latest_open_incident_kind)}</span>
        )}

        <figure className="brick-square">
          <Hero className="brick-image" selection={selection} width={480} height={480} />
        </figure>
        <div className="brick-metadata">
          <input
            type="checkbox"
            checked={checked}
            disabled={selection.delayed}
            onChange={(event) => {
              (event.target.checked ? add : remove)();
            }}
          />{' '}
          <Metadata item={selection} />
          {!selection.group && (
            <div>
              <span className="label label-default">
                <CategoryFormatter resource={selection} fallback="No Category" />
              </span>
              <br />
              <span className="label label-info">
                <DimensionsFormatter resource={selection} fallback="No Dimensions" />
              </span>
            </div>
          )}
        </div>
      </label>
    </div>
  );
};
