import { intersection } from 'lodash';
import React from 'react';

import { SelectWithOther } from '@admin/components/helpers/select_with_other';
import { MovingOverrideLaborRateAmount } from '@admin/components/orders/moving/override_labor_rate/amount';
import { ROLES } from '@admin/config/roles';
import { UserRole } from '@shared/types/user_role';
import { Col, ControlLabel, FormGroup, HelpBlock, Row } from '@shared/components/bootstrap';

const OVERRIDE_REASONS = [
  'Too low on movers for this day',
  'Count is off based on plan size',
  'Item on this order requires more movers',
  'This is a warehouse order',
  'This is a 3PL order',
  'Route Optimization',
  'Order duration is too long',
  'Building type requirements',
  'Geo level requirements',
  'Order too challenging',
  'Order can be done with fewer movers',
];

export const MoversSharedFields: React.FC<{
  orderID?: string;
  orderType: string;
  overrideMovers: boolean;
  onOverrideMovers: (overrideMovers: boolean) => void;
  movers?: number;
  onMovers: (movers?: number) => void;
  moverOverrideReason?: string;
  onMoverOverrideReason: (moverOverrideReason?: string) => void;
  movingConfigurationID?: string;
}> = ({
  orderID,
  orderType,
  overrideMovers,
  onOverrideMovers,
  movers,
  onMovers,
  moverOverrideReason,
  onMoverOverrideReason,
  movingConfigurationID,
}) => {
  const showOverrideMovers =
    orderID &&
    orderType !== 'selfstorage-shuttle' &&
    intersection(ROLES, [UserRole.Admin, UserRole.Dispatcher, UserRole.Manager]).length > 0;

  const disableMovers = !orderID || !overrideMovers || !showOverrideMovers;

  return (
    <FormGroup className="movers" inline={true}>
      <ControlLabel className="col-sm-2" htmlFor="order_movers">
        Movers
      </ControlLabel>
      <Col sm={4} className="col-auto">
        {showOverrideMovers && (
          <>
            <ControlLabel htmlFor="order_movers_overwritten_at">Override</ControlLabel>
            <input
              id="order_movers_overwritten_at"
              type="checkbox"
              checked={overrideMovers}
              style={{ margin: '0 5px' }}
              onChange={(e) => onOverrideMovers(e.target.checked)}
            />
          </>
        )}
        <input
          id="order_movers"
          type="number"
          className="form-control"
          value={movers ?? ''}
          onChange={(e) => onMovers(Number(e.target.value) || undefined)}
          min="1"
          max="9"
          disabled={disableMovers}
        />
      </Col>
      {showOverrideMovers && (
        <Col sm={4}>
          <SelectWithOther
            option={moverOverrideReason}
            options={OVERRIDE_REASONS}
            placeholder="Select Reason for Mover Override"
            onSelect={onMoverOverrideReason}
            disabled={disableMovers}
          />
        </Col>
      )}
      {showOverrideMovers && !disableMovers && movers !== undefined && movingConfigurationID && (
        <Col sm={2}>
          <MovingOverrideLaborRateAmount movers={movers} movingConfigurationID={movingConfigurationID} />
        </Col>
      )}
      {showOverrideMovers && orderType === 'moving-move' && (
        <Row>
          <Col sm={9} className="col-sm-offset-2">
            <HelpBlock className="text-danger">
              <strong>Warning:</strong> Manual Mover changes are allowed only 48 hours before a scheduled move. If you
              are changing Movers within 48 hours of a scheduled move, the Customer must be notified and has to approve
              the change!
              <br />
              <strong>Use with Caution</strong>: Mover selection should be done only by qualified Dispatch and
              Eng-Product personnel.
            </HelpBlock>
          </Col>
        </Row>
      )}
    </FormGroup>
  );
};
