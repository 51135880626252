import styled from '@emotion/styled';
import * as React from 'react';
import { COLORS } from '@root/colors';

import { Roles } from '@admin/components/helpers/roles';
import { StorageTreasures__AuctionStatus, useActiveAuctionOrderQuery } from 'admin/schema';
import { client } from '@admin/libraries/apollo';
import { UserRole } from '@shared/types/user_role';
import { Timestamp } from '@shared/components/helpers';

const Banner = styled.div`
  background: ${COLORS.yellow};
  margin-bottom: 10px;
  display: block;
  font-size: 16px;
  padding: 10px;
`;

export const AuctionAccountBanner: React.FC<{
  accountID: string;
}> = ({ accountID }) => {
  const queryData = useActiveAuctionOrderQuery({ client, variables: { accountID } }).data;
  const auctionOrder = queryData?.activeAuctionOrder;
  const upcomingStorageTreasuresAuction = queryData?.account.storageTreasuresAuctionsHeld.find(
    (auction) => auction.status === StorageTreasures__AuctionStatus.Active,
  );
  const auctionDate = auctionOrder?.scheduled || upcomingStorageTreasuresAuction?.startTime;

  return (
    <>
      {auctionDate && (
        <Banner>
          Auction Account for Auction on <Timestamp value={auctionDate} format={Timestamp.Format.DateTime} />
          <Roles show={[UserRole.L2Agent, UserRole.Care, UserRole.BillingAdmin]}>
            , please transfer to AR if available. Read all account notes before speaking to the customer.
          </Roles>
        </Banner>
      )}
    </>
  );
};
