import { Panel } from '@admin/components/helpers/panel';
import { client } from '@admin/libraries/apollo';
import { StorageTreasures__AuctionStatus, useStorageTreasuresAuctionListFiltersFormDataQuery } from '@admin/schema';
import { Col, Row } from '@shared/components/bootstrap';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { Form as FormikForm, Formik } from 'formik';
import React from 'react';
import { FormikDatePickerFormGroup } from '@admin/components/fields/formik/formik_date_picker_form_group';
import { capitalize } from 'lodash';

export type AuctionFilterValues = {
  status?: StorageTreasures__AuctionStatus;
  warehouseID?: string;
  minInvoiceAmount?: string;
  date?: string;
  search?: string;
};

export const AuctionFilters: React.FC<{
  filters: AuctionFilterValues;
  onChangeFilters: (formValues: AuctionFilterValues) => void;
}> = ({ filters, onChangeFilters }) => {
  const { data } = useStorageTreasuresAuctionListFiltersFormDataQuery({ client });
  const warehouses = data?.warehouses || [];

  return (
    <Formik<AuctionFilterValues> initialValues={filters} onSubmit={() => {}} enableReinitialize>
      {({ values }) => (
        <FormikForm>
          <Panel>
            <div style={{ padding: '25px' }}>
              <Row className="mb-4">
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikInputFormGroup
                    id="search"
                    name="search"
                    label="Search"
                    value={values.search}
                    placeholder="Search by auctioned account name, winner name, or Storage Treasures ID"
                    onChange={(e) =>
                      onChangeFilters({ ...filters, search: e.target.value === '' ? undefined : e.target.value })
                    }
                  />
                </Col>
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikSelectFormGroup
                    id="status"
                    name="status"
                    label="Status"
                    value={values.status}
                    onChange={(e) =>
                      onChangeFilters({
                        ...filters,
                        status: e.target.value === '' ? undefined : (e.target.value as StorageTreasures__AuctionStatus),
                      })
                    }
                  >
                    <option value="">All Statuses</option>
                    {Object.values(StorageTreasures__AuctionStatus).map((status) => (
                      <option key={status} value={status}>
                        {capitalize(status)}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                </Col>
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikDatePickerFormGroup
                    id="date"
                    name="date"
                    label="Date"
                    onChange={(e) =>
                      onChangeFilters({ ...filters, date: e.target.value === '' ? undefined : e.target.value })
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikSelectFormGroup
                    id="warehouseID"
                    name="warehouseID"
                    label="Warehouse"
                    value={values.warehouseID}
                    onChange={(e) =>
                      onChangeFilters({
                        ...filters,
                        warehouseID: e.target.value === '' ? undefined : e.target.value,
                      })
                    }
                  >
                    <option value="">All Warehouses</option>
                    {warehouses.map((warehouse) => (
                      <option key={warehouse.id} value={warehouse.id}>
                        {warehouse.name}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                </Col>
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikInputFormGroup
                    id="minInvoiceAmount"
                    name="minInvoiceAmount"
                    label="Minimum Invoice Amount"
                    value={values.minInvoiceAmount}
                    type="number"
                    onChange={(e) =>
                      onChangeFilters({
                        ...filters,
                        minInvoiceAmount: e.target.value === '' ? undefined : e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
          </Panel>
        </FormikForm>
      )}
    </Formik>
  );
};
