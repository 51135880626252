import { IInvoiceActionFormProps } from '@admin/components/billing/invoices/types';
import { ROLES } from '@admin/config/roles';
import { UserRole } from '@shared/types/user_role';
import { Billing__OfflinePayment__Kind, useBillingInvoiceMarkAsPaidMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { pollForInvoiceEvent } from '@admin/utils/poll_for_invoice_event';

import { Button, Text } from '@shared/components/bootstrap';
import { intersection } from 'lodash';
import React, { useState } from 'react';

import {
  AmountFormGroup,
  DueDateFormGroup,
  KindFormGroup,
  NotesFormGroup,
  TransactionNumberFormGroup,
} from '@admin/components/billing/offline_payments';

export const InvoiceMarkAsPaidForm: React.FC<IInvoiceActionFormProps> = ({
  invoiceID,
  accountID,
  onSave,
  onCancel,
  amountOwed,
}) => {
  const [amount, setAmount] = useState<number | undefined>(amountOwed);
  const [newDueDate, setNewDueDate] = useState<string | undefined>();
  const [notes, setNotes] = useState<string | undefined>('');
  const [kind, setKind] = useState<Billing__OfflinePayment__Kind | undefined>();
  const [transactionNumber, setTransactionNumber] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [polling, setPolling] = useState<boolean>(false);

  const [markAsPaid, { loading }] = useBillingInvoiceMarkAsPaidMutation({ client });

  const disabledPartialPayment =
    intersection(ROLES, [UserRole.SelfStorageAssociate, UserRole.Admin, UserRole.Billing]).length === 0;

  const requiredTransactionNumber =
    kind !== Billing__OfflinePayment__Kind.Other && kind !== Billing__OfflinePayment__Kind.Cash;

  const requiredDueDate = amount !== amountOwed;

  const disabled =
    !amount || (requiredDueDate && !newDueDate) || !notes || !kind || (requiredTransactionNumber && !transactionNumber);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (
      loading ||
      !amount ||
      (requiredDueDate && !newDueDate) ||
      !notes ||
      !kind ||
      (!transactionNumber && requiredTransactionNumber)
    ) {
      return;
    }

    if (amount < 0 || amount > amountOwed) {
      setError('Amount must be greater than 0 and less than or equal to the amount due');
      return;
    }

    const response = await markAsPaid({
      variables: {
        invoiceID,
        input: {
          notes,
          amount,
          kind,
          transactionNumber,
          newDueDate,
        },
      },
    });

    if (response && response.data) {
      setPolling(true);
      await pollForInvoiceEvent(accountID, response.data.result.attemptID);
      setPolling(false);
      onSave(response.data.result.attemptID);
    } else {
      setError('Unknown error occurred. Please try again later.');
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <AmountFormGroup value={amount} onChange={setAmount} disabled={disabledPartialPayment} />
      {amount !== amountOwed && !disabledPartialPayment && (
        <DueDateFormGroup value={newDueDate} onChange={setNewDueDate} />
      )}
      <KindFormGroup
        kind={kind}
        onSelect={(value) => {
          if (value === Billing__OfflinePayment__Kind.Cash) {
            setTransactionNumber(undefined);
          }
          setKind(value);
        }}
      />
      {kind !== Billing__OfflinePayment__Kind.Cash && (
        <TransactionNumberFormGroup
          required={requiredTransactionNumber}
          value={transactionNumber}
          onChange={setTransactionNumber}
        />
      )}
      <NotesFormGroup value={notes} onChange={setNotes} />
      {error && (
        <Text tag="p" style="danger" alignment="center">
          <strong>{error}</strong>
        </Text>
      )}
      <Text alignment="right" tag="div">
        <Button kind="danger" disabled={loading || polling} onClick={onCancel}>
          Cancel
        </Button>{' '}
        <Button kind="primary" type="submit" loading={loading || polling} disabled={disabled}>
          Pay
        </Button>
      </Text>
    </form>
  );
};
