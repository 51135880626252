import * as React from 'react';

import { client } from '@admin/libraries/apollo';

import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { UserRole } from '@shared/types/user_role';
import { AnchorButton, Button, Table, Text } from '@shared/components/bootstrap';

import {
  LogisticsSkusForPanelQuery,
  useLogisticsSkusForPanelQuery,
  Logistics__SkuTeachInStatusEnum,
  useReteachLogisticsSkuMutation,
  LogisticsSkusForPanelDocument,
} from '@admin/schema';
import { logisticsEditSKUURL, logisticsNewSKUURL, logisticsDetailedSKUURL } from '@admin/config/routes';
import { TeachInWarning } from './teach_in_warning';

const SkusTable: React.FC<{ data: LogisticsSkusForPanelQuery; accountID: string; page?: number }> = ({
  data,
  accountID,
  page,
}) => {
  const [executeReteach, { loading: loadingReteach }] = useReteachLogisticsSkuMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: LogisticsSkusForPanelDocument, variables: { accountID: accountID, page: page } }],
  });

  if (!data.paginated.pagination.totalCount) {
    return <Text tag="p">This account does not have any 3PL SKUs.</Text>;
  }

  return (
    <Table responsive striped>
      <thead>
        <tr>
          <th className="text-left col-md-1">Value</th>
          <th className="text-left col-md-2">Description</th>
          <th className="text-left col-md-1">Has Unique Barcodes</th>
          <th className="text-right col-md-1" />
        </tr>
      </thead>
      <tbody>
        {data.paginated.results.map((sku) => (
          <tr key={sku.id}>
            <td className="text-left col-md-1">
              {sku.value}
              {sku.requiresTeachIn && (
                <>
                  {' '}
                  <TeachInWarning />
                </>
              )}
            </td>
            <td className="text-left col-md-2">{sku.description}</td>
            <td className="text-left col-md-1">{sku.hasBarcode.toString()}</td>
            <td className="text-right col-md-1">
              <a className="btn btn-primary" href={logisticsDetailedSKUURL({ id: sku.id })}>
                View
              </a>{' '}
              {sku.teachInStatus === Logistics__SkuTeachInStatusEnum.Taught && (
                <Roles show={[UserRole.Admin, UserRole.EnterpriseManager]}>
                  <Button
                    kind="warning"
                    type="submit"
                    onClick={() => executeReteach({ variables: { id: sku.id } })}
                    loading={loadingReteach}
                  >
                    Reteach
                  </Button>{' '}
                </Roles>
              )}
              <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
                <a className="btn btn-primary" href={logisticsEditSKUURL({ id: sku.id })}>
                  Edit
                </a>
              </Roles>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const LogisticsSkusPanel: React.FC<{ accountID: string }> = ({ accountID }) => {
  const [page, setPage] = React.useState<number | undefined>();
  const { data, loading } = useLogisticsSkusForPanelQuery({ client, variables: { page, accountID } });

  const pagination = data &&
    data.paginated && {
      __typename: 'Pagination' as const,
      currentPage: data.paginated.pagination.currentPage,
      nextPage: data.paginated.pagination.nextPage || undefined,
      prevPage: data.paginated.pagination.prevPage || undefined,
      totalPages: data.paginated.pagination.totalPages,
      totalCount: data.paginated.pagination.totalCount,
    };

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>SKUs</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        {loading && <Spinner />}
        {data && <SkusTable data={data} accountID={accountID} page={page} />}
        {pagination && <Pagination pagination={pagination} onPage={setPage} />}
      </Panel.Body>
      <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
        <Panel.Footer>
          <Text alignment="right" tag="div">
            <AnchorButton kind="primary" href={logisticsNewSKUURL({ accountID })}>
              New SKU
            </AnchorButton>
          </Text>
        </Panel.Footer>
      </Roles>
    </Panel>
  );
};
