import * as React from 'react';

import { IItem } from '@admin/types/item';

export const Metadata: React.FC<{
  item: IItem;
}> = ({ item }) => (
  <>
    <span>#{item.barcode.value}</span> <strong>({item.state})</strong>
  </>
);
