import { Panel } from '@admin/components/helpers/panel';
import { IDispatch } from '@admin/types/dispatch';
import { IOrderFilterInput } from '@admin/types/order';
import { client } from '@admin/libraries/apollo';
import React from 'react';
import { useQuery } from '@apollo/client';
import { DispatchDocument } from '@admin/schema';
import { TimeZoneContext } from '../context';
import { Legend } from './legend';
import { OrderGantt } from './order_gantt';

interface IQueryData {
  dispatch: Pick<IDispatch, 'region' | 'id' | 'arrival' | 'cutoff' | 'clockout' | 'orders'>;
}

interface IQueryVariables {
  dispatchID: string;
  orderFilterInput?: IOrderFilterInput;
}

export const OrderEvents: React.FC<{ dispatchID: string }> = ({ dispatchID }) => {
  const { data, loading } = useQuery<IQueryData, IQueryVariables>(DispatchDocument, {
    client,
    variables: { dispatchID, orderFilterInput: { external: true, forWarehouse: false } },
  });

  if (!data || loading) {
    return null;
  }

  return (
    <TimeZoneContext.Provider value={data.dispatch.region!.tz}>
      <Panel>
        <Panel.Header>
          <Panel.Title>Timeline</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          {data && (
            <OrderGantt
              arrival={data.dispatch.arrival}
              orders={data.dispatch.orders}
              cutoff={data.dispatch.cutoff}
              clockout={data.dispatch.clockout}
            />
          )}
        </Panel.Body>
        <Panel.Footer>
          <Legend />
        </Panel.Footer>
      </Panel>
    </TimeZoneContext.Provider>
  );
};
