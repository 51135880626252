import * as React from 'react';

import { ICategory } from '@shared/types/category';
import { IPackaging } from '@admin/types/packaging';

interface ICategorized {
  custom_category_name?: string;
  sizing_selection?: string;
  packaging?: IPackaging;
  category?: ICategory;
}

export const Formatter: React.FC<{
  resource: ICategorized;
  fallback?: string;
}> = ({ resource, fallback }) => {
  if (!resource) {
    return fallback ? <>{fallback}</> : null;
  }
  const { category, packaging } = resource;

  if (!!packaging && !category) {
    if (packaging.material_category_name) {
      return <>{packaging.material_category_name}</>;
    } else if (packaging.part_category_name) {
      return <>{packaging.part_category_name}</>;
    }
  }

  if (!category) {
    return fallback ? <>{fallback}</> : null;
  }

  if (resource.custom_category_name) {
    return <>{resource.custom_category_name} (Custom)</>;
  }
  if (!resource.sizing_selection) {
    return <>{category.name}</>;
  }

  let summary = resource.sizing_selection;
  if (category.sizing_metric && category.sizing_metric.unit) {
    summary += `-${category.sizing_metric.unit}`;
  }

  return (
    <>
      {category.name} ({summary})
    </>
  );
};
