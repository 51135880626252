import styled from '@emotion/styled';

import { ROLES } from '@admin/config/roles';
import { UserRole } from '@shared/types/user_role';
import { DateTime } from 'luxon';
import React from 'react';
import { OrderSubtypeEnum, OrderTypeEnum } from '@admin/schema';
import { capitalize } from 'lodash';

const LinkButtonGroup = styled.div`
  padding: 16px;
`;

const LinkButton = styled.a`
  margin: 2px;
`;

interface OperatingScheduleTemplate {
  id: string;
  effectiveDate: string;
  operatesSunday: boolean;
  operatesMonday: boolean;
  operatesTuesday: boolean;
  operatesWednesday: boolean;
  operatesThursday: boolean;
  operatesFriday: boolean;
  operatesSaturday: boolean;
  orderType?: OrderTypeEnum | null;
  orderSubtype?: OrderSubtypeEnum | null;
}

export const OperatingSchedule: React.FC<{
  operatingScheduleTemplates: OperatingScheduleTemplate[];
  manageHref: string;
}> = ({ operatingScheduleTemplates, manageHref }) => {
  const editAccess = ROLES.includes(UserRole.Admin);

  function jobTypeDisplayName(orderType: OrderTypeEnum, orderSubtype: OrderSubtypeEnum) {
    if (orderType === OrderTypeEnum.Move) {
      return 'Move';
    }

    return `${capitalize(orderSubtype)} ${capitalize(orderType)}`;
  }

  const showEffectiveDate = !operatingScheduleTemplates.every(
    (template) => template.effectiveDate === operatingScheduleTemplates[0].effectiveDate,
  );

  const showJobType = !operatingScheduleTemplates.every((template) => !template.orderType && !template.orderSubtype);

  return (
    <>
      <div className="panel">
        {editAccess && (
          <div className="panel-heading">
            <LinkButtonGroup className="text-right">
              <LinkButton href={manageHref} className="btn btn-primary">
                Manage Operating Schedule
              </LinkButton>
            </LinkButtonGroup>
          </div>
        )}

        <div className="panel-body">
          <div>
            <table className="table">
              <thead>
                <tr>
                  {showEffectiveDate && <th>Effective Date</th>}
                  {showJobType && <th>Job Type</th>}
                  <th>Sunday</th>
                  <th>Monday</th>
                  <th>Tuesday</th>
                  <th>Wednesday</th>
                  <th>Thursday</th>
                  <th>Friday</th>
                  <th>Saturday</th>
                </tr>
              </thead>
              <tbody>
                {operatingScheduleTemplates.map((template) => (
                  <tr key={template.id}>
                    {showEffectiveDate && <td>{DateTime.fromISO(template.effectiveDate).toISODate()}</td>}
                    {showJobType && (
                      <td>
                        {template.orderType &&
                          template.orderSubtype &&
                          jobTypeDisplayName(template.orderType, template.orderSubtype)}
                      </td>
                    )}
                    <td>{template.operatesSunday ? 'OPEN' : 'CLOSED'}</td>
                    <td>{template.operatesMonday ? 'OPEN' : 'CLOSED'}</td>
                    <td>{template.operatesTuesday ? 'OPEN' : 'CLOSED'}</td>
                    <td>{template.operatesWednesday ? 'OPEN' : 'CLOSED'}</td>
                    <td>{template.operatesThursday ? 'OPEN' : 'CLOSED'}</td>
                    <td>{template.operatesFriday ? 'OPEN' : 'CLOSED'}</td>
                    <td>{template.operatesSaturday ? 'OPEN' : 'CLOSED'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
