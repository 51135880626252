import { depotsURL } from '@admin/config/routes';
import { useBuildDepotMutation, DepotInput } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { useState } from 'react';
import * as React from 'react';
import { DepotForm } from './depot_form';

const DEFAULT_DEPOT_INPUT: DepotInput = {
  name: '',
  abbreviation: '',
  active: false,
  address: {
    aptsuite: '',
    street: '',
    city: '',
    state: '',
    country: 'United States',
    zip: '',
  },
  regionIDs: [],
  tz: '',
};

export const CreateDepot: React.FC = () => {
  const [depot, setDepot] = useState<DepotInput>(DEFAULT_DEPOT_INPUT);
  const [error, setError] = useState<string | null | undefined>();
  const [execute, { loading }] = useBuildDepotMutation({ client });

  const onSave = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (loading) {
      return;
    }
    const variables = { input: depot };
    const response = await execute({ variables });
    if (response?.data) {
      setError(response.data.buildDepot.error);
      if (!response.data.buildDepot.error) {
        setDepot(DEFAULT_DEPOT_INPUT);
        window.location.href = depotsURL();
      }
    }
  };

  return (
    <div className="panel">
      <div className="panel-heading">
        <h3 className="panel-title">Add Depot</h3>
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
      >
        <div className="panel-body">
          <DepotForm depot={depot} onChange={setDepot} />
        </div>
        <div className="panel-footer text-right">
          <button type="button" className="btn btn-primary" disabled={loading} onClick={onSave}>
            Save
          </button>
        </div>
      </form>
    </div>
  );
};
