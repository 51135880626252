import { client } from '@admin/libraries/apollo';
import { Hiring__JobListing__Input, Status, useHiringJobListingCreateMutation } from '@admin/schema';
import React from 'react';
import type { FC } from 'react';
import { HiringJobListingForm } from './form';

export const HiringJobListingNew: FC = () => {
  const [execute, { loading: saving, data }] = useHiringJobListingCreateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) window.location.href = '/hiring/job_listings';
    },
  });

  const save = (input: Hiring__JobListing__Input) => {
    execute({ variables: { input } });
  };

  const error = data?.result?.error;

  return <HiringJobListingForm save={save} saving={saving} error={error} />;
};
