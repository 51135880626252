import styled from '@emotion/styled';
import React from 'react';

import { client } from '@admin/libraries/apollo';
import { useUsersDetailsQuery, History__Subject, UserNotificationPreferenceEnum } from '@admin/schema';
import { TicketTicketableType } from '@admin/types/ticketable';
import { UserRole } from '@shared/types/user_role';

import { CreateTicket } from '@admin/components/tickets/tech_support/create_ticket';
import { Formatter } from '@admin/components/phone/formatter';
import { HistoriesPanel } from '@admin/components/histories/panel';
import { GenerateTestPassword } from '@admin/components/qa_automation/generate_test_password';
import { AnchorButton as Link, Breadcrumb, Col, Row } from '@shared/components/bootstrap';
import { Roles } from '@admin/components/helpers/roles';
import { Label } from '@admin/components/helpers/label';
import { Panel } from '@admin/components/helpers/panel';
import { Map as CheckinsMap } from '@admin/components/checkins/map';
import { SendGridStatusButton } from '@admin/components/sendgrid/send_grid_status_button';

import { ROLES } from '@admin/config/roles';
import { Activity } from './activity';
import { Avatar } from './avatar';
import { ForceLeaveCallCenter } from './force_leave_call_center';
import { Role } from './role';
import { ExternalGroup } from './external_group';

export const PREFERENCE_TO_LABEL = {
  [UserNotificationPreferenceEnum.PersonalEmail]: 'Personal email',
  [UserNotificationPreferenceEnum.PersonalPhone]: 'SMS',
  [UserNotificationPreferenceEnum.PersonalEmailAndPhone]: 'SMS + personal email',
  [UserNotificationPreferenceEnum.WorkEmailOnly]: 'Work email only',
};

const Labels = styled.span`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 4px;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Details: React.FC<{ id: string }> = ({ id }) => {
  const { data } = useUsersDetailsQuery({ client, variables: { id } });
  const user = data?.user;

  const editable =
    ROLES.includes(UserRole.Admin) ||
    (user?.allRolesEditableByManager &&
      (ROLES.includes(UserRole.Manager) || ROLES.includes(UserRole.EnterpriseManager)));

  const allowedToGenerateTestPassword = ROLES.includes(UserRole.Admin);

  return (
    <>
      <Roles show={[UserRole.Admin, UserRole.Manager, UserRole.EnterpriseManager, UserRole.L2Agent]}>
        <div className="pull-right">
          <CreateTicket ticketable={{ id, type: TicketTicketableType.User, displayName: user?.name }} />
        </div>
      </Roles>

      <div className="page-header text-overflow">
        <Navigation>
          <Breadcrumb>
            <Breadcrumb.Item>
              <a href="/users">Users</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item active>{user?.name ?? '-'}</Breadcrumb.Item>
          </Breadcrumb>
        </Navigation>
      </div>

      <Panel>
        <Panel.Header>
          <Panel.Title>Details</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          <Row>
            <Col md={3}>{user && <Avatar user={user} />}</Col>
            <Col md={9}>
              <p>
                <strong>Teal ID:</strong> {user?.id ?? '-'}
              </p>
              <p>
                <strong>Workday User ID:</strong> {user?.workdayUserID ?? '-'}
              </p>
              <p>
                <strong>Name:</strong> {user?.name ?? '-'}
              </p>
              <p>
                <strong>Email:</strong> {user ? <a href={`mailto:${user.email}`}>{user.email}</a> : <span>-</span>}
              </p>
              {user?.personalEmail && (
                <p>
                  <strong>Personal Email:</strong> {<a href={`mailto:${user.personalEmail}`}>{user.personalEmail}</a>}
                </p>
              )}
              <p>
                <strong>Notification Preferences:</strong>{' '}
                {user?.notificationPreference ? PREFERENCE_TO_LABEL[user.notificationPreference] : '-'}
              </p>
              <p>
                <strong>Referral Code:</strong> {user?.promoCode.value ?? '-'}
              </p>
              <p>
                <strong>Phone:</strong> {user?.phone ? <Formatter number={user.phone} /> : <span>-</span>}
              </p>
              <p>
                <strong>Roles:</strong>{' '}
                {user ? (
                  <Labels>
                    {user.roles.map((role, index) => (
                      <Role key={index} role={role} />
                    ))}
                  </Labels>
                ) : (
                  <span>-</span>
                )}
              </p>
              <p>
                <strong>Team:</strong> {user?.team ? <Label kind="default">{user.team.name}</Label> : <span>-</span>}
              </p>
              {user?.externalGroup && (
                <p>
                  <strong>External Group:</strong> <ExternalGroup externalGroup={user.externalGroup} />
                </p>
              )}
              <p>
                <strong>Owned Teams:</strong>{' '}
                {user ? (
                  <Labels>
                    {user.ownedTeams.map((team) => (
                      <Label key={team.id} kind="default">
                        {team.name}
                      </Label>
                    ))}
                  </Labels>
                ) : (
                  <span>-</span>
                )}
              </p>
              <p>
                <strong>Logins:</strong> <span>{user?.signInCount ?? '-'}</span>
              </p>
              <p>{user && <ForceLeaveCallCenter user={user} />}</p>
              {allowedToGenerateTestPassword && (
                <p>
                  <GenerateTestPassword resourceID={id} resourceType="User" />
                </p>
              )}
            </Col>
          </Row>
        </Panel.Body>
        <Panel.Footer align="right">
          <SendGridStatusButton email={user?.email} />

          {editable && (
            <Link kind="primary" href={`/users/${id}/edit`}>
              Edit
            </Link>
          )}
        </Panel.Footer>
      </Panel>

      <CheckinsMap id={id} type="users" />
      <Activity id={id} />
      <HistoriesPanel type={History__Subject.User} id={id} />
    </>
  );
};
