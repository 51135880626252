import React, { useEffect, useState } from 'react';

import { PaymentPlan__Offer__State as OfferState, usePaymentPlanOfferQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Roles } from '@admin/components/helpers/roles';
import { HistoriesPanel } from '@admin/components/histories/panel';
import { Spinner } from '@admin/components/spinner';
import { UserRole } from '@shared/types/user_role';
import { History__Subject } from '@admin/schema';
import { OfferChanges } from '@admin/types/payment_plan/offer_changes';
import { Button } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';

import { Attributes } from './attributes';
import { Breadcrumbs } from './breadcrumbs';
import { CancelButton } from './cancel_button';
import { Edit } from './edit';

const EDITABLE_STATES = [OfferState.Draft, OfferState.Approved];

export const Details: React.FC<{
  accountID: string;
  offerID: string;
}> = ({ accountID, offerID }) => {
  const [offerChanges, setOfferChanges] = useState<OfferChanges>({});
  const [editing, setEditing] = useState<boolean>(false);
  const { data, loading } = usePaymentPlanOfferQuery({ client, variables: { accountID, offerID } });

  useEffect(() => {
    if (!data || !data.offer) {
      return;
    }
    setOfferChanges({
      type: offer.type,
      oneTimeFee: offer.oneTimeFee,
      expiry: offer.expiry,
      storageTerm: offer.storageTerm,
      monthlyFee: offer.monthlyFee,
      monthlyFeeTerm: offer.monthlyFeeTerm,
    });
  }, [data]);

  if (loading) {
    return <Spinner />;
  }
  if (!data) {
    return <p>Offer could not be found.</p>;
  }

  const { offer } = data;

  const editable = EDITABLE_STATES.includes(offer.state);

  return (
    <>
      <Breadcrumbs account={offer.account} />
      <Panel>
        <Panel.Header>
          <Panel.Title>#{offer.id}</Panel.Title>
        </Panel.Header>
        <Panel.Body>
          {editing ? (
            <Edit
              accountID={accountID}
              offer={offer}
              offerChanges={offerChanges}
              setOfferChanges={setOfferChanges}
              finishEditing={() => setEditing(false)}
            />
          ) : (
            <>
              <Attributes offer={offer} />
              {editable && (
                <Roles show={[UserRole.Admin, UserRole.Collections]}>
                  <div className="text-right">
                    <CancelButton offerID={offer.id} />
                    <Button kind="primary" className="mar-lft" onClick={() => setEditing(true)}>
                      Edit
                    </Button>
                  </div>
                </Roles>
              )}
            </>
          )}
        </Panel.Body>
      </Panel>
      <HistoriesPanel id={offer.id} type={History__Subject.PaymentPlanOffer} />
    </>
  );
};
