import { IItem } from '@admin/types/item';
import qs from 'qs';

import { ENV, Environment } from '@shared/config/environment';

type ID = number | string;

const accountSubdomain: string = (window as any).clutter.account_subdomain;
const enterpriseSubdomain: string = (window as any).clutter.enterprise_subdomain;

/** Please keep this list sorted! */
export const accountItemURL = (accountID: ID, itemID: ID) => `/accounts/${accountID}/items/${itemID}`;
const domain = `clutter.${ENV === Environment.development ? 'test' : 'com'}`;
export const accountPortalURL = `https://${accountSubdomain}.${domain}`;
export const enterpriseAccountPortalURL = `https://${enterpriseSubdomain}.${domain}`;
export const accountsURL = () => '/accounts';
export const accountURL = ({ id }: { id: ID }) => `/accounts/${id}`;
export const addressViewURL = (accountID: string, addressID: string) => `/accounts/${accountID}/addresses/${addressID}`;
export const auctionUploadFormURL = () => '/auctions/new';
export const auctionsURL = () => '/auctions';
export const availabilityLaborAdjustmentListURL = () => '/availability_labor_adjustment';
export const availabilityLaborAdjustmentNewURL = () => '/availability_labor_adjustment/new';
export const availabilityRescheduleOffersListURL = () => '/availability_reschedule_offers';
export const availabilityRescheduleOffersNewURL = () => '/availability_reschedule_offers/new';
export const cancelSelfStorageRentalURL = ({ accountID }: { accountID: ID }) =>
  `/self_storage/rentals/cancel?accountID=${accountID}`;
export const claimURL = ({ id }: { id: ID }) => `/claims/${id}`;
export const createDepotURL = () => '/depots/new';
export const createPalletTypeURL = () => `/pallet_types/new`;
export const createVehicleTypeURL = () => `/vehicle_types/new`;
export const customerTicketURL = ({ id }: { id: ID }) => `/customer_tickets/${id}`;
export const depotURL = ({ id }: { id: ID }) => `/depots/${id}`;
export const depotsURL = () => '/depots';
export const detailSelfStorageReservationURL = ({ id }: { id: ID }) => `/self_storage/reservations/${id}`;
export const detailSelfStorageUnitURL = ({ id }: { id: ID }) => `/self_storage/units/${id}`;
export const dispatcherURL = ({ date, regionID }: { date: string; regionID: string }) =>
  `/dispatcher?date=${date}&region_id=${regionID}`;
export const dispatchesURL = (params: { region?: string; date?: string }) =>
  ['/dispatches', qs.stringify(params)].filter(Boolean).join('?');
export const dispatchURL = ({ id }: { id: ID }) => `/dispatches/${id}`;
export const editAccountMoveURL = ({ accountID, orderID }: { accountID: ID; orderID: ID }) =>
  `/accounts/${accountID}/moves/${orderID}/edit`;
export const editAccountPickupURL = ({ accountID, orderID }: { accountID: ID; orderID: ID }) =>
  `/accounts/${accountID}/pickups/${orderID}/edit`;
export const editAccountPricingURL = ({ accountID }: { accountID: ID }) => `/accounts/${accountID}/pricing/edit`;
export const editAccountRetailDeliveryURL = ({ accountID, orderID }: { accountID: ID; orderID: ID }) =>
  `/accounts/${accountID}/retail_deliveries/${orderID}/edit`;
export const editAccountReturnURL = ({ accountID, orderID }: { accountID: ID; orderID: ID }) =>
  `/accounts/${accountID}/returns/${orderID}/edit`;
export const editAccountShuttleURL = ({ accountID, orderID }: { accountID: ID; orderID: ID }) =>
  `/accounts/${accountID}/shuttles/${orderID}/edit`;
export const editAccountTaskOrderURL = ({ accountID, orderID }: { accountID: ID; orderID: ID }) =>
  `/accounts/${accountID}/task_orders/${orderID}/edit`;
export const editBillingSelfStorageRentalURL = ({ accountID }: { accountID: ID }) =>
  `/self_storage/edit_billing?accountID=${accountID}`;
export const editLeadURL = ({ id }: { id: ID }) => `/leads/${id}/edit`;
export const editPalletTypeURL = ({ id }: { id: ID }) => `/pallet_types/${id}/edit`;
export const editPaymentPlanOfferURL = ({ accountID, offerID }: { accountID: ID; offerID: ID }) =>
  `/accounts/${accountID}/payment_plans/${offerID}`;
export const editSelfStorageUnitURL = ({ id }: { id: ID }) => `/self_storage/units/${id}/edit`;
export const editTicketURL = ({ id }: { id: ID }) => `/tickets/${id}/edit`;
export const editVehicleTypeURL = ({ id }: { id: ID }) => `/vehicle_types/${id}/edit`;
export const globalUnavailabilityListURL = () => '/global_unavailability';
export const globalUnavailabilityNewURL = () => '/global_unavailability/new';
export const itemURL = (item: IItem) => `/accounts/${item.account_id}/items/${item.id}`;
export const jobAvailabilityListURL = () => '/job_availability';
export const jobAvailabilityNewURL = () => '/job_availability/new';
export const listSelfStorageReservationsURL = () => '/self_storage/reservations';
export const listSelfStorageUnitsURL = () => '/self_storage/units';
export const locationURL = (locationID: ID) => `/locations/${locationID}`;
export const logisticsDetailedLoadURL = ({ id }: { id: ID }) => `/logistics/loads/${id}`;
export const logisticsDetailedSKUURL = ({ id }: { id: ID }) => `/logistics/skus/${id}`;
export const logisticsEditLoadURL = ({ id }: { id: ID }) => `/logistics/loads/${id}/edit`;
export const logisticsEditPurchaseOrderURL = ({ accountID, id }: { accountID: ID; id: ID }) =>
  `/logistics/purchase_orders/${id}/edit?accountID=${accountID}`;
export const logisticsEditSKUURL = ({ id }: { id: ID }) => `/logistics/skus/${id}/edit`;
export const logisticsLoadBolURL = ({ id }: { id: ID }) => `/logistics/loads/${id}/bill_of_ladings`;
export const logisticsLoadPackingSlipURL = ({ id }: { id: ID }) => `/logistics/loads/${id}/packing_slips`;
export const logisticsLoadShippingLabelsURL = ({ id }: { id: ID }) => `/logistics/loads/${id}/shipping_labels`;
export const logisticsNewLoadsURL = () => `/logistics/loads/new`;
export const logisticsNewPurchaseOrderURL = ({ accountID }: { accountID: ID }) =>
  `/logistics/purchase_orders/new?accountID=${accountID}`;
export const logisticsNewSKUURL = ({ accountID }: { accountID: ID }) => `/logistics/skus/new?accountID=${accountID}`;
export const mapURL = ({ orderID, accountID }: { orderID: ID; accountID: ID }) =>
  `/map?order=${orderID}&account_id=${accountID}`;
export const newAccountMoveFollowUpURL = ({ accountID, orderID }: { accountID: ID; orderID: ID }) =>
  `/accounts/${accountID}/moves/${orderID}/follow_up/new`;
export const newAccountOrderShipURL = ({ accountID, orderID }: { accountID: ID; orderID: ID }) =>
  `/accounts/${accountID}/orders/${orderID}/ship/new`;
export const newAccountPickupURL = ({ accountID, external = true }: { accountID: string; external?: boolean }) =>
  `/accounts/${accountID}/pickups/new${external ? '' : '?external=false'}`;
export const newAccountReturnURL = ({
  accountID,
  warehouseReturn = false,
}: {
  accountID: string;
  warehouseReturn?: boolean;
}) => `/accounts/${accountID}/returns/new${warehouseReturn ? '?warehouse_return=true' : ''}`;
export const newAccountShuttleURL = ({ accountID, external = true }: { accountID: ID; external?: boolean }) =>
  `/accounts/${accountID}/self_storage_shuttles/new${external ? '' : '?external=false'}`;
export const newAccountTaskOrderURL = ({ accountID }: { accountID: string }) =>
  `/accounts/${accountID}/task_orders/new`;
export const newPaymentPlanOfferURL = ({ accountID }: { accountID: ID }) => `/accounts/${accountID}/payment_plans/new`;
export const newReferralURL = () => `/marketing/referrals/new`;
export const newSelfStorageRentalURL = ({ accountID }: { accountID?: ID } = {}) =>
  `/self_storage/rentals/new${accountID ? `?accountID=${accountID}` : ''}`;
export const newSelfStorageReservationURL = () => '/self_storage/reservations/new';
export const newTicketURL = () => '/tickets/new';
export const opsAuditAuditURL = (auditID: ID) => `/ops_audit/audits/${auditID}`;
export const opsAuditConfigurationEditURL = (id: ID) => `/ops_audit/configurations/${id}/edit`;
export const opsAuditConfigurationsAuditReviewURL = (configurationID: ID) =>
  `/ops_audit/configurations/${configurationID}/audit_review`;
export const opsAuditConfigurationsURL = () => `/ops_audit/configurations`;
export const orderURL = (order: { id: ID; account?: { id: ID } }) =>
  order.account ? `/accounts/${order.account.id}/orders/${order.id}` : `/orders/${order.id}`;
export const palletTypesURL = () => `/pallet_types`;
export const palletURL = (palletID: ID) => `/pallets/${palletID}`;
export const phonecallURL = ({ id }: { id: ID }) => `/phone_calls/${id}`;
export const regionEditURL = ({ id }: { id: ID }) => `/regions/${id}/edit`;
export const regionOperatingScheduleTemplateNewURL = () => '/regions/operating_schedule_templates/new';
export const regionsURL = () => '/regions';
export const regionURL = ({ id }: { id: ID }) => `/regions/${id}`;
export const ticketURL = ({ id }: { id: ID }) => `/tickets/${id}`;
export const trainingUploadAttestationsURL = (trainingUploadID: ID) =>
  `/training_uploads/${trainingUploadID}/attestations`;
export const userURL = ({ id }: { id: ID }) => `/users/${id}`;
export const vehicleTypesURL = () => `/vehicle_types`;
export const warehouseCapacityListURL = () => '/warehouse_capacity';
export const warehouseCapacityNewURL = () => '/warehouse_capacity/new';
export const warehouseOperatingScheduleTemplateNewURL = () => '/warehouses/operating_schedule_templates/new';
export const warehousesURL = () => '/warehouses';
export const warehouseLocationsURL = (warehouseID: ID) => `/warehouses/${warehouseID}/locations`;
export const warehouseOrderLeadTimesURL = (warehouseID: ID) => `/warehouses/${warehouseID}/order_lead_times`;
export const warehousePalletRequestsURL = (warehouseID: ID) => `/warehouses/${warehouseID}/pallet_requests`;
export const warehouseURL = ({ id }: { id: ID }) => `/warehouses/${id}`;
