import { DepotFragment, FacilityFragment, FacilityInput, FacilityInput__Kind, WarehouseFragment } from '@admin/schema';

export function encodeFacility(facility: FacilityFragment | WarehouseFragment | DepotFragment): string {
  return `${facility.__typename}/${facility.id}`;
}

export function decodeFacility(facilities: FacilityFragment[], encodedFacility: string): FacilityFragment | undefined {
  return facilities.find((facility) => encodeFacility(facility) === encodedFacility);
}

export function convertEncodedFacilityToFacilityInput(encodedFacility: string): FacilityInput {
  return {
    id: encodedFacility.split('/')[1],
    type: encodedFacility.split('/')[0] === 'Warehouse' ? FacilityInput__Kind.Warehouse : FacilityInput__Kind.Depot,
  };
}

export function convertFacilityFragmentToFacilityInput(facilityFragment: FacilityFragment): FacilityInput {
  return {
    id: facilityFragment.id,
    type: facilityFragment.__typename === 'Warehouse' ? FacilityInput__Kind.Warehouse : FacilityInput__Kind.Depot,
  };
}

export function facilityNameWithType(facility: FacilityFragment) {
  return `${facility.name} (${facility.__typename})`;
}
