import React from 'react';
import { Formik, Form as FormikForm, FieldArray } from 'formik';

import { Hiring__Question__Input, useHiringQuestionFormDataQuery } from '@admin/schema';

import { Alert, Button, Panel } from '@shared/components/bootstrap';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikMultiselectFormGroup } from '@admin/components/fields/formik/formik_multiselect_form_group';
import { client } from '@admin/libraries/apollo';
import { FormikCheckboxFormGroup } from '@admin/components/fields/formik/formik_checkbox_form_group';

type FormValues = {
  text: string;
  options: Array<{
    text: string;
    failing: boolean;
  }>;
  jobIDs: string[];
};

export const HiringQuestionForm: React.FC<{
  save(_: Hiring__Question__Input): void;
  defaults?: {
    text: string;
    options: Array<{
      text: string;
      failing: boolean;
    }>;
    jobIDs: string[];
  };
  saving?: boolean;
  error?: string | null;
}> = ({ save, saving, error, defaults }) => {
  const { data: formData } = useHiringQuestionFormDataQuery({ client });

  const onSubmit = (values: FormValues) => {
    save({
      text: values.text,
      options: values.options.map((opt) => opt.text),
      failingOptions: values.options.filter((opt) => opt.failing).map((opt) => opt.text),
      jobIDs: values.jobIDs,
    });
  };

  const jobOptions =
    formData?.hiringJobs.map((job) => ({
      id: job.id,
      name: job.title,
    })) ?? [];

  return (
    <Formik<FormValues>
      initialValues={{
        text: defaults?.text ?? '',
        options: defaults?.options ?? [],
        jobIDs: defaults?.jobIDs ?? [],
      }}
      onSubmit={onSubmit}
    >
      {({ values }) => (
        <FormikForm>
          <Panel>
            <Panel.Header style={{ padding: '20px' }}>
              <h4>{defaults ? 'Edit' : 'Create'} Question</h4>
            </Panel.Header>
            <Panel.Body>
              {error && <Alert style="danger">{error}</Alert>}

              <FormikInputFormGroup name="text" label="Question Text" />

              <FormikMultiselectFormGroup name="jobIDs" label="Jobs" entries={jobOptions} />

              <FieldArray
                name="options"
                render={(arrayHelpers) => (
                  <div>
                    {values.options.length > 0 && <label>Options</label>}
                    {values.options.map((option, index) => (
                      <div
                        key={index}
                        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px', gap: '24px' }}
                      >
                        <FormikInputFormGroup name={`options.${index}.text`} style={{ flex: 1 }} />
                        <FormikCheckboxFormGroup
                          name={`options.${index}.failing`}
                          label={'Failing Answer'}
                          style={{ marginLeft: '10px' }}
                        />
                        <Button kind="danger" onClick={() => arrayHelpers.remove(index)} style={{ marginLeft: '10px' }}>
                          Remove
                        </Button>
                      </div>
                    ))}
                    <Button
                      onClick={() => arrayHelpers.push({ text: '', failing: false })}
                      kind="primary"
                      style={{ marginTop: '10px' }}
                    >
                      Add Option
                    </Button>
                  </div>
                )}
              />
            </Panel.Body>
            <Panel.Footer align="right">
              <Button loading={saving} kind="primary" type="submit">
                Save
              </Button>
            </Panel.Footer>
          </Panel>
        </FormikForm>
      )}
    </Formik>
  );
};
