import React from 'react';
import { Formik, Form as FormikForm } from 'formik';

import { Hiring__Job__Input } from '@admin/schema';

import { Alert, Button, Panel } from '@shared/components/bootstrap';
import { FormikHTMLFormGroup } from '@admin/components/fields/formik/formik_html_form_group';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikCheckboxFormGroup } from '@admin/components/fields/formik/formik_checkbox_form_group';

type FormValues = {
  title: string;
  blurb: string;
  warehouse: boolean;
  field: boolean;
};

export const HiringJobForm: React.FC<{
  save(_: Hiring__Job__Input): void;
  defaults?: {
    title: string;
    trixHTML: string;
    warehouse: boolean;
    field: boolean;
  };
  saving?: boolean;
  error?: string | null;
}> = ({ save, saving, error, defaults }) => {
  const onSubmit = (values: FormValues) => {
    save(values);
  };

  return (
    <Formik<FormValues>
      initialValues={{
        title: defaults?.title ?? '',
        blurb: defaults?.trixHTML ?? '',
        warehouse: defaults ? defaults.warehouse : false,
        field: defaults ? defaults.field : false,
      }}
      onSubmit={onSubmit}
    >
      <FormikForm>
        <Panel>
          <Panel.Header style={{ padding: '20px' }}>
            <h4>{defaults ? 'Edit' : 'Create'} Role</h4>
          </Panel.Header>
          <Panel.Body>
            {error && <Alert style="danger">{error}</Alert>}

            <FormikInputFormGroup name="title" label="Title" />
            <FormikHTMLFormGroup name="blurb" label="Blurb" />
            <FormikCheckboxFormGroup name="warehouse" label="Warehouse" />
            <FormikCheckboxFormGroup name="field" label="Field" />
          </Panel.Body>
          <Panel.Footer align="right">
            <Button loading={saving} kind="primary" type="submit">
              Save
            </Button>
          </Panel.Footer>
        </Panel>
      </FormikForm>
    </Formik>
  );
};
