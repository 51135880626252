import { Roles } from '@admin/components/helpers/roles';
import { client } from '@admin/libraries/apollo';
import {
  Assignment,
  Assignment__Role,
  OrderLaborClocksDocument,
  Status,
  useCreateLaborClockMutation,
  User,
} from '@admin/schema';
import { UserRole } from '@shared/types/user_role';
import { Select } from '@clutter/clean';
import styled from '@emotion/styled';
import { Alert, Button } from '@shared/components/bootstrap';
import { DateTime } from 'luxon';
import React, { useState } from 'react';

const StyledAlert = styled.div`
  div {
    margin: 0px;
  }
`;

type AssignmentType = Pick<Assignment, 'role'> & {
  user: Pick<User, 'id' | 'name'>;
};

export const CreateRow: React.FC<{
  orderID: string;
  assignmentsWithoutLaborClocks: AssignmentType[] | undefined;
  defaultDate: DateTime | undefined;
  tz?: string;
  setEditing(editing: boolean): void;
}> = ({ orderID, assignmentsWithoutLaborClocks, tz, defaultDate, setEditing }) => {
  const timeZone = tz ?? DateTime.local().zoneName;
  const assignmentOptions =
    assignmentsWithoutLaborClocks?.map((assignment) => ({
      value: assignment.user.id,
      label: assignment.user.name,
    })) ?? [];

  const [error, setError] = useState<string | undefined>(undefined);
  const [assignment, setAssignment] = useState<AssignmentType>();
  const [endedTime, setEndedTime] = useState<string | undefined>();
  const [startedTime, setStartedTime] = useState<string | undefined>();
  const [executeAddSubmit, { loading }] = useCreateLaborClockMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: OrderLaborClocksDocument, variables: { orderID } }],
  });

  const setAssignmentFromUserID = (userID?: string) => {
    const newAssignment = assignmentsWithoutLaborClocks?.find((a) => a.user.id === userID);
    setAssignment(newAssignment);
  };

  const getEndedDateTime = (value: string | undefined) => {
    if (value && defaultDate) {
      const newDt = DateTime.fromISO(value, { zone: timeZone });
      return newDt.toISO();
    }
  };

  const getStartedDateTime = (value: string | undefined) => {
    if (value && defaultDate) {
      const newDt = DateTime.fromISO(value, { zone: timeZone });
      return newDt.toISO();
    }
  };

  async function save() {
    try {
      const input = {
        orderID,
        userID: assignment!.user.id,
        role: assignment!.role,
        started: getStartedDateTime(startedTime),
        ended: getEndedDateTime(endedTime),
      };
      const payload = await executeAddSubmit({ variables: { input } });
      const res = payload.data?.createLaborClock;
      if (res?.status === Status.Ok) {
        setError(undefined);
        setEditing(false);
      } else {
        setStartedTime(undefined);
        setEndedTime(undefined);
        setError(res?.error || undefined);
      }
    } catch (e) {
      setStartedTime(undefined);
      setEndedTime(undefined);
      setError('Sorry, something went wrong. Please try again or contact Tech Support.');
    }
  }

  const cancelEditing = () => {
    setStartedTime(undefined);
    setEndedTime(undefined);
    setEditing(false);
  };

  return (
    <tr>
      <td className="text-left col-sm-3">
        <Select
          name="user"
          placeholder="-"
          value={assignment?.user.id}
          options={assignmentOptions}
          onChange={(userID?: any) => setAssignmentFromUserID(userID)}
        />
      </td>
      <td className="text-left col-sm-1">
        {assignment ? (
          <span className={'label ' + (assignment.role === Assignment__Role.Lead ? 'label-primary' : 'label-default')}>
            {assignment.role.toLowerCase()}
          </span>
        ) : (
          '-'
        )}
      </td>
      <td className="text-left col-sm-2">
        <input
          type="datetime-local"
          value={startedTime}
          onChange={(event) => setStartedTime(event.currentTarget.value)}
        />
      </td>
      <td className="text-left col-sm-2">
        <input type="datetime-local" value={endedTime} onChange={(event) => setEndedTime(event.currentTarget.value)} />
      </td>
      <td className="text-left col-sm-1">{'-'}</td>
      <td className="text-right col-sm-3">
        <Roles show={[UserRole.Admin, UserRole.Dispatcher]}>
          {error && (
            <StyledAlert>
              <Alert style="danger" onClose={() => setError(undefined)}>
                {error}
              </Alert>
            </StyledAlert>
          )}
          <>
            <Button kind="danger" onClick={() => cancelEditing()} loading={loading}>
              Cancel
            </Button>{' '}
            <Button kind="primary" disabled={!assignment} onClick={() => save()} loading={loading}>
              Save
            </Button>
          </>
        </Roles>
      </td>
    </tr>
  );
};
