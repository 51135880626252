import * as React from 'react';
import { intersection } from 'lodash';
import { client } from '@admin/libraries/apollo';
import { useAdminDropOffAppointmentQuery } from '@admin/schema';

import { Panel } from '@admin/components/helpers/panel';
import { Button } from '@shared/components/bootstrap';
import { UserRole } from '@shared/types/user_role';
import { ROLES } from '@admin/config/roles';
import { StorageArrangementManager } from './storage_arrangement_manager';
import { Summary } from './manager/summary';
import { Thumbnails } from '../helpers/thumbnails';

const ALLOWED_MANAGEMENT_ROLES = [
  UserRole.Admin,
  UserRole.SelfStorageAssociate,
  UserRole.Dispatcher,
  UserRole.Manager,
  UserRole.L2Agent,
];
const allowedToManage = intersection(ROLES, ALLOWED_MANAGEMENT_ROLES).length !== 0;

export const DropOffManager: React.FC<{ orderID: string }> = ({ orderID }) => {
  const { data } = useAdminDropOffAppointmentQuery({
    client,
    variables: { orderID },
    fetchPolicy: 'network-only',
  });

  const dropOffAppointment = data?.dropOffAppointment;

  if (!dropOffAppointment) return null;

  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Drop Off Appointment</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Summary appointment={dropOffAppointment} />
        <StorageArrangementManager appointment={dropOffAppointment} />
        {dropOffAppointment.files.length > 0 && (
          <>
            <p>
              <strong>Photos: </strong>
            </p>
            <Thumbnails condensed attachments={dropOffAppointment.files} />
          </>
        )}
      </Panel.Body>
      <Panel.Footer>
        {allowedToManage && (
          <div className="text-right">
            <Button.Group>
              <Button kind="danger" disabled>
                Cancel
              </Button>
              <Button kind="primary" disabled>
                Complete
              </Button>
            </Button.Group>
          </div>
        )}
      </Panel.Footer>
    </Panel>
  );
};
