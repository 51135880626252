import { useField } from 'formik';
import React from 'react';
import { FieldFormGroupProps } from '@shared/components/fields/field_form_group';
import { FormikFieldFormGroup } from '@shared/components/fields/formik/formik_field_form_group';

// The date value should be a string in the format of yyyy-MM-dd (e.g. "2024-04-17")
export const FormikDatePickerFormGroup: React.FC<
  FieldFormGroupProps & { name: string; onChange?: React.ChangeEventHandler<HTMLInputElement> }
> = ({ help, label, name, id, onChange }) => {
  const [{ value }, _, { setValue }] = useField(name);

  return (
    <FormikFieldFormGroup name={name} help={help} label={label} id={id}>
      <input
        id={id}
        type="date"
        className="form-control"
        value={value}
        onChange={(event) => {
          setValue(event.target.value);
          onChange?.(event);
        }}
      />
    </FormikFieldFormGroup>
  );
};
