import { Panel } from '@admin/components/helpers/panel';
import { Pagination } from '@admin/components/pagination';
import { Spinner } from '@admin/components/spinner';
import { client } from '@admin/libraries/apollo';
import { useHiringJobApplicationsPaginatedQuery, Hiring__JobApplicationStatusEnum } from '@admin/schema';
import { Table, AnchorButton } from '@shared/components/bootstrap';
import React, { useState } from 'react';
import { getFacilityInput } from '@admin/components/shifts/utilities';
import { getStatusBadgeClass, getStatusDisplayText } from './status';
import { Filters, FormValues } from './filters';

export const HiringJobApplications: React.FC = () => {
  const [page, setPage] = useState<number | undefined>();
  const [formValues, setFormValues] = useState<FormValues>({});

  const { data, loading } = useHiringJobApplicationsPaginatedQuery({
    client,
    variables: {
      page,
      candidateSearch: formValues.candidateSearch,
      status: formValues.status as Hiring__JobApplicationStatusEnum,
      jobID: formValues.jobID,
      regionID: formValues.regionID,
      facility: formValues.encodedFacility ? getFacilityInput(formValues.encodedFacility) : undefined,
      interviewDate: formValues.interviewDate,
    },
  });

  const paginated = data?.paginated;

  const updatedFilters = (formikFormValues: FormValues) => {
    setFormValues(formikFormValues);
    setPage(undefined); // Reset to first page when filtering

    // refetch();
  };

  return (
    <>
      <Filters filters={formValues} onChangeFilters={updatedFilters} />

      <Panel>
        <Table responsive striped>
          <thead>
            <tr>
              <th>Candidate</th>
              <th>Job Listing</th>
              <th>Onsite Interview</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {paginated?.results.map((application) => (
              <tr key={application.id}>
                <td>
                  <div>
                    <strong>{application.candidate.name}</strong>
                  </div>
                  <div>{application.candidate.email}</div>
                  <div>{application.candidate.phone}</div>
                </td>
                <td>
                  <div>
                    <strong>{application.jobListing.job.title}</strong>
                  </div>
                  <div>{application.jobListing.facility.name}</div>
                  <div>
                    {application.jobListing.fullTime ? 'Full-Time' : 'Part-Time'}
                    {application.jobListing.nightShift ? ' • Night shift' : ''}
                  </div>
                  <div>${application.jobListing.hourlyRate}/hour</div>
                </td>
                <td>
                  {application.onsiteInterviews.length > 0 ? (
                    application.onsiteInterviews.map((interview, index) => (
                      <div key={interview.id}>
                        <div>
                          <div>
                            <strong>
                              {new Date(interview.scheduledStart).toLocaleDateString('en-US', {
                                timeZone: interview.facility.tz,
                                month: 'long',
                                day: 'numeric',
                                year: 'numeric',
                              })}
                            </strong>
                          </div>
                          <div>
                            {new Date(interview.scheduledStart).toLocaleTimeString('en-US', {
                              timeZone: interview.facility.tz,
                              hour: 'numeric',
                              minute: '2-digit',
                            }) +
                              ' - ' +
                              new Date(interview.scheduledEnd).toLocaleTimeString('en-US', {
                                timeZone: interview.facility.tz,
                                hour: 'numeric',
                                minute: '2-digit',
                              })}
                          </div>
                        </div>
                        <div>{interview.facility.name}</div>
                        <div>{interview.interviewer?.name && `Interviewer: ${interview.interviewer.name}`}</div>
                        <div>{interview.passedAt ? '✓ Passed' : interview.failedAt ? '✗ Failed' : ''}</div>
                        {interview.noShow && <div>No Show</div>}
                        {index < application.onsiteInterviews.length - 1 && <hr />}
                      </div>
                    ))
                  ) : (
                    <div>Not Scheduled</div>
                  )}
                </td>
                <td>
                  <span className={`badge ${getStatusBadgeClass(application.status)}`}>
                    {getStatusDisplayText(application.status)}
                  </span>
                </td>
                <td>
                  <AnchorButton href={`/hiring/job_applications/${application.id}/review`} kind="primary">
                    Review
                  </AnchorButton>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {paginated && <Pagination pagination={paginated.pagination} onPage={setPage} />}
        {loading && <Spinner />}
      </Panel>
    </>
  );
};
