import { Panel } from '@admin/components/helpers/panel';
import { Hiring__JobApplicationStatusEnum, useHiringJobApplicationsFiltersFormDataQuery } from '@admin/schema';
import { Col, Row } from '@shared/components/bootstrap';
import React from 'react';
import { encodeFacility, facilityNameWithType } from '@admin/utils/facilities';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { Formik, Form as FormikForm } from 'formik';
import { client } from '@admin/libraries/apollo';
import { FormikDatePickerFormGroup } from '@admin/components/fields/formik/formik_date_picker_form_group';
import { getStatusDisplayText } from './status';

export type FormValues = {
  regionID?: string;
  encodedFacility?: string;
  jobID?: string;
  status?: string;
  candidateSearch?: string;
  interviewDate?: string;
};

export const Filters: React.FC<{ filters: FormValues; onChangeFilters: (formValues: FormValues) => void }> = ({
  filters,
  onChangeFilters,
}) => {
  const { data: formData } = useHiringJobApplicationsFiltersFormDataQuery({ client });
  const jobs = formData?.hiringJobs || [];
  const regions = formData?.regions || [];
  const facilities = formData?.facilities || [];

  return (
    <Formik<FormValues> initialValues={filters} onSubmit={() => {}} enableReinitialize>
      {({ values }) => (
        <FormikForm>
          <Panel>
            <div style={{ padding: '25px' }}>
              <Row className="mb-4">
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikInputFormGroup
                    id="candidateSearch"
                    name="candidateSearch"
                    label="Candidate Search"
                    value={values.candidateSearch}
                    placeholder="Search by name, email, phone..."
                    onChange={(e) =>
                      onChangeFilters({
                        ...filters,
                        candidateSearch: e.target.value === '' ? undefined : e.target.value,
                      })
                    }
                  />
                </Col>
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikSelectFormGroup
                    id="status"
                    name="status"
                    label="Status"
                    value={values.status}
                    onChange={(e) =>
                      onChangeFilters({ ...filters, status: e.target.value === '' ? undefined : e.target.value })
                    }
                  >
                    <option value="">All Statuses</option>
                    {Object.values(Hiring__JobApplicationStatusEnum).map((statusValue) => (
                      <option key={statusValue} value={statusValue}>
                        {getStatusDisplayText(statusValue)}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                </Col>
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikSelectFormGroup
                    id="jobID"
                    name="jobID"
                    label="Job"
                    value={values.jobID}
                    onChange={(e) =>
                      onChangeFilters({ ...filters, jobID: e.target.value === '' ? undefined : e.target.value })
                    }
                  >
                    <option value="">All Jobs</option>
                    {jobs.map((job) => (
                      <option key={job.id} value={job.id}>
                        {job.title}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikSelectFormGroup
                    id="regionID"
                    name="regionID"
                    label="Region"
                    value={values.regionID}
                    onChange={(e) =>
                      onChangeFilters({
                        ...filters,
                        regionID: e.target.value === '' ? undefined : e.target.value,
                      })
                    }
                  >
                    <option value="">All Regions</option>
                    {regions.map((region) => (
                      <option key={region.id} value={region.id}>
                        {region.name}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                </Col>
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikSelectFormGroup
                    id="encodedFacility"
                    name="encodedFacility"
                    label="Facility"
                    value={values.encodedFacility}
                    onChange={(e) =>
                      onChangeFilters({
                        ...filters,
                        encodedFacility: e.target.value === '' ? undefined : e.target.value,
                      })
                    }
                  >
                    <option value="">All Facilities</option>
                    {facilities.map((facility) => (
                      <option key={encodeFacility(facility)} value={encodeFacility(facility)}>
                        {facilityNameWithType(facility)}
                      </option>
                    ))}
                  </FormikSelectFormGroup>
                </Col>
                <Col md={4} style={{ paddingLeft: '20px', paddingRight: '20px' }}>
                  <FormikDatePickerFormGroup
                    id="interviewDate"
                    name="interviewDate"
                    label="Interview Date"
                    onChange={(e) =>
                      onChangeFilters({
                        ...filters,
                        interviewDate: e.target.value === '' ? undefined : e.target.value,
                      })
                    }
                  />
                </Col>
              </Row>
            </div>
          </Panel>
        </FormikForm>
      )}
    </Formik>
  );
};
