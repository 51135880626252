import styled from '@emotion/styled';
import * as React from 'react';

import { accountURL } from '@admin/config/routes';
import { Roles } from '@admin/components/helpers/roles';
import { Spinner } from '@admin/components/spinner';
import { UserRole } from '@shared/types/user_role';
import { client } from '@admin/libraries/apollo';
import { Spacer } from '@shared/components/helpers';

import {
  Logistics__PurchaseOrderInput,
  useLogisticsPurchaseOrderQuery,
  useAccountBreadCrumbsQueryQuery,
} from '@admin/schema';
import { LogisticsPurchaseOrderForm, DEFAULT_ADDRESS } from './form/form';
// import { LogisticsPurchaseOrdersQuery, useLogisticsPurchaseOrdersQuery } from '@admin/schema';
import { AccountBreadcrumbs } from '../breadcrumbs/account_breadcrumbs';

const LargeFont = styled.div`
  font-size: 2.5em;
`;

export const LogisticsEditPurchaseOrder: React.FC<{ accountID: string; id: string }> = ({ accountID, id }) => {
  const account = useAccountBreadCrumbsQueryQuery({ client, variables: { accountID } }).data?.account;
  const purchaseOrder = useLogisticsPurchaseOrderQuery({ client, variables: { id } }).data?.purchaseOrder;

  const onSubmit = async () => {
    window.location.href = accountURL({ id: accountID });
  };

  if (!purchaseOrder) {
    return <Spinner />;
  }

  const formData: Logistics__PurchaseOrderInput = {
    accountID,
    id: purchaseOrder.id,
    number: purchaseOrder.number,
    fulfillmentMethod: purchaseOrder.fulfillmentMethod,
    shipToAddress:
      (purchaseOrder.shipTo && {
        street: purchaseOrder.shipTo.street,
        aptsuite: purchaseOrder.shipTo.aptsuite,
        city: purchaseOrder.shipTo.city,
        state: purchaseOrder.shipTo.state,
        zip: purchaseOrder.shipTo.zip,
        country: purchaseOrder.shipTo.country,
        latitude: purchaseOrder.shipTo.latitude,
        longitude: purchaseOrder.shipTo.longitude,
        businessName: purchaseOrder.shipTo.businessName,
      }) ??
      DEFAULT_ADDRESS,
    fulfillmentExpectations: purchaseOrder.fulfillmentExpectations.map((fe) => ({
      fulfillmentExpectationID: fe.id,
      skuID: fe.sku.id,
      quantity: fe.quantity,
    })),
    phoneNumber: purchaseOrder.phoneNumber,
  };

  return (
    <Roles show={[UserRole.Admin, UserRole.EnterpriseManager, UserRole.Manager]}>
      {account && (
        <LargeFont>
          <AccountBreadcrumbs account={account} newItem={`Edit Purchase Order ${purchaseOrder.number}`} />
        </LargeFont>
      )}
      <Spacer height="10px" />
      <LogisticsPurchaseOrderForm formData={formData} onSave={onSubmit} />
    </Roles>
  );
};
