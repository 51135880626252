import React, { useState, useEffect } from 'react';
import {
  Day,
  HiringOnsiteInterviewTimeSlotsQuery,
  useHiringOnsiteInterviewFacilitiesQuery,
  useHiringOnsiteInterviewTimeSlotDestroyMutation,
  useHiringOnsiteInterviewTimeSlotsQuery,
  useHiringOnsiteInterviewCandidateInstructionsUpdateMutation,
} from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { decodeFacility, encodeFacility, facilityNameWithType } from '@admin/utils/facilities';
import { Spinner } from '@admin/components/spinner';
import { getFacilityInput } from '@admin/components/shifts/utilities';
import { Panel } from '@admin/components/helpers/panel';
import { Spacer } from '@shared/components/helpers';
import { ConfirmModal } from '@admin/components/helpers/confirm_modal';
import { ROLES } from '@admin/config/roles';
import { UserRole } from '@shared/types/user_role';
import { Button } from '@shared/components/bootstrap';
import { CreateTimeSlotModal } from './create_modal';

type TimeSlot = HiringOnsiteInterviewTimeSlotsQuery['hiringOnsiteInterviewTimeSlots'][0];

export const HiringOnsiteInterviewTimeSlotsCalendar: React.FC<{
  preSelectedEncodedFacility?: string;
}> = ({ preSelectedEncodedFacility }) => {
  const {
    data: facilitiesData,
    loading: loadingFacilities,
    refetch: refetchFacilities,
  } = useHiringOnsiteInterviewFacilitiesQuery({ client });
  const [encodedFacility, setEncodedFacility] = useState<string | undefined>(preSelectedEncodedFacility);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Day | undefined>(undefined);
  const [confirmModal, setConfirmModal] = useState<{ message: string; onConfirm: () => void } | undefined>(undefined);
  const [deleteTimeSlot] = useHiringOnsiteInterviewTimeSlotDestroyMutation({ client });
  const [isEditingInstructions, setIsEditingInstructions] = useState(false);
  const [candidateInstructions, setCandidateInstructions] = useState<string>('');
  const [updateCandidateInstructions] = useHiringOnsiteInterviewCandidateInstructionsUpdateMutation({ client });

  const editAccess =
    ROLES.includes(UserRole.Admin) || ROLES.includes(UserRole.Manager) || ROLES.includes(UserRole.OpsLeadership);

  const {
    data: timeSlotsData,
    loading: loadingTimeSlots,
    refetch: refetchTimeSlots,
  } = useHiringOnsiteInterviewTimeSlotsQuery({
    client,
    variables: { facility: getFacilityInput(encodedFacility)! },
    skip: !encodedFacility,
  });

  const timeSlots = timeSlotsData?.hiringOnsiteInterviewTimeSlots;

  const getTimeSlotsByDay = () => {
    if (!timeSlots) return undefined;

    const timeSlotsByDay: Record<Day, TimeSlot[]> = {
      [Day.Sunday]: [],
      [Day.Monday]: [],
      [Day.Tuesday]: [],
      [Day.Wednesday]: [],
      [Day.Thursday]: [],
      [Day.Friday]: [],
      [Day.Saturday]: [],
    };

    timeSlots.forEach((timeSlot) => {
      if (timeSlotsByDay[timeSlot.day]) {
        timeSlotsByDay[timeSlot.day].push(timeSlot);
      }
    });

    // Sort time slots within each day
    Object.keys(timeSlotsByDay).forEach((day) => {
      timeSlotsByDay[day as Day].sort((a, b) => new Date(a.startTime).getTime() - new Date(b.startTime).getTime());
    });

    return timeSlotsByDay;
  };

  const handleAddTimeSlot = (day: Day) => {
    setSelectedDay(day);
    setIsCreateModalOpen(true);
  };

  const handleCloseModal = (refetchTimeSlotData: boolean) => {
    setIsCreateModalOpen(false);
    setSelectedDay(undefined);
    if (refetchTimeSlotData) {
      refetchTimeSlots();
    }
  };

  const handleDeleteTimeSlot = async (id: string) => {
    setConfirmModal({
      message:
        'Are you sure you want to delete this time slot? Any interviews already scheduled will not be affected, but new interviews will no longer be booked for this time slot.',
      onConfirm: async () => {
        await deleteTimeSlot({ variables: { id } });
        refetchTimeSlots();
        setConfirmModal(undefined);
      },
    });
  };

  const timeSlotsByDay = getTimeSlotsByDay();
  const selectedFacility =
    facilitiesData && encodedFacility && decodeFacility(facilitiesData.facilities, encodedFacility);
  const orderedDays = [Day.Sunday, Day.Monday, Day.Tuesday, Day.Wednesday, Day.Thursday, Day.Friday, Day.Saturday];

  // Update candidate instructions when facility changes
  useEffect(() => {
    if (selectedFacility && selectedFacility.candidateInterviewInstructions) {
      setCandidateInstructions(selectedFacility.candidateInterviewInstructions);
    } else {
      setCandidateInstructions('');
    }
  }, [selectedFacility]);

  const handleSaveInstructions = async () => {
    if (!selectedFacility) return;

    await updateCandidateInstructions({
      variables: {
        facility: getFacilityInput(encodedFacility)!,
        instructions: candidateInstructions,
      },
    });
    setIsEditingInstructions(false);
    refetchFacilities();
  };

  if (loadingFacilities) {
    return <Spinner />;
  }

  return (
    <>
      {!preSelectedEncodedFacility && (
        <>
          <Panel>
            <Panel.Body>
              <select
                id="facilitySelect"
                className="form-select form-select-sm"
                style={{ width: '280px' }}
                value={encodedFacility ?? ''}
                onChange={(e) => {
                  setEncodedFacility(e.target.value === '' ? undefined : e.target.value);
                }}
              >
                <option value=""> - Select Facility - </option>
                {facilitiesData?.facilities.map((facility) => (
                  <option key={encodeFacility(facility)} value={encodeFacility(facility)}>
                    {facilityNameWithType(facility)}
                  </option>
                ))}
              </select>
            </Panel.Body>
          </Panel>
          <Spacer />
        </>
      )}
      <Panel>
        <Panel.Body>
          {loadingTimeSlots && <Spinner />}

          {timeSlotsByDay && encodedFacility && (
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: '8px' }}>
              {orderedDays.map((day) => (
                <div key={day}>
                  <h6 style={{ textAlign: 'center' }}>{day}</h6>
                  <div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                      {timeSlotsByDay[day].map((timeSlot) => (
                        <div
                          key={timeSlot.id}
                          style={{
                            textAlign: 'center',
                            borderRadius: '8px',
                            padding: '4px',
                            backgroundColor: 'teal',
                            color: 'white',
                            position: 'relative',
                          }}
                        >
                          {timeSlot.formattedStartTime} - {timeSlot.formattedEndTime}
                          <div style={{ fontSize: '0.8em', marginTop: '4px' }}>
                            {timeSlot.candidateCapacity} Candidate Capacity
                          </div>
                          {editAccess && (
                            <Button
                              onClick={() => handleDeleteTimeSlot(timeSlot.id)}
                              style={{
                                position: 'absolute',
                                top: '-4px',
                                right: '0px',
                                color: 'white',
                                width: '20px',
                                height: '20px',
                              }}
                            >
                              &times;
                            </Button>
                          )}
                        </div>
                      ))}
                    </div>
                    {editAccess && (
                      <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <Button
                          size="sm"
                          outline={true}
                          kind="primary"
                          onClick={() => handleAddTimeSlot(day)}
                          style={{
                            borderRadius: '8px',
                          }}
                        >
                          Add Time Slot
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          )}
        </Panel.Body>
      </Panel>

      {selectedFacility && (
        <>
          <Spacer height="8px" />
          <h3>Candidate Interview Instructions</h3>
          <Panel>
            <Panel.Header>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '16px 16px 0 16px',
                }}
              >
                {editAccess && (
                  <div style={{ justifyContent: 'flex-end', display: 'flex', gap: '2px', width: '100%' }}>
                    <Button
                      kind="primary"
                      onClick={() => setIsEditingInstructions(!isEditingInstructions)}
                      style={{ marginBottom: '16px' }}
                    >
                      {isEditingInstructions ? 'Cancel Editing' : 'Edit Instructions'}
                    </Button>
                  </div>
                )}
              </div>
            </Panel.Header>
            <Panel.Body>
              <textarea
                className="form-control"
                value={candidateInstructions}
                onChange={(e) => setCandidateInstructions(e.target.value)}
                rows={5}
                disabled={!isEditingInstructions}
                placeholder="Enter instructions for candidates on how to access the facility, as well as any information they should know before arriving, such as what to bring."
              />
            </Panel.Body>
            {isEditingInstructions && (
              <Panel.Footer align="right">
                <Button type="button" kind="primary" onClick={handleSaveInstructions}>
                  Save
                </Button>
              </Panel.Footer>
            )}
          </Panel>
        </>
      )}

      {isCreateModalOpen && selectedFacility && selectedDay && (
        <CreateTimeSlotModal closeModal={handleCloseModal} day={selectedDay} facility={selectedFacility} />
      )}

      {confirmModal && <ConfirmModal {...confirmModal} onCancel={() => setConfirmModal(undefined)} />}
    </>
  );
};
