import axios from 'axios';

import { IEstimatedItem } from '@shared/types/estimated_item';
import { IEstimationCategory } from '@shared/types/estimation_category';
import { IEstimationSelection } from '@shared/types/estimation_selection';
import { IOrderInventory } from '@shared/types/order_inventory';

import { filterInventoryForSave } from '@shared/utils/filter_inventory_for_save';
import { PlanFragment } from '@admin/schema';

interface IMoverCountParams {
  storage_plan_id: number | string;
  movers_for_estimated_items: number;
  custom_cuft?: number;
  subtype?: string;
  service_type?: string;
  address_id?: number | string;
  estimated_cuft: number;
}

interface IMoverCountResponse {
  estimated_mover_count: number;
}

interface IEstimatedMoverCountParams {
  inventory: IOrderInventory;
  plan: PlanFragment;
  cuft: number;
  subtype?: string;
  addressID?: number | string;
}

const minMoversForExtraItems = (extraItems: IEstimatedItem[] | undefined) => {
  if (!extraItems || extraItems.length === 0) {
    return 1;
  }

  return Math.max(...extraItems.map((item) => item.estimatedMovers || 1));
};

const minMoversForEstimationCategories = (
  estimationSelections: Map<IEstimationCategory, Partial<IEstimationSelection>> | undefined,
) => {
  if (!estimationSelections) {
    return 1;
  }

  let minMovers = 1;

  for (const category of Array.from(estimationSelections.keys())) {
    const selection = estimationSelections.get(category)!;
    if (selection.quantity! > 0 && category.numMovers > minMovers) {
      minMovers = category.numMovers;
    }
  }

  return minMovers;
};

export const estimatedMoverCount = async ({
  inventory,
  plan,
  cuft,
  subtype,
  addressID,
}: IEstimatedMoverCountParams) => {
  // Only take into consideration customer requested movers if it is greater than 2.
  const moversRequested = inventory.requestedMovers && inventory.requestedMovers >= 2 ? 2 : 1;

  const filteredInventory = filterInventoryForSave(inventory);
  const unpackableEstimations = filteredInventory.unpackableEstimations;
  const moversForEstimatedItems = Math.max(
    minMoversForExtraItems(filteredInventory.extraItems),
    minMoversForEstimationCategories(unpackableEstimations),
    moversRequested,
  );

  const extraItemCuft = filteredInventory.extraItems?.reduce((sum, item) => sum + (item.cuft ?? 0), 0) ?? 0;
  const unpackableEstimationsCuft =
    (unpackableEstimations &&
      Array.from(unpackableEstimations.keys())?.reduce((sum, category) => {
        const selection: Partial<IEstimationSelection> = unpackableEstimations.get(category)!;
        return sum + category.cuft * (selection.quantity ?? 1);
      }, 0)) ??
    0;

  const estimatedCuft = extraItemCuft + unpackableEstimationsCuft;

  const params: IMoverCountParams = {
    storage_plan_id: plan.id,
    movers_for_estimated_items: moversForEstimatedItems,
    custom_cuft: cuft,
    subtype,
    address_id: addressID,
    estimated_cuft: estimatedCuft,
  };

  const { data } = await axios.post<IMoverCountResponse>('/onboarding_movers_estimations.json', params);
  return data.estimated_mover_count;
};
