import * as React from 'react';

import { IPagination as IGQLPagination } from '@shared/types/pagination';

const INITIAL_PAGE = 1;
const SURROUNDING_PAGES = 2; // i.e. how many pages to display on either side of the current page.

const getPageNumbers = (current: number, total: number): number[] => {
  const results = [];
  const from = Math.max(INITIAL_PAGE, current - SURROUNDING_PAGES);
  const till = Math.min(total, current + SURROUNDING_PAGES);
  for (let page = from; page <= till; page++) {
    results.push(page);
  }
  return results;
};

const PaginationButton: React.FC<{
  text: string;
  page?: number | null;
  currentPage: number;
  onPage(page: number): void;
}> = ({ text, page, currentPage, onPage }) => (
  <button
    className="btn btn-default btn-active-primary"
    onClick={(event) => {
      event.preventDefault();
      event.stopPropagation();
      if (page) {
        onPage(page);
      }
    }}
    disabled={currentPage === page}
  >
    {text}
  </button>
);

interface IPaginationProps {
  pagination: IGQLPagination;
  onPage(page: number): void;
}

// This is taken from admin/components but with only the GQLPagination interface.
// TODO: Have admin components that use GQL use this pagination instead of the admin specific one.
export const Pagination = ({ pagination, onPage }: IPaginationProps) => {
  const current = pagination.currentPage;
  const total = pagination.totalPages;
  const prev = pagination.prevPage;
  const next = pagination.nextPage;

  return (
    <>
      {total > INITIAL_PAGE && (
        <div className="text-center">
          <div className="pagination btn-group">
            <PaginationButton text="«" page={INITIAL_PAGE} currentPage={current} onPage={onPage} />
            <PaginationButton text="‹" page={prev} currentPage={current} onPage={onPage} />

            {getPageNumbers(current, total).map((page) => (
              <PaginationButton key={page} page={page} text={page.toString()} currentPage={current} onPage={onPage} />
            ))}

            <PaginationButton text="›" page={next} currentPage={current} onPage={onPage} />
            <PaginationButton text="»" page={total} currentPage={current} onPage={onPage} />
          </div>
        </div>
      )}
    </>
  );
};
