import { client } from '@admin/libraries/apollo';
import {
  Hiring__JobListing__Input,
  Status,
  useHiringJobListingDetailsQuery,
  useHiringJobListingUpdateMutation,
} from '@admin/schema';
import React from 'react';
import type { FC } from 'react';
import { encodeFacility } from '@admin/utils/facilities';
import { HiringJobListingForm } from './form';

export const HiringJobListingEdit: FC<{
  id: string;
}> = ({ id }) => {
  const { data: details } = useHiringJobListingDetailsQuery({ client, variables: { id } });
  const [execute, { loading: saving, data }] = useHiringJobListingUpdateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) window.location.href = '/hiring/job_listings';
    },
  });

  const save = (input: Hiring__JobListing__Input) => {
    execute({ variables: { id, input } });
  };

  const error = data?.result?.error;

  const defaults = details?.hiringJobListing && {
    jobID: details.hiringJobListing.job.id,
    regionID: details.hiringJobListing.region.id,
    encodedFacility: encodeFacility(details.hiringJobListing.facility),
    hourlyRate: details.hiringJobListing.hourlyRate,
    fullTime: details.hiringJobListing.fullTime ? 'true' : 'false',
    nightShift: details.hiringJobListing.nightShift ? 'true' : 'false',
  };

  if (!defaults) return null;

  return <HiringJobListingForm save={save} saving={saving} error={error} defaults={defaults} />;
};
