import React from 'react';
import { Formik, FormikErrors, Form as FormikForm } from 'formik';
import { Day, FacilityFragment, Status, useHiringOnsiteInterviewTimeSlotCreateMutation } from '@admin/schema';
import { client } from '@admin/libraries/apollo';
import { FormikInputFormGroup } from '@shared/components/fields/formik/formik_input_form_group';
import { Alert, Button, Panel, Modal } from '@shared/components/bootstrap';
import { FormikSelectFormGroup } from '@shared/components/fields/formik/formik_select_form_group';
import { convertFacilityFragmentToFacilityInput } from '@admin/utils/facilities';
import { FormikTimePickerFormGroup } from '@admin/components/fields/formik/formik_time_picker_form_group';

type FormValues = {
  startTime?: string;
  endTime?: string;
  candidateCapacity?: number;
};

interface CreateTimeSlotModalProps {
  closeModal: (refetchTimeSlotData: boolean) => void;
  day: Day;
  facility: FacilityFragment;
}

export const CreateTimeSlotModal: React.FC<CreateTimeSlotModalProps> = ({ closeModal, day, facility }) => {
  const [createTimeSlot, { loading, data }] = useHiringOnsiteInterviewTimeSlotCreateMutation({
    client,
    onCompleted: ({ result }) => {
      if (result?.status === Status.Ok) closeModal(true);
    },
  });

  const onSubmit = (values: FormValues) => {
    if (!values.startTime || !values.endTime || !values.candidateCapacity) return;

    createTimeSlot({
      variables: {
        input: {
          facility: convertFacilityFragmentToFacilityInput(facility),
          day: day,
          startTime: values.startTime,
          endTime: values.endTime,
          candidateCapacity: values.candidateCapacity,
        },
      },
    });
  };

  const validateInput = (values: FormValues): FormikErrors<FormValues> => {
    const formErrors: FormikErrors<FormValues> = {};

    if (!values.startTime) {
      formErrors.startTime = 'Please enter a start time';
    }

    if (!values.endTime) {
      formErrors.endTime = 'Please enter an end time';
    }

    if (values.startTime && values.endTime && values.startTime >= values.endTime) {
      formErrors.endTime = 'End time must be after start time';
    }

    if (!values.candidateCapacity) {
      formErrors.candidateCapacity = 'Please enter a candidate capacity';
    } else if (values.candidateCapacity <= 0) {
      formErrors.candidateCapacity = 'Candidate capacity must be greater than 0';
    }

    return formErrors;
  };

  return (
    <Modal onClose={() => closeModal(false)}>
      <Formik<FormValues>
        initialValues={{
          startTime: '',
          endTime: '',
          candidateCapacity: undefined,
        }}
        onSubmit={onSubmit}
        validate={validateInput}
      >
        {() => (
          <FormikForm>
            <Panel>
              <Panel.Body>
                {data?.result?.error && <Alert style="danger">{data.result.error}</Alert>}

                <FormikInputFormGroup label="Facility" name="facilityName" value={facility.name} disabled />

                <FormikSelectFormGroup label="Day" name="day" value={day} disabled>
                  <option value={Day.Sunday}>Sunday</option>
                  <option value={Day.Monday}>Monday</option>
                  <option value={Day.Tuesday}>Tuesday</option>
                  <option value={Day.Wednesday}>Wednesday</option>
                  <option value={Day.Thursday}>Thursday</option>
                  <option value={Day.Friday}>Friday</option>
                  <option value={Day.Saturday}>Saturday</option>
                </FormikSelectFormGroup>

                <FormikTimePickerFormGroup id="startTime" name="startTime" label={`Start Time (${facility.tz})`} />

                <FormikTimePickerFormGroup id="endTime" name="endTime" label={`End Time (${facility.tz})`} />

                <FormikInputFormGroup label="Candidate Capacity" name="candidateCapacity" type="number" min="1" />
              </Panel.Body>

              <Panel.Footer align="right">
                <Button kind="secondary" onClick={() => closeModal(false)}>
                  Cancel
                </Button>
                <Button loading={loading} kind="primary" type="submit">
                  Create Time Slot
                </Button>
              </Panel.Footer>
            </Panel>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
};
