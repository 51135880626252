import React, { useState } from 'react';

import { client } from '@admin/libraries/apollo';
import { Roles } from '@admin/components/helpers/roles';
import { Button } from '@shared/components/bootstrap';

import {
  Logistics__ParcelCarrierAccountInput,
  Logistics__ParcelCarrierEnum,
  ParcelCarrierAccountsDocument,
  Status,
  useBuildParcelCarrierAccountMutation,
} from '@admin/schema';
import { UserRole } from '@shared/types/user_role';

export const CreateParcelCarrierAccountRow: React.FC<{
  accountID: string;
  setBuilding: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string | undefined>>;
}> = ({ accountID, setBuilding, setError }) => {
  const [carrier, setCarrier] = useState<string>(Object.values(Logistics__ParcelCarrierEnum)[0]);
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [country, setCountry] = useState<string>('');
  const [postalCode, setPostalCode] = useState<string>('');
  const [executeBuildSubmit, { loading: buildLoading }] = useBuildParcelCarrierAccountMutation({
    client,
    awaitRefetchQueries: true,
    refetchQueries: [{ query: ParcelCarrierAccountsDocument, variables: { id: accountID } }],
  });

  const resetValues = () => {
    setCarrier('');
    setAccountNumber('');
    setCountry('');
    setPostalCode('');
  };

  const formatParcelAccount = () => {
    const parcelAccount: Logistics__ParcelCarrierAccountInput = {
      accountID: accountID,
      carrier: carrier,
      accountNumber: accountNumber,
      country: country,
      postalCode: postalCode,
    };
    return parcelAccount;
  };

  async function save() {
    try {
      const input = formatParcelAccount();
      const payload = await executeBuildSubmit({ variables: { input: input } });
      const res = payload.data?.buildParcelCarrierAccount;
      if (res?.status === Status.Ok) {
        setError(undefined);
      } else {
        resetValues();
        setError(res?.error || undefined);
      }
    } catch (error) {
      resetValues();
      setError('Sorry, something went wrong. Please try again or contact Tech Support.');
    } finally {
      setBuilding(false);
    }
  }

  return (
    <tr>
      <td className="text-left col-md-2">
        <select className="form-control" value={carrier} onChange={(event) => setCarrier(event.target.value)}>
          {Object.values(Logistics__ParcelCarrierEnum).map((value) => (
            <option key={value} value={value}>
              {value}
            </option>
          ))}
        </select>
      </td>
      <td className="text-left col-md-3">
        <input type="string" value={accountNumber} onChange={(event) => setAccountNumber(event.currentTarget.value)} />
      </td>
      <td className="text-left col-md-1">
        <input type="string" value={country} onChange={(event) => setCountry(event.currentTarget.value)} />
      </td>
      <td className="text-left col-md-2">
        <input type="string" value={postalCode} onChange={(event) => setPostalCode(event.currentTarget.value)} />
      </td>
      <td className="text-right col-md-4">
        <Roles show={[UserRole.Admin, UserRole.EnterpriseManager]}>
          <Button kind="primary" onClick={() => save()} loading={buildLoading}>
            Save
          </Button>
        </Roles>
      </td>
    </tr>
  );
};
