import { SerializedStyles } from '@emotion/react';
import { flatMap } from 'lodash';

import { Maybe } from '@admin/schema';
import { IOrderService } from '@shared/types/order';
import { goodAssessmentStyle, poorAssessmentStyle } from './utils';
import { CuftMetrics } from './cuft_metrics';

const POOR_PICKUP_CUFT_PER_HOUR = 40;
const GOOD_PICKUP_CUFT_PER_HOUR = 55;

const POOR_RETURN_CUFT_PER_HOUR = 120;
const GOOD_RETURN_CUFT_PER_HOUR = 155;

export const assessCuft = () => null;

const assessCuftPerHourStyle = (poorCuftPerHour: number, goodCuftPerHour: number) => (value: Maybe<number>) => {
  if (value === null) {
    return null;
  }

  if (value < poorCuftPerHour) {
    return poorAssessmentStyle;
  }

  if (value > goodCuftPerHour) {
    return goodAssessmentStyle;
  }

  return null;
};

export const assessPickupCuftPerHour = assessCuftPerHourStyle(POOR_PICKUP_CUFT_PER_HOUR, GOOD_PICKUP_CUFT_PER_HOUR);
export const assessReturnCuftPerHour = assessCuftPerHourStyle(POOR_RETURN_CUFT_PER_HOUR, GOOD_RETURN_CUFT_PER_HOUR);

export const orderCuftHeaders = (kind: 'Pickup' | 'Return', numOrders: number) =>
  flatMap(Array.from({ length: numOrders }), () => [`${kind} cuft processed`, `${kind} cuft per hour`]);

const scaledFloat = (value: Maybe<number>) => value && Number(value.toFixed(2));

const getCuft = ({ cuft, started }: IOrderService) => (started ? scaledFloat(cuft) : null);

const getEstimatedCuft = ({ estimatedCuft }: IOrderService) => scaledFloat(estimatedCuft);

const getCuftPerHourPerMover = ({ cuftPerHourPerMover, started }: IOrderService) =>
  started ? scaledFloat(cuftPerHourPerMover) : null;

const getEstimatedCuftPerHourPerMover = ({ estimatedCuftPerHourPerMover }: IOrderService) =>
  scaledFloat(estimatedCuftPerHourPerMover);

const getCuftMetricsFor =
  (assessCuftPerHour: (value: Maybe<number>) => Maybe<SerializedStyles>) =>
  (service: IOrderService): CuftMetrics => {
    const actualCuft = getCuft(service);
    const estimatedCuft = getEstimatedCuft(service);

    const actualCuftPerHour = getCuftPerHourPerMover(service);
    const estimatedCuftPerHour = getEstimatedCuftPerHourPerMover(service);

    return {
      actual: [
        {
          cuft: { value: actualCuft, assessment: null },
          cuftPerHour: { value: actualCuftPerHour, assessment: assessCuftPerHour(actualCuftPerHour) },
        },
      ],
      estimated: [
        {
          cuft: { value: estimatedCuft, assessment: null },
          cuftPerHour: { value: estimatedCuftPerHour, assessment: assessCuftPerHour(estimatedCuftPerHour) },
        },
      ],
    };
  };

export const getCuftMetricsForReturn = getCuftMetricsFor(assessReturnCuftPerHour);
export const getCuftMetricsForPickup = getCuftMetricsFor(assessPickupCuftPerHour);

export const formatNumber: (value: Maybe<number>) => Maybe<string> = (value) => {
  if (value === 0) {
    return '0';
  }

  return value ? String(value) : null;
};
