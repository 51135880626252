import styled from '@emotion/styled';
import React, { useEffect } from 'react';
import { FormContext, useForm, useFieldArray } from 'react-hook-form';

import { AddressForm } from '@admin/components/address/address_form';
import { Panel } from '@admin/components/helpers/panel';
import { Spacer } from '@shared/components/helpers';
import { client } from '@admin/libraries/apollo';
import { useLogisticsSkusForAccountQuery } from '@admin/schema';
import { Alert, Button } from '@shared/components/bootstrap';
import { IAddress } from '@shared/types/address';
import {
  Logistics__PurchaseOrderInput,
  Logistics__AddressInput,
  Status,
  useBuildLogisticsPurchaseOrderMutation,
  useModifyLogisticsPurchaseOrderMutation,
  Logistics__FulfillmentMethodTypeEnum,
  Logistics__ModifyPurchaseOrderPayload,
} from '@admin/schema';
import { Field, StyledRow } from './field';
import { FulfillmentExpectationFields } from './fulfillment_expectation_fields';

const Container = styled.div`
  width: 50%;
  margin: auto;
`;

const HR = styled.hr`
  margin: 20px 0px;
`;

const SectionTitle = styled.div`
  font-weight: 600;
  font-size: 22px;
`;

export const DEFAULT_ADDRESS = {
  street: '',
  aptsuite: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  businessName: '',
};

const FALLBACK_ERROR = 'Sorry, an unexpected error occurred. If the problem persists, contact Tech Support.';

function IAddressFromAddressInput(input: Logistics__AddressInput | null | undefined): IAddress | null | undefined {
  return (
    input && {
      street: input.street,
      aptsuite: input.aptsuite ?? undefined,
      city: input.city,
      state: input.state,
      country: input.country ?? undefined,
      zip: input.zip,
      latitude: input.latitude ?? undefined,
      longitude: input.longitude ?? undefined,
      businessName: input.businessName ?? undefined,
    }
  );
}

export const LogisticsPurchaseOrderForm: React.FC<{
  formData: Logistics__PurchaseOrderInput;
  onSave(id: string | undefined): void;
}> = ({ formData, onSave }) => {
  const [error, setError] = React.useState<string | undefined>(undefined);
  const form = useForm<Logistics__PurchaseOrderInput>({ defaultValues: formData });

  const {
    fields: fulfillmentFields,
    append: appendFulfillment,
    remove: removeFulfillment,
  } = useFieldArray({
    control: form.control,
    name: 'fulfillmentExpectations',
  });

  const [executeNewSubmit, { loading: newPurchaseOrderSaving }] = useBuildLogisticsPurchaseOrderMutation({ client });
  const [executeModifySubmit, { loading: modifyPurchaseOrderSaving }] = useModifyLogisticsPurchaseOrderMutation({
    client,
  });

  const skus = useLogisticsSkusForAccountQuery({ client, variables: { accountID: formData.accountID } }).data
    ?.logisticsSkus;

  useEffect(() => {
    form.register({ name: 'shipToAddress' });
    return () => {
      form.unregister('shipToAddress');
    };
  }, [form.register]);

  const checkForErrors = (purchaseOrderInput: Logistics__PurchaseOrderInput) => {
    if (
      purchaseOrderInput.fulfillmentMethod === Logistics__FulfillmentMethodTypeEnum.Parcel &&
      !(
        purchaseOrderInput.shipToAddress &&
        purchaseOrderInput.shipToAddress.street &&
        purchaseOrderInput.shipToAddress.country &&
        purchaseOrderInput.shipToAddress.city &&
        purchaseOrderInput.shipToAddress.state &&
        purchaseOrderInput.shipToAddress.zip
      )
    ) {
      form.setError('shipToAddress', 'required');
      return 'Please fill out ship to street, city, state, country, and zip fields completely';
    } else {
      form.clearError('shipToAddress');
    }

    if (purchaseOrderInput.fulfillmentExpectations?.some((fe) => !(fe.quantity && fe.skuID) || fe.skuID === '0')) {
      form.setError('fulfillmentExpectations', 'required');
      return 'Please fill out all fulfillment expectation fields';
    } else {
      const skuIdArray: string[] = purchaseOrderInput.fulfillmentExpectations?.map((fe) => fe.skuID) || [];
      const skuDict: { [key: string]: boolean } = {};
      let duplicates = false;
      skuIdArray.forEach((skuID) => {
        if (skuDict[skuID] === true) {
          duplicates = true;
        } else {
          skuDict[skuID] = true;
        }
      });

      if (duplicates) {
        form.setError('fulfillmentExpectations', 'required');
        return 'Please have 1 fulfillment expectation per SKU';
      } else {
        form.clearError('fulfillmentExpectations');
      }
    }
  };

  async function onSubmit(purchaseOrderInput: Logistics__PurchaseOrderInput) {
    const errorsFromValidation = checkForErrors(purchaseOrderInput);
    if (errorsFromValidation) {
      setError(errorsFromValidation);
      window.scrollTo(0, 0);
      return;
    }

    if (purchaseOrderInput.fulfillmentExpectations) {
      purchaseOrderInput.fulfillmentExpectations.forEach((fe, _) => {
        fe.quantity = parseInt(String(fe.quantity), 10);
      });
    }

    // React Hook Form weirdness was not registering the businessName at the right nested data level.
    if (
      purchaseOrderInput.shipToAddress &&
      form.watch('shipToAddress.businessName') &&
      purchaseOrderInput.shipToAddress.businessName !== form.watch('shipToAddress.businessName')
    ) {
      purchaseOrderInput.shipToAddress.businessName = (form.watch('shipToAddress.businessName') ?? undefined) as
        | string
        | undefined;
    }

    try {
      let resultFields: Pick<Logistics__ModifyPurchaseOrderPayload, 'id' | 'status' | 'error'> | undefined;
      if (purchaseOrderInput.id) {
        const result = await executeModifySubmit({
          variables: {
            input: purchaseOrderInput,
          },
        });
        resultFields = result.data?.modifyLogisticsPurchaseOrder;
      } else {
        const result = await executeNewSubmit({
          variables: {
            input: purchaseOrderInput,
          },
        });
        resultFields = result.data?.buildLogisticsPurchaseOrder;
      }

      if (resultFields?.status === Status.Unprocessable) {
        setError(resultFields?.error || FALLBACK_ERROR);
        window.scrollTo(0, 0);
      } else {
        setError(undefined);
        onSave(resultFields?.id || undefined);
      }
    } catch (e) {
      setError(FALLBACK_ERROR);
      window.scrollTo(0, 0);
    }
  }

  return (
    <Panel>
      <FormContext {...form}>
        {error && <Alert style="danger">{error}</Alert>}
        <form id="purchaseOrderForm" onSubmit={form.handleSubmit(onSubmit)}>
          <Panel.Title>{formData.id ? 'Edit Purchase Order' : 'New Purchase Order'}</Panel.Title>
          <Panel.Body>
            {form.watch('id') && (
              <input
                id="id"
                name="id"
                type="hidden"
                disabled
                value={form.watch('id') as string}
                ref={form.register({ required: true })}
              />
            )}
            {form.watch('accountID') && (
              <input
                id="account_id"
                name="accountID"
                type="hidden"
                disabled
                value={form.watch('accountID') as string}
                ref={form.register({ required: true })}
              />
            )}
            <Container>
              <StyledRow className="row">
                <div className="col-sm-6">
                  <Field error={form.errors.number} id="number" label="PO Number" required={false}>
                    <input
                      id="number"
                      type="text"
                      className="form-control"
                      name="number"
                      defaultValue={undefined}
                      placeholder="Will be autogenerated if left blank"
                      ref={form.register}
                      disabled={!!formData.id}
                    />
                  </Field>
                </div>
                <div className="col-sm-6">
                  <Field
                    error={form.errors.fulfillmentMethod}
                    id="fulfillment_method"
                    label="Fulfillment Method"
                    required={true}
                  >
                    <select
                      className="form-control"
                      name="fulfillmentMethod"
                      id="fulfillment_method"
                      ref={form.register({ required: 'please select a fulfillment method' })}
                    >
                      <option value={Logistics__FulfillmentMethodTypeEnum.Freight}>Freight (LTL, FTL, etc)</option>
                      <option value={Logistics__FulfillmentMethodTypeEnum.Parcel}>Parcel (FedEx, UPS, etc)</option>
                    </select>
                  </Field>
                </div>
              </StyledRow>
            </Container>
            <HR />
            <Container>
              <SectionTitle>Ship To Address</SectionTitle>
              <Spacer height="8px" />
              <Field id="business_name" label="Business Name" required={false}>
                <input
                  id="business_name"
                  type="text"
                  className="form-control"
                  name="shipToAddress.businessName"
                  ref={form.register}
                />
              </Field>
              <Field id="phone_number" label="Phone Number" required={false}>
                <input id="phone_number" type="text" className="form-control" name="phoneNumber" ref={form.register} />
              </Field>
              <AddressForm
                address={IAddressFromAddressInput(form.watch('shipToAddress')) || DEFAULT_ADDRESS}
                onChange={(address) => {
                  form.setValue('shipToAddress', address);
                }}
              />
            </Container>
            <HR />
            <Container>
              <SectionTitle>Fulfillment Expectations</SectionTitle>
              <Spacer height="8px" />
              {skus &&
                fulfillmentFields.map((item, index) => (
                  <FulfillmentExpectationFields
                    key={item.id}
                    skus={skus}
                    item={item}
                    index={index}
                    removeFulfillmentExpectation={removeFulfillment}
                    initialSkuID={
                      (formData?.fulfillmentExpectations &&
                        formData.fulfillmentExpectations.length > index &&
                        formData.fulfillmentExpectations[index].skuID) ||
                      undefined
                    }
                  />
                ))}
              {!skus && <span>No SKUs to select from for this account</span>}
              <StyledRow className="row pull-right">
                <Button
                  kind="primary"
                  type="button"
                  onClick={() => appendFulfillment({ skuID: '', quantity: undefined })}
                >
                  Add
                </Button>
              </StyledRow>
            </Container>
          </Panel.Body>
          <Panel.Footer align="right">
            <Button kind="primary" type="submit" loading={newPurchaseOrderSaving || modifyPurchaseOrderSaving}>
              Save
            </Button>
          </Panel.Footer>
        </form>
      </FormContext>
    </Panel>
  );
};
