import React, { useState } from 'react';
import styled from '@emotion/styled';
import { client } from '@admin/libraries/apollo';

import {
  SignatureRequest,
  SignatureRequestKindEnum as SignatureRequest__Kind,
  Status,
  useRegenerateDocumentsMutation,
} from '@admin/schema';
import { Button, HelpBlock } from '@shared/components/bootstrap';
import { Roles } from '@admin/components/helpers/roles';
import { UserRole } from '@shared/types/user_role';

const Buttons = styled.div`
  display: flex;
  gap: 4px;
`;

const SIGNATURE_REQUEST_KIND_TO_HUMAN: Record<SignatureRequest__Kind, string> = {
  [SignatureRequest__Kind.InAdvance]: 'Advanced Documents',
  [SignatureRequest__Kind.PreJob]: 'Start Documents',
  [SignatureRequest__Kind.PostJob]: 'End Documents',
};

const RegenerateSignatureRequestButton: React.FC<{
  signatureRequest: Pick<SignatureRequest, 'id' | 'kind' | 'signedAt'>;
  onSubmit(): void;
  onSuccess(message: string): void;
  onError(error: string): void;
}> = ({ signatureRequest, onSubmit, onSuccess, onError }) => {
  const [execute, { loading }] = useRegenerateDocumentsMutation({
    client,
    variables: {
      signatureRequestID: signatureRequest.id,
    },
  });

  const onClick = async () => {
    onSubmit();

    const result = await execute();

    const response = result.data?.regenerateDocuments;

    if (response?.status === Status.Ok) {
      onSuccess('Documents successfully regenerated! Ask the customer to revisit the link they were sent.');
    } else {
      onError(response?.error || 'Sorry, something went wrong. Please try again or contact Tech Support.');
    }
  };

  const signed = !!signatureRequest.signedAt;

  return (
    <Button key={signatureRequest.id} kind="primary" onClick={onClick} loading={loading} disabled={signed}>
      Regenerate {SIGNATURE_REQUEST_KIND_TO_HUMAN[signatureRequest.kind]} {signed && <>(signed)</>}
    </Button>
  );
};

export const RegenerateDocuments: React.FC<{
  signatureRequests: SignatureRequest[];
}> = ({ signatureRequests }) => {
  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  return (
    <Roles show={[UserRole.Admin, UserRole.Manager, UserRole.Dispatcher, UserRole.L1Agent, UserRole.L2Agent]}>
      {errorMessage && <HelpBlock className="text-danger">{errorMessage}</HelpBlock>}
      {successMessage && <HelpBlock className="text-success">{successMessage}</HelpBlock>}
      <Buttons>
        {signatureRequests.map((signatureRequest) => (
          <RegenerateSignatureRequestButton
            key={signatureRequest.id}
            signatureRequest={signatureRequest}
            onSubmit={() => {
              setErrorMessage(undefined);
              setSuccessMessage(undefined);
            }}
            onSuccess={(message) => {
              setSuccessMessage(message);
            }}
            onError={(message) => {
              setErrorMessage(message);
            }}
          />
        ))}
      </Buttons>
    </Roles>
  );
};
