import * as React from 'react';

import { IItem } from '@admin/types/item';
import { IItemGroup } from '@admin/types/item_group';

import { Table } from './table';

const SINGULAR_COUNT = 1;

export const Summary: React.FC<{
  items: IItem[];
  groups: IItemGroup[];
  selections: IItem[];
  onSelect(selections: IItem[]): void;
}> = ({ items, groups, selections, onSelect }) => (
  <div className="panel">
    <div className="panel-heading">
      <div className="panel-title clearfix">
        <div className="pull-right">
          Select All{' '}
          <input
            type="checkbox"
            checked={items.length === selections.length}
            disabled={items.length === selections.length}
            onChange={(event) => {
              if (event.target.checked) {
                onSelect([...items]);
              }
            }}
          />
        </div>
        <div className="pull-left">
          {selections.length} {selections.length === SINGULAR_COUNT ? 'Selection' : 'Selections'}
        </div>
      </div>
    </div>
    <Table items={selections} groups={groups} />
  </div>
);
