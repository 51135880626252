import React, { useState } from 'react';
import cn from 'classnames';
import { DateTime } from 'luxon';
import { capitalize } from 'lodash';
import { Label } from '@admin/components/helpers/label';
import {
  accountURL,
  claimURL,
  customerTicketURL,
  detailSelfStorageUnitURL,
  dispatchURL,
  editLeadURL,
  logisticsDetailedLoadURL,
  newTicketURL,
  orderURL,
  ticketURL,
  userURL,
  warehouseURL,
} from '@admin/config/routes';
import { AnchorButton, Table as BootstrapTable } from '@shared/components/bootstrap';
import { client } from '@admin/libraries/apollo';
import { Pagination } from '@admin/components/pagination';
import { Panel } from '@admin/components/helpers/panel';
import { Refresh } from '@admin/components/refresh';
import { Spinner } from '@admin/components/spinner';
import { TicketFiltersInput, TicketFragment, usePaginatedTicketListQuery } from '@admin/schema';
import { TicketMode } from '@admin/types/ticket_mode';
import { UserRole } from '@shared/types/user_role';
import { TicketState } from '@admin/types/ticket_state';
import { Timestamp, Retry } from '@shared/components/helpers';

import { Roles } from '@admin/components/helpers/roles';
import { Filters } from './filters';

const DEFAULT_FILTER_STATE = {
  mode: TicketMode.MyTickets,
  state: TicketState.Open,
};

const SHORT_DATE_TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  year: '2-digit',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const statusText = (ticket: TicketFragment) =>
  ticket.state === TicketState.Resolved && ticket.resolutionType === 'did_not_do'
    ? 'Closed - No Action Taken'
    : capitalize(ticket.state);

const ticketAssignee = (ticket: TicketFragment) => {
  if (ticket.assignee && ticket.group) {
    return (
      <>
        <div>{ticket.assignee.name}</div>
        <div>({ticket.group.name})</div>
      </>
    );
  }
  if (ticket.assignee) {
    return ticket.assignee.name;
  }
  if (ticket.group) {
    return `(${ticket.group.name})`;
  }
  return '-';
};

const ticketSubject = (ticket: TicketFragment) => {
  const ticketableID = ticket.ticketableID;
  const ticketableName = ticket.ticketableName;
  switch (ticket.ticketableType) {
    case 'Account':
      return <a href={accountURL({ id: ticketableID })}>{ticketableName}</a>;
    case 'Claim':
      return <a href={claimURL({ id: ticketableID })}>Claim {ticketableName}</a>;
    case 'CustomerTicket':
      return <a href={customerTicketURL({ id: ticketableID })}>{ticketableName}</a>;
    case 'Dispatch':
      return <a href={dispatchURL({ id: ticketableID })}>{ticketableName}</a>;
    case 'Lead':
      return <a href={editLeadURL({ id: ticketableID })}>{ticketableName}</a>;
    case 'Logistics::Load':
      return <a href={logisticsDetailedLoadURL({ id: ticketableID })}>{ticketableName}</a>;
    case 'Order':
      return <a href={orderURL({ id: ticketableID })}>{ticketableName}</a>;
    case 'SelfStorage::Unit':
      return <a href={detailSelfStorageUnitURL({ id: ticketableID })}>{ticketableName}</a>;
    case 'User':
      return <a href={userURL({ id: ticketableID })}>{ticketableName}</a>;
    case 'Warehouse':
      return <a href={warehouseURL({ id: ticketableID })}>{ticketableName}</a>;
    default:
      return <span>{ticketableName}</span>;
  }
};

const statusStyle = (ticket: TicketFragment) => {
  switch (ticket.state) {
    case TicketState.Resolved:
      return ticket.resolutionType === 'did_not_do' ? 'default' : 'success';
    case TicketState.Open:
      return 'warning';
    case TicketState.Paused:
      return 'danger';
    case TicketState.Pending:
      return 'info';
    default:
      return 'default';
  }
};

const Row: React.FC<{
  ticket: TicketFragment;
}> = ({ ticket }) => {
  const { id, dueDate, read, state, description, category, subCategory } = ticket;
  return (
    <tr
      className={cn({
        'ticket-unread': !read,
        'ticket-overdue': state === TicketState.Open && DateTime.fromISO(dueDate) < DateTime.local(),
      })}
    >
      <td className="col-md-3">{ticketSubject(ticket)}</td>
      <td className="col-md-3">{description}</td>
      <td className="col-md-2">
        {category} {subCategory && ` - ${subCategory}`}
      </td>
      <td className="col-md-1">{ticketAssignee(ticket)}</td>
      <td className="col-md-3">
        <Label kind={statusStyle(ticket)}>{statusText(ticket)}</Label>
        {ticket.state !== TicketState.Resolved && (
          <div>
            due <Timestamp value={dueDate} format={Timestamp.Format.Custom} options={SHORT_DATE_TIME_OPTIONS} />
          </div>
        )}
      </td>
      <td className="col-zero col-nowrap">
        <AnchorButton kind="primary" href={ticketURL({ id })} target="_blank" style={{ marginRight: '4px' }}>
          View
        </AnchorButton>
      </td>
    </tr>
  );
};

const Table: React.FC<{
  tickets: TicketFragment[];
}> = ({ tickets }) => (
  <BootstrapTable hover responsive className="mar-top">
    <thead>
      <tr>
        <th className="col-sm-3">Subject</th>
        <th className="col-sm-3">Description</th>
        <th className="col-sm-2">Group</th>
        <th className="col-sm-1">Assignee</th>
        <th className="col-sm-3">Status</th>
        <th className="col-zero col-nowrap"></th>
      </tr>
    </thead>
    <tbody>
      {tickets.map((ticket) => (
        <Row key={ticket.id} ticket={ticket} />
      ))}
    </tbody>
  </BootstrapTable>
);
const Result: React.FC<{
  filters: TicketFiltersInput;
}> = ({ filters }) => {
  const { data, error, refetch } = usePaginatedTicketListQuery({
    client,
    variables: {
      filters,
    },
  });

  if (error) {
    return <Retry error={error} refetch={refetch} />;
  }

  if (!data) {
    return <Spinner />;
  }

  if (!data.paginatedTicketList.results.length) {
    return (
      <>
        <Refresh refetch={refetch} intervalSeconds={15} />
        <div className="text-center">No tickets found</div>
      </>
    );
  }

  return (
    <>
      <Refresh refetch={refetch} intervalSeconds={15} />
      <Table tickets={data.paginatedTicketList.results} />
      <Pagination pagination={data.paginatedTicketList.pagination} onPage={(page) => refetch({ page })} />
    </>
  );
};

export const Queue: React.FC = () => {
  const [filters, setFilters] = useState<TicketFiltersInput>(DEFAULT_FILTER_STATE);
  return (
    <Panel>
      <Panel.Header>
        <Panel.Title>Tickets</Panel.Title>
      </Panel.Header>
      <Panel.Body>
        <Filters filters={filters} setFilters={setFilters} />
        <Result filters={filters} />
      </Panel.Body>
      <Roles show={[UserRole.L2Agent, UserRole.Admin, UserRole.Manager, UserRole.Warehouse]}>
        <Panel.Footer align="right">
          <a className="btn btn-mint btn-labeled fa fa-plus" href={newTicketURL()}>
            Add Task Ticket
          </a>
        </Panel.Footer>
      </Roles>
    </Panel>
  );
};
